import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { unitSizes, AmGraphDataValues } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';

class InfraFlowChartComponent implements angular.IComponentOptions {
  bindings = {
    obsInfraFlows: '<',
    fromDate: '<',
    toDate: '<',
    reloadCount: '<',
  };
  controller = InfraFlowChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/equipment/components/infra-flow-chart.html';
}

interface IObsInfraChartItem {
  date: Date;
  dayNumber: number;
  volume: number;
}

class InfraFlowChartController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;

  private infraFlowChart: AmCharts.AmSerialChart;
  private fromDate: Date;
  private toDate: Date;
  private volumeNormalUnit: uomUnit;
  private obsInfraFlows: fuse.obsInfraWaterFlowDto[];

  constructor(UnitOfMeasureService: UnitOfMeasureService, LanguageService: LanguageService) {
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.volumeNormalUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.normal);
  }

  $onChanges(changes) {
    if (changes.reloadCount?.currentValue > 0) {
      this.displayInfraFlowChart();
    }
  }

  $onDestroy() {
    if (this.infraFlowChart != null) {
      this.infraFlowChart.clear();
      this.infraFlowChart = null;
    }
  }

  private displayInfraFlowChart() {
    const fromTime = this.fromDate.clone().setHours(0, 0, 0, 0);
    const endTime = this.toDate.clone().setHours(0, 0, 0, 0);
    const dataProvider =
      ArrayUtils.sortByNumber(
        this.obsInfraFlows
        .filter((a) => a.date.getTime() >= fromTime && a.date.getTime() <= endTime)
        .map((obs) => ({
          date: obs.date,
          dayNumber: obs.dayNumber,
          volume: this._unitOfMeasureService.convertFromBase('Volume', unitSizes.normal, obs.volumeKL),
        }) as IObsInfraChartItem),
        (x) => x.dayNumber
      );

    const unit = this.volumeNormalUnit;
    // #region chart
    const volumeAxis = {
      id: 'volumeAxis',
      dashLength: 1,
      position: 'left',
      title: `${this._languageService.instant('COMMON.VOLUME')} (${unit.name})`,
      minimum: 0,
    } as AmCharts.ValueAxis;
    const volumeGraph = {
      valueField: 'volume',
      title: `${this._languageService.instant('COMMON.VOLUME')} (${unit.name})`,
      valueAxis: volumeAxis,
      type: 'column',
      columnWidth: 0.5,
      alphaField: 'true',
      fillAlphas: 0.8,
      lineColor: '#27AAE0',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.VOLUME')}: ${val.toFixed(2)}${unit.name}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const infraFlowChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider: dataProvider,
      valueAxes: [volumeAxis],
      startDuration: 0,
      graphs: [volumeGraph],
      chartScrollbar: {
        oppositeAxis: false,
        offset: 30,
        scrollbarHeight: 30,
        backgroundAlpha: 0,
        autoGridCount: true,
        color: '#aaa',
      },
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'center',
        gridAlpha: 0,
        parseDates: true,
        axisColor: '#DADADA',
        dateFormats: [
          { period: 'DD', format: 'MMM DD' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'MM', format: 'MMM DD' },
          { period: 'YYYY', format: 'MMM DD' },
        ],
        dashLength: 1,
      } as AmCharts.CategoryAxis,
      export: {
        enabled: false,
      },
    };
    this.infraFlowChart = AmCharts.makeChart('infra-flow-chart', infraFlowChartOption) as AmCharts.AmSerialChart;
    this.infraFlowChart.validateNow();
  }
}

angular.module('app.account').component('infraFlowChart', new InfraFlowChartComponent());
