import * as angular from 'angular';
import * as moment from 'moment';
import * as XLSX from 'js-xlsx';
import { AssetClassNameEnum } from '@indicina/swan-shared/enums/AssetClassNameEnum';
import { IGoogleTimeZone } from '@indicina/swan-shared/interfaces/google/IGoogleTimeZone';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { ObjectUtils } from '@indicina/swan-shared/utils/ObjectUtils';
import { AssetUtils } from '@indicina/swan-shared/utils/DomainUtils/AssetUtils';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes, unitSizes, SQLErrorCodes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { EquipmentService } from '@services/equipment.service';
import { DialogParams, LanguageService } from '@services/language.service';
import { MapService } from '@services/map.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { SensorPropertyService } from '@services/sensor-property.service';
import { UnitOfMeasureService, UnitOptions, uomUnit } from '@services/unit-of-measure.service';
import { DayNumberService } from '@services/day-number.service';
import { FetchDataService } from '@services/fetch-data.service';
import { BaseController, PostSaveActions } from 'src/app/base.controller';
import { ObsSoilMoisture } from 'src/app/_DBContext/ObsSoilMoisture';
import { ObsWaterFlow } from 'src/app/_DBContext/ObsWaterFlow';
import { ObsWeatherRollup } from 'src/app/_DBContext/ObsWeatherRollup';
import { PropertyValue } from 'src/app/_DBContext/PropertyValue';
import { Sensor } from 'src/app/_DBContext/Sensor';
import { AccountConstants } from '../../AccountConstants';
import { AddSoilMoistureReadingDialogController } from './observations/add-soil-moisture-reading-dialog.controller';
import { AddWaterFlowReadingDialogController } from './observations/add-water-flow-reading-dialog.controller';
import { CopyValueRangeDialogController } from './settings/copy-value-range-dialog.controller';
import { DataCaptureDialogController } from './settings/data-capture-dialog.controller';
import { AddInfraWaterFlowDialogController } from './observations/add-infra-water-flow-dialog.controller';
import { AddInfraDepthVolumeDialogController } from './observations/add-infra-depth-volume-dialog.controller';

interface IObsDisplayDate {
  dayNumber: number;
  date: Date;
  dayDisplayYMD: string;
  id: number;
  depth: number;
  newDepth: number;
}

enum PrimaryTabEnum {
  Summary,
  Settings,
  Observations,
  Logs,
}

enum SecondarySettingsTabEnum {
  Automated,
  FileUpload,
  ValueRange,
}

type PrimaryTabName = keyof typeof PrimaryTabEnum;

type SecondaryTab = {
  [key in keyof typeof SecondarySettingsTabEnum]: { enum: SecondarySettingsTabEnum, isVisible: boolean };
};

export class EquipmentDetailController extends BaseController {
  public primaryTabs: Record<PrimaryTabName, { enum: PrimaryTabEnum, isVisible: boolean }>;
  public secondaryTabs: Pick<Record<PrimaryTabName, SecondaryTab>, 'Settings'>;
  public equipment_: Sensor;
  public equipmentType: {
    isDepthMeter: boolean;
    isNormalFlowMeter: boolean;
    isSoilMoisture: boolean;
    isSiteHealthArea: boolean;
    isSiteHealthPoint: boolean;
    isSiteHealthAreaOrPoint: boolean;
    isTrueFlowMeter: boolean;
    isWaterFlowMeter: boolean;
    isWaterStore: boolean;
    isWeatherStation: boolean;
  };
  public equipmentAssetClassId: number;
  public equipmentAssetClassName: AssetClassNameEnum;

  public reloadCount = 0;
  public regexTimeInput: RegExp;
  public tempUnit: uomUnit;

  public healthIndexEquipmentDataInputLookup: Record<number, fuse.equipmentDataInputDto> = {};
  public browserTimezoneLabel: string;
  public equipmentAccountInfo: fuse.equipmentAccountInfoDto;
  public equipmentId: number;
  public minDate = SWANConstants.MinDate;
  public maxDate: Date;
  public accountEquipments: fuse.equipmentProfileDto[];
  public equipmentDataTo: string;
  public obsChart: AmCharts.AmSerialChart;
  private irrigationSeasonStartDate: Date = new Date();
  private irrigationSeasonEndDate: Date = new Date();
  public minValueRangeDate: Date;
  public maxValueRangeDate: Date;
  public irrigationSeasonStartDayNumber: number;
  public irrigationSeasonEndDayNumber: number;
  private monthHeaderFormatter: any;
  public modelTimezone: number;

  public weatherTypeOptions = [
    { key: 'ETO', isDisabled: false },
    { key: 'RAINFALL', isDisabled: false },
    { key: 'TEMPERATURE', isDisabled: false },
    { key: 'HUMIDITY', isDisabled: false },
    { key: 'WINDSPEED', isDisabled: false },
    { key: 'SOLAR_RADIATION', isDisabled: false },
  ]
  public selectedWeatherType = '';

  public AssetStates = ['Active', 'Suspended', 'Archived'];

  public topText = '';
  public otherText = 'AC.EQUIP.DAY_START_TEXT';
  public weatherText = 'AC.EQUIP.WEATHER_TEXT';
  public irrigationStart: string = '';
  public lockable = false;

  public sensorTimezone: IGoogleTimeZone;

  // tab tracking
  public primaryTab: number;
  public secondaryTab: number;

  public flowMeterUploadTemplateCsv = [];
  public flowMeterUploadTemplateCsvHeader = ['Name', 'RecordType', 'DateFrom', 'DateTo', 'Volume'];

  private allHealthRanges: fuse.healthIndexValueRangeDto[] = [];
  public filteredHealthRanges: fuse.healthIndexValueRangeDto[];
  private selectedRangeDayNumber: number;

  public obsFromDate: Date;
  public obsToDate: Date;
  private hasCustomChanges: Function;
  private cancelCustomChanges: Function;
  private defaultDaysLength: number = 90;
  private maximumDaysLength: number = 365;
  private scheduler = {} as fuse.equipmentScheduler;

  public obsDisplayDates: IObsDisplayDate[];
  // soil moisture don't need data validate (it has depth property)
  public obsSoilMoistures: ObsSoilMoisture[];
  public obsWeatherRollups: ObsWeatherRollup[];
  public obsWaterFlows: ObsWaterFlow[];
  public obsInfraWaterFlows = [] as fuse.obsInfraWaterFlowDto[];
  public obsInfraWaterDepths: fuse.obsInfraWaterDepthDto[];
  public depthMeterBoundaries: fuse.depthMeterBoundaryValuesDto[];

  public isShowObs = false;
  public isObsEditMode = false;
  public imuSites = [] as fuse.equipmentSiteInfoDto[];
  private infrastructures = [] as fuse.infrastructureDto[];
  public associatedSites = [] as number[];
  private savedSelection = [] as number[];
  public associatedInfra: string;

  public searchTerm: string;
  public searchEquip: string;

  private AssetSettings = [];
  private AssetRepeat: number = 1;

  public downloadBOMActivated = false;
  public isSuperUser = false;
  public autoDataCaptureIcon = '';
  public autoDataCaptureText = '';
  public currentDataInputId: number;
  private locationValidated: boolean = true;
  private adjustedTodayDayNumber: number;
  public numberOfFutureDays = 3;
  public rowLockOverride: number = null;
  public unitsConfirmed = null;
  public isFutureDataEnabled: boolean;
  public csvFile: File;
  private isValueRangeChanged = false;
  public isAutomaticCollector: boolean;
  public lockLatLong: boolean = false;

  private unitOptions: UnitOptions;
  public uploadUnits;
  public uploadUnitNames;
  public etoUnit: uomUnit;
  public flowRateUnit: uomUnit;
  public humidityUnit: uomUnit;
  public rainfallUnit: uomUnit;
  public soilDepthUnit: uomUnit;
  public solarRadiationUnit: uomUnit;
  public temperatureUnit: uomUnit;
  public volumeUnit: uomUnit;
  public windSpeedUnit: uomUnit;

  public elevationUnit: uomUnit;
  public sensorProperties = [];
  public collectorProperties: PropertyValue[];
  public isInfraEquipment = false;
  private meterUnit: uomUnit;
  public isObsChanged = false;
  public isObsValid = true;
  public schedulerRefreshCount = 0;
  public isProfileChangable = true;
  public meterUnits: fuse.uomBasic[];
  public isObsExisted: boolean;

  private _document: angular.IDocumentService;
  private _http: angular.IHttpService;
  private _mdDateLocale: angular.material.IDateLocaleProvider;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _equipmentService: EquipmentService;
  private _fetchDataService: FetchDataService;
  private _languageService: LanguageService;
  private _mapService: MapService;
  private _notifyingService: NotifyingService;
  private _sensorPropertyService: SensorPropertyService;
  private _unitOfMeasureService: UnitOfMeasureService;
  private _ngFileUpload: any;

  constructor(
    $document: angular.IDocumentService,
    $http: angular.IHttpService,
    $mdDateLocale: angular.material.IDateLocaleProvider,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    EquipmentService: EquipmentService,
    FetchDataService: FetchDataService,
    LanguageService: LanguageService,
    MapService: MapService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    SensorPropertyService: SensorPropertyService,
    UnitOfMeasureService: UnitOfMeasureService,
    Upload: any,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._document = $document;
    this._http = $http;
    this._mdDateLocale = $mdDateLocale;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._timeout = $timeout;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._dupeHandlerService = DupeHandlerService;
    this._equipmentService = EquipmentService;
    this._fetchDataService = FetchDataService;
    this._languageService = LanguageService;
    this._mapService = MapService;
    this._notifyingService = NotifyingService;
    this._sensorPropertyService = SensorPropertyService;
    this._unitOfMeasureService = UnitOfMeasureService;
    this._ngFileUpload = Upload;

    this.updateScheduler = this.updateScheduler.bind(this);

    this.entityManager = DataEntityService.manager;
    this.regexTimeInput = SWANConstants.RegexTimeInput;
    this.equipmentId = Number(this._state.params.id);
    this.currentDataInputId = EquipmentService.getDataInputId();

    this.browserTimezoneLabel = DateUtils.getBrowserTimezoneLabel();
    this.elevationUnit = UnitOfMeasureService.getUnits(UnitTypes.Elevation);
    this.etoUnit = UnitOfMeasureService.getUnits(UnitTypes.FluidDepth);
    this.flowRateUnit = UnitOfMeasureService.getUnits(UnitTypes.FlowRate);
    this.humidityUnit = UnitOfMeasureService.createDummyUnit(1); // no unit prefs, but need to round to 1dp
    this.rainfallUnit = UnitOfMeasureService.getUnits(UnitTypes.FluidDepth);
    this.soilDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.SoilDepth);
    this.solarRadiationUnit = UnitOfMeasureService.getUnits(UnitTypes.SolarRadiation);
    this.temperatureUnit = UnitOfMeasureService.getUnits(UnitTypes.Temperature);
    this.volumeUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume);
    this.windSpeedUnit = UnitOfMeasureService.getUnits(UnitTypes.Velocity);

    this.isSuperUser = PermissionService.isSuperUser;

    this.scope['datePickerLocale'] = DateUtils.Locale.AngularJS.DatePicker.dayAndMonthShort();

    // Forms
    this.scope['obsWaterFlowForm'] = {};
    this.scope['summaryForm'] = {};
    this.scope['settingForm'] = {};
    this.scope['uploadForm'] = {};

    this.obsFromDate = new Date();
    this.obsFromDate.setDate(this.obsFromDate.getDate() - this.defaultDaysLength);
    this.obsToDate = new Date();
    this.obsToDate.setDate(this.obsToDate.getDate() + 8);
    this.scheduler.startDate = new Date();

    this.tempUnit = UnitOfMeasureService.getUnits(UnitTypes.Temperature);
    this._dataEntityService.clear(); // changes from previously selected equipment should not be kept

    this.scope.$on('$destroy', () => {
      this.restoreOriginalDateLocale();
      this._dataEntityService.installCustomChanges(null, null, null);
      console.log('Equipment Page closed.');
    });

    // clears so that previous equipment obs data is not kept
    this.obsSoilMoistures = [];
    this.obsWeatherRollups = [];
    this.obsWaterFlows = [];

    this._notifyingService.subscribe(NotifyEvents.App.SaveChanges.Equipment, $scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });

    this.adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.maxDate = this._dayNumberService.convertDayNumberToDate(this.adjustedTodayDayNumber - 1);

    this.isShowObs = true;
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  public get canRunDataCapture(): boolean {
    return this.apf.hasEquipmentSettingsFull && !this.hasDataChanges;
  }

  public setDefaultUploadUnits() {
    const getAssetClassOptions = (assetClassName: AssetClassNameEnum) => {
      const options = {
        'Water Flow Meter': {
          'Volume': { type: 'Volume', size: unitSizes.normal }
        },
        'Weather Station': {
          'Temperature': { type: 'Temperature', size: unitSizes.normal },
          'Rainfall': { type: 'Fluid Depth', size: unitSizes.normal },
          'Evaporation': { type: 'Fluid Depth', size: unitSizes.normal },
          'Wind Speed': { type: 'Velocity', size: unitSizes.normal },
          'Solar Radiation': { type: 'Solar Radiation', size: unitSizes.normal },
        },
        'Soil Moisture': {
          'Soil Depth': { type: 'Soil Depth', size: unitSizes.normal }
        },
      };

      return options[assetClassName];
    }

    this.uploadUnits = getAssetClassOptions(this.equipmentAssetClassName);

    if (!this.uploadUnits) {
      return;
    }

    this.uploadUnitNames = Object.keys(this.uploadUnits);

    this.uploadUnitNames.forEach((name) => {
      const uploadUnit = this.uploadUnits[name];

      uploadUnit.selection = this._unitOfMeasureService.getUnitLabel(uploadUnit.type, uploadUnit.size);
      uploadUnit.options = this.unitOptions.getAllowedUnitScales(uploadUnit.type, uploadUnit.size);
    });
  }

  public setTopText() {
    this.lockable = !this.equipmentType.isSoilMoisture;

    if (this.equipment_.DataCollectionProfileId == null) {
      return;
    }

    if (this.equipmentType.isWeatherStation) {
      this.topText = this.weatherText;
    } else {
      const timestring = new Date(0, 0, 0, 0, this.account.irrigationLocalStartTimeInMinutes, 0).toString('HH:mm');

      this.irrigationStart = timestring;
      this.topText = this.otherText;
    }

    this.lockLatLong = this.equipmentType.isSiteHealthArea;
  }

  public clearSchedule() {
    this.scheduler = { hours: [], minutes: null } as fuse.equipmentScheduler;
    this.equipment_.Schedule = JSON.stringify(this.scheduler);

    this.schedulerRefreshCount++;
  }

  public viewOnDashboard() {
    this._state.go('app.account.dashboard', { viewSiteHealth: true, sensorId: this.equipmentId });
  }

  public getSelectedRangeDayNumber(valueRange: fuse.healthIndexValueRangeDto) {
    this.selectedRangeDayNumber = valueRange.dayNumber;
  }

  public clearSelectedRangeDayNumber() {
    this.selectedRangeDayNumber = undefined;
  }

  $onInit() {
    const initTabs = () => {
      const restoreTabsFromLocalStorage = () => {
        const context = LocalStorageUtils.contextData;

        if (isFinite(context.tab1)) {
          this.primaryTab = this.getPrimaryTabIndex(context.tab1);

          if (isFinite(context.tab2)) {
            this.secondaryTab = this.getSecondaryTabIndex(context.tab2);
            context.tab2 = undefined;
          }

          context.tab1 = undefined;

          LocalStorageUtils.setContextData(context);
        }
      };

      const setTabDefaults = () => {
        if (!this._state.params.isSwitchEquipment) {
          if (this._state.params.viewRange || this._state.params.viewSettings) {
            if (this._state.params.viewRange) {
              this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Settings);
              this.secondaryTab = this.getSecondaryTabIndex(SecondarySettingsTabEnum.ValueRange);
            } else if (this._state.params.viewSettings) {
              this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Settings);
              this.secondaryTab = this.getSecondaryTabIndex(SecondarySettingsTabEnum.Automated);
            }
          } else if (!this.equipmentType.isSiteHealthAreaOrPoint) {
            this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Observations);
          } else {
            restoreTabsFromLocalStorage();
          }
        } else {
          restoreTabsFromLocalStorage();
        }
      };

      this.primaryTabs = {
        Summary: {
          enum: PrimaryTabEnum.Summary,
          isVisible: true,
        },
        Settings: {
          enum: PrimaryTabEnum.Settings,
          isVisible: this.apf.hasEquipmentSettingsFull || (!this.equipmentType.isSiteHealthAreaOrPoint && this.apf.hasEquipmentSettingsFileUploadFull),
        },
        Observations: {
          enum: PrimaryTabEnum.Observations,
          isVisible: !this.equipmentType.isSiteHealthAreaOrPoint,
        },
        Logs: {
          enum: PrimaryTabEnum.Logs,
          isVisible: this.equipmentAssetClassId != null,
        },
      };

      this.secondaryTabs = {
        Settings: {
          Automated: {
            enum: SecondarySettingsTabEnum.Automated,
            isVisible: (!this.equipmentType.isSiteHealthAreaOrPoint && this.apf.hasEquipmentSettingsFull) || (this.isSuperUser && this.apf.hasAdminAccountFull),
          },
          FileUpload: {
            enum: SecondarySettingsTabEnum.FileUpload,
            isVisible: !this.equipmentType.isSiteHealthAreaOrPoint && this.apf.hasEquipmentSettingsFileUploadFull
              && !this.equipmentType.isDepthMeter
              && !this.equipmentType.isTrueFlowMeter,
          },
          ValueRange: {
            enum: SecondarySettingsTabEnum.ValueRange,
            isVisible: this.equipmentType.isSiteHealthAreaOrPoint,
          },
        }
      };

      setTabDefaults();
    };

    this.hasCustomChanges = (): boolean => this.isValueRangeChanged;

    this.cancelCustomChanges = () => {
      this.isValueRangeChanged = false;
      this.fetchValueRanges(false);
    };

    this._dataEntityService.installCustomChanges(this.hasCustomChanges, null, this.cancelCustomChanges);

    this.monthHeaderFormatter = this._mdDateLocale.monthHeaderFormatter;

    const promises = [
      this._mapService.isGoogleMapsJSApiLoaded,
      this.getAccountInfo().then(() =>
        this.fetchSensor(this.equipmentId).then(() => {
          initTabs();

          if (this.equipmentType.isSiteHealthAreaOrPoint) {
            this.fetchValueRanges(true);
          }
        }),
      ),
      this.getAccountEquipments(),
      this.getAccountSites(),
      this.getInfrastructures(),
      this.getUnitOptions(),
      this.hasObsWaterFlows(this.equipmentId),
    ];

    this._q.all(promises).then(() => {
      this._timeout(() => {
        // Timeout ensures that 'showLocationMap' is exectuted on the next digest cycle after equipment tabs have been rendered.
        this.showLocationMap();
      });
      this.setCaptureStatus();
      this.scheduler = JSON.parse(this.equipment_.Schedule);
      this.getSensorTimezone();

      const parentAssets = this.equipment_.Asset.ParentAssets.filter((a) => a.TrackDeletedWhen == null && a.DataInputId == 1);
      const childAssets = this.equipment_.Asset.ChildAssets.filter((a) => a.TrackDeletedWhen == null && a.DataInputId != 1);

      this.associatedSites = this.imuSites.filter((a) => parentAssets.some((b) => b.ParentAssetId == a.id)).map((a) => a.id);
      this.savedSelection = this.associatedSites;
      this.equipmentDataTo = this.imuSites
        .filter((a) => childAssets.some((b) => b.ChildAssetId == a.id))
        .map((a) => a.name)
        .join(', ');
      this.associatedInfra = this.infrastructures
        .filter((a) => childAssets.some((b) => b.ChildAssetId == a.assetId))
        .map((a) => a.name)
        .join(', ');
      this.isInfraEquipment = !!this.associatedInfra?.length;

      this.setDefaultUploadUnits();
      this.getSettings();

      if (this.equipmentType.isDepthMeter) {
        this.isShowObs = true;
        this.getDepthMeterBoundaries();
        this.getInfraObs();
      } else if (this.equipmentType.isTrueFlowMeter) {
        this.isShowObs = true;
        this.getInfraObs();
      } else {
        this.fetchObs();
      }
    });
  }

  private filterObsWaterFlowDates(exclusionIndex: number): (date: Date) => boolean {
    return (date: Date) => {
      return this.obsWaterFlows
        .filter((_a, idx) => idx !== exclusionIndex)
        .every((a) => a.localDate.getTime() !== date.getTime());
    };
  };

  private filterObsWeatherStationDates(exclusionIndex: number): (date: Date) => boolean {
    return (date: Date) => {
      return this.obsWeatherRollups
        .filter((_a, idx) => idx != exclusionIndex)
        .every((a) => a.localDate?.getTime() !== date.getTime())
    };
  };

  private filterValueRangeDates(): (date: Date) => boolean {
    return (date: Date) => {
      if (!this.selectedRangeDayNumber) {
        return true;
      }

      const dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNumber = this._dayNumberService.convertDateToLocaleDayNumber(dateUTC, 'UTC');

      if (dayNumber === this.selectedRangeDayNumber) {
        return true;
      }

      return this.allHealthRanges.every((a) => !(a.dayNumber === dayNumber && a.dataInputId === this.currentDataInputId));
    };
  }

  private getAccountInfo(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl('equipment/getAccountInfo')).then(
      (response) => {
        if (response.data) {
          this.equipmentAccountInfo = response.data as fuse.equipmentAccountInfoDto;
          this.irrigationSeasonStartDate = new Date(2010, this.equipmentAccountInfo.irrigationSeasonStartMonth - 1, 1);
          const irrigationSeasonStartUTC = new Date(Date.UTC(2010, this.equipmentAccountInfo.irrigationSeasonStartMonth - 1, 1));
          this.irrigationSeasonEndDate = moment(this.irrigationSeasonStartDate).add(1, 'year').subtract(1, 'day').toDate();
          const irrigationSeasonEndUTC = moment(irrigationSeasonStartUTC).add(1, 'year').subtract(1, 'day').toDate();
          this.irrigationSeasonStartDayNumber = this._dayNumberService.convertDateToLocaleDayNumber(irrigationSeasonStartUTC, 'UTC');
          this.irrigationSeasonEndDayNumber = this._dayNumberService.convertDateToLocaleDayNumber(irrigationSeasonEndUTC, 'UTC');
        } else {
          this._languageService.whoops();
        }

        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );

    return defer.promise;
  }

  private hasObsWaterFlows(assetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl(`equipment/hasobswaterflows?assetid=${assetId}`)).then(
      (res) => {
        this.isObsExisted = res.data as boolean;
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );

    return defer.promise;
  }

  private getUnitOptions(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('profile/getUnitScales')).then(
      (res) => {
        if (!res.data) {
          this._languageService.error('AC.EQUIP.ERROR_LOADING');
        } else {
          this.unitOptions = new UnitOptions(res.data as fuse.unitScaleDto[]);
        }
        defer.resolve();
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );

    return defer.promise;
  }

  private getAccountEquipments(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('equipment/getAccountEquipments')).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.accountEquipments = (response.data as fuse.equipmentProfileDto[]).filter((a) => a.status == 'Active');
          this.createFlowMeterCSV();
        }
        defer.resolve();
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );
    return defer.promise;
  }

  public configureMonthOnlyDateLocale() {
    this._mdDateLocale.monthHeaderFormatter = (date: Date) => {
      if (date == null) return null;
      return moment(date).isValid() ? moment(date).format('MMM') : null;
    };
  }

  public restoreOriginalDateLocale() {
    this._mdDateLocale.monthHeaderFormatter = this.monthHeaderFormatter;
  }

  private getAccountSites(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('equipment/AccountSites')).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          const accountSites = response.data as fuse.equipmentSiteInfoDto[];
          this.imuSites = accountSites.filter((a) => a.status == 'Active');
        }
        defer.resolve();
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );
    return defer.promise;
  }

  private getInfrastructures(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    this._http.get(CommonHelper.getApiUrl('infrastructure/infrastructures')).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.infrastructures = response.data as fuse.infrastructureDto[];
        }
        defer.resolve();
      },
      () => {
        this._languageService.whoops();
        defer.reject();
      },
    );
    return defer.promise;
  }

  public changeEquipmentSummary() {
    if (this.equipment_.Asset.Name != null) {
      if (
        this.accountEquipments.some(
          (a) =>
            a.assetId != this.equipment_.Asset.AssetId &&
            a.assetName.trim().toLowerCase() == this.equipment_.Asset.Name.trim().toLowerCase(),
        )
      ) {
        angular.forEach(this.scope['summaryForm.nameForm'], (field) => {
          if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
            if (field.$name == 'name') {
              field.$error = { nameExisted: true };
              field.$invalid = true;
            }
          }
        });
      } else {
        angular.forEach(this.scope['summaryForm.nameForm'], (field) => {
          if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
            if (field.$name == 'name') {
              field.$error = {};
              field.$invalid = false;
            }
          }
        });
      }
    }
  }

  // Creates or recreates the example upload CSV
  private createFlowMeterCSV() {
    this.flowMeterUploadTemplateCsv = [];
    this.accountEquipments
      .filter((a) => a.assetClassName == 'Water Flow Meter')
      .forEach((flowSensor) => {
        this.flowMeterUploadTemplateCsv.push({
          Name: flowSensor.assetName,
          RecordType: 'WaterFlow',
          DateFrom: '2016-08-01',
          DateTo: '2016-08-05',
          Volume: 150,
        });
      });
    // in case the name was changed
    if (this.equipment_ != undefined && this.equipment_.Asset != undefined) {
      const sensor = this.accountEquipments.find((s) => s.assetId === this.equipment_.Asset.AssetId);
      if (sensor !== undefined) {
        const changedMeter = this.flowMeterUploadTemplateCsv.find((f) => f.Name === sensor.assetName);
        if (changedMeter !== undefined) {
          changedMeter.Name = this.equipment_.Asset.Name;
        }
      }
    }
  }

  // Virtually the same behaviour as asset-property-view.validateLocation()
  public validateLocation() {
    this.locationValidated = false;

    const latitudeRef = this.scope['summaryForm'].locationForm.latitude;
    const longitudeRef = this.scope['summaryForm'].locationForm.longitude;

    const asset = this.equipment_.Asset;
    const latitude = asset.Latitude;
    const longitude = asset.Longitude;

    //need to clear any errors in google_location so .$valid reflects only max/min validations
    latitudeRef.$setValidity('google_location', true);
    longitudeRef.$setValidity('google_location', true);

    if (latitudeRef.$valid && longitudeRef.$valid) {
      const promises = [
        this._mapService.getElevation(latitude, longitude),
        this._mapService.getTimeZone(latitude, longitude),
      ];

      this._q.all(promises).then((data) => {
        const tzData = JSON.parse(data[1].toString()) as IGoogleTimeZone;

        if (tzData.status == 'ZERO_RESULTS') {
          latitudeRef.$setValidity('google_location', false);
          latitudeRef.$setDirty();
          latitudeRef.$setTouched();

          longitudeRef.$setValidity('google_location', false);
          longitudeRef.$setDirty();
          longitudeRef.$setTouched();

          this._languageService.error('AC.EQUIP.THE_SEPCIFIED_LOCATION_INVALID');
        } else {
          latitudeRef.$setValidity('google_location', true);
          longitudeRef.$setValidity('google_location', true);
        }

        asset.Elevation = parseFloat(data[0].toString());
        asset.TZStandardName = data[1].toString();
        this.locationValidated = true;

        this.getSensorTimezone();
        this.showLocationMap();
      });
    }
  }

  private showLocationMap() {
    const latlng = new google.maps.LatLng(this.equipment_.Asset.Latitude, this.equipment_.Asset.Longitude);
    const mapContainer = document.getElementById('equipment-google-map');

    if (mapContainer) {
      const mapOptions = {
        cameraControl: false,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        rotateControl: false,
        streetViewControl: false,
        tilt: 0,
        zoom: 16,
        zoomControl: true,
      } as google.maps.MapOptions;

      const map = new google.maps.Map(mapContainer, mapOptions);

      const marker = new google.maps.Marker({
        draggable: true,
        icon: {
          anchor: new google.maps.Point(0, 36),
          origin: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(28, 36),
          url: `assets/icons/mapicons/${this.equipment_.Asset.AssetClassId}.svg`,
        } as google.maps.Icon,
        map: map,
        position: latlng,
        title: this.equipment_.Asset.Name,
      });

      marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
        this.equipment_.Asset.Latitude = parseFloat(event.latLng.lat().toFixed(5));
        this.equipment_.Asset.Longitude = parseFloat(event.latLng.lng().toFixed(5));
        this.calculateEvalation();
        this.getTimezone();
        this._dataEntityService.hasDirtyCustomForm = true;
      });
    }
  }

  private calculateEvalation() {
    if (this.equipment_.Asset.Latitude == null || this.equipment_.Asset.Longitude == null) {
      this.equipment_.Asset.Elevation = null;
    } else {
      this._mapService.getElevation(this.equipment_.Asset.Latitude, this.equipment_.Asset.Longitude).then((res) => {
        this.equipment_.Asset.Elevation = res;
      });
    }
  }

  private getTimezone() {
    if (this.equipment_.Asset.Latitude == null || this.equipment_.Asset.Longitude == null) {
      this.equipment_.Asset.TZStandardName = null;
    } else {
      this._mapService
        .getTimeZone(this.equipment_.Asset.Latitude, this.equipment_.Asset.Longitude)
        .then(
          (res) => {
            const result = JSON.parse(res) as IGoogleTimeZone;
            if (result.status == 'ZERO_RESULTS') {
              this.equipment_.Asset.TZStandardName = null;
            } else {
              this.equipment_.Asset.TZStandardName = res;
            }
          },
          () => {
            this.equipment_.Asset.TZStandardName = null;
          },
        )
        .finally(() => {
          this.validateLocation();
        });
    }
  }

  public downloadTemplate() {
    this._languageService.success('AC.EQUIP.UPLOAD.TEMPLATE_DOWNLOADED');
  };

  public updateScheduler(scheduler: { hours: number[], minute: number | null }) {
    if (this.equipment_) {
      this.scheduler = {
        ...this.scheduler,
        hours: scheduler.hours.map((h) => h.toString().padStart(2, '0')),
        minutes: scheduler.minute?.toString().padStart(2, '0'),
      };

      this.equipment_.Schedule = JSON.stringify(this.scheduler);
    }
  };

  private checkDataProvider() {
    if (this.equipment_.DataCollectionProfileId == null) {
      if (this.scheduler?.hours?.length || this.scheduler?.minutes?.length) {
        this._languageService.showSaveFailure('AC.EQUIP.SCHEDULED_HOURS_ERASED');
        return false;
      }
    }
    return true;
  }

  private saveChanges(postSaveActions: PostSaveActions = null): angular.IPromise<void> {
    if (!this.locationValidated) {
      this._languageService.warning('AC.EQUIP.THE_LOCATION_BEING_VALIDATED');

      return;
    }

    if (this.isObsChanged || this.hasDataChanges) {
      let isValidChanges = true;

      isValidChanges = isValidChanges && this.checkDataProvider();

      const invalidRange = this.allHealthRanges.find(
        (a) =>
          a.effectiveFrom == undefined ||
          a.targetRangeLow == undefined ||
          a.targetRangeHigh == null ||
          a.targetRangeHigh < a.targetRangeLow ||
          a.variabilityLow == null ||
          a.variabilityHigh == null ||
          a.variabilityHigh < a.variabilityLow,
      );

      if (invalidRange != null) {
        isValidChanges = false;

        this.currentDataInputId = invalidRange.dataInputId;
        this.filterRanges();
        this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Settings);
        this.secondaryTab = this.getSecondaryTabIndex(SecondarySettingsTabEnum.ValueRange);

        if (!(this.isSuperUser && this.apf.hasAdminAccountFull)) {
          this.secondaryTab--;
        }

        this.setSettingFormDirty();
      } else {
        angular.forEach(this.scope['settingForm'], (itemForm) => {
          angular.forEach(itemForm, (field) => {
            if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
              if (field.$invalid) {
                isValidChanges = false;
                field.$setDirty();
                field.$setTouched();
                this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Settings);
                this.secondaryTab = this.getSecondaryTabIndex(SecondarySettingsTabEnum.Automated);
              }
            }
          });
        });

        this.sensorProperties.forEach((p) => {
          if (!p.checkElement(true)) {
            isValidChanges = false;
          }
        });
      }

      const invalidWaterFlow = this.obsWaterFlows.find((a) => a.VolumeKL == null);

      if (invalidWaterFlow != null) {
        isValidChanges = false;
        this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Observations);
      }

      const invalidObsWeather = this.obsWeatherRollups.find((a) => a.localDate == null);

      if (invalidObsWeather != null) {
        isValidChanges = false;
        this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Observations);
      }

      angular.forEach(this.scope['summaryForm'], (itemForm) => {
        angular.forEach(itemForm, (field) => {
          if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
            if (field.$invalid) {
              isValidChanges = false;
              this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Summary);
            }
          }
        });
      });

      angular.forEach(this.scope['obsWaterFlowForm'], (itemForm) => {
        angular.forEach(itemForm, (field) => {
          if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
            if (field.$invalid) {
              isValidChanges = false;
              this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Observations);
            }
          }
        });
      });

      if (!this.isObsValid) {
        isValidChanges = false;
        this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Observations);
      }

      if (isValidChanges == false) {
        const params = { title: 'COMMON.ALERT', content: 'COMMON.PLEASE_FIX', ok: 'COMMON.CLOSE' } as DialogParams;
        const alert = this._languageService.basicAlert(params).targetEvent();

        this._mdDialog.show(alert).then(() => {
          this._mdDialog.hide();
        });

        return;
      } else {
        if (this.scheduler) {
          if (this.scheduler.interval == 0 || this.equipment_.Asset.Status != this.AssetStates[0]) {
            this.scheduler.startDate = null;
          } else {
            if (['day', 'hour'].includes(this.scheduler.frequency)) {
              const config = this.scheduler.frequency === 'day'
                ? { days: this.scheduler.interval }
                : { hours: this.scheduler.interval };

              this.scheduler.startDate = Date.today().setTimeToNow().add(config);
            }
          }

          const data = {
            startDate: this.scheduler.startDate,
            hours: this.scheduler.hours,
            minutes: this.scheduler.minutes,
          };

          // if either hours or mins are null, set both to null
          if (!data.hours?.length || !data.minutes) {
            data.hours = null;
            data.minutes = null;
          }

          this.equipment_.Schedule = JSON.stringify(data);
          this.equipment_.NextRun = this.scheduler.startDate;
        }

        const successCallback = () => {
          this._dataEntityService.hasDirtyCustomForm = false;
          this._dataEntityService.installCustomChanges(null, null, null);
          this._languageService.showSaveSuccess();


          if (super.executeAnyPostSaveActions(postSaveActions)) {
            return;
          }

          this.handleGenerateChart();;

          if (this.allHealthRanges != null) {
            this.allHealthRanges.forEach((v) => (v.modified = false));
          }

          this.isValueRangeChanged = false;

          if (this.isObsChanged) {
            this.isObsChanged = false;
          }

          this.setCaptureStatus();
          this.createFlowMeterCSV();
        };

        const failCallback = (saveFailed) => {
          if (saveFailed.httpResponse.data.Errors) {
            saveFailed.httpResponse.data.Errors.map((error) => {
              if (error.ErrorNumber == SQLErrorCodes.DuplicateKeyError) {
                this._languageService.showSaveFailure('AC.EQUIP.ERRORS_ENCOUNTERED_SAVING');
              } else {
                this._languageService.showSaveFailure(error.Message);
              }
            });
          }
        };

        const promises: angular.IPromise<void>[] = [];

        if (this.hasCustomChanges?.()) {
          let ranges = angular.copy(this.allHealthRanges);

          ranges = ranges.map((r) => {
            r.effectiveFrom = new Date(
              Date.UTC(r.effectiveFrom.getFullYear(), r.effectiveFrom.getMonth(), r.effectiveFrom.getDate()),
            );

            return r;
          });

          promises.push(this._equipmentService.setHealthIndexValueRanges(this.equipmentId, ranges));
        }

        if (this.isObsChanged) {
          promises.push(this.saveInfraObs());
        }

        return this._q.all(promises).then(() => {
          this._dupeHandlerService.setDuplicateType(this.equipmentAssetClassName);
          this._dataEntityService.saveChanges(false, this._dupeHandlerService.duplicatesOnly).then(successCallback, (error) => {
            this._dupeHandlerService.showError(error);
          });
        }, failCallback);
      }
    }
  }

  private saveInfraObs(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const fromDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.obsFromDate);
    const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.obsToDate);

    if (this.equipmentType.isWaterFlowMeter) {
      this._http
        .post(
          CommonHelper.getApiUrl(`equipment/obsInfraWaterFlows?assetId=${this.equipmentId}&fromDayNumber=${fromDayNumber}&endDayNumber=${endDayNumber}`),
          this.obsInfraWaterFlows,
        )
        .then(
          (res) => {
            this.isObsChanged = false;
            this.reloadCount++;
            defer.resolve();
          },
          () => {
            defer.reject();
          },
        );
      return defer.promise;
    } else {
      this._http
        .post(
          CommonHelper.getApiUrl(`equipment/obsInfraWaterDepths?assetId=${this.equipmentId}&fromDayNumber=${fromDayNumber}&endDayNumber=${endDayNumber}`),
          this.obsInfraWaterDepths,
        )
        .then(
          (res) => {
            this.isObsChanged = false;
            this.reloadCount++;
            defer.resolve();
          },
          () => {
            defer.reject();
          },
        );
      return defer.promise;
    }
  }

  public cancelChanges() {
    this.scope['summaryForm'].locationForm.latitude.$setValidity('invalid', true);
    this.scope['summaryForm'].locationForm.longitude.$setValidity('invalid', true);
    this.locationValidated = true;
    this.equipment_.entityAspect.rejectChanges();
    this._dataEntityService.rejectChanges();

    if (this.isInfraEquipment && !this.equipmentType.isNormalFlowMeter) {
      this.getInfraObs();
      this.isObsChanged = false;
      this.isObsValid = true;
    } else {
      this.fetchObs();
      this.handleGenerateChart();;
    }

    this.getSettings();
    this.getSensorTimezone();
    this.showLocationMap();

    this.scheduler = JSON.parse(this.equipment_.Schedule);
    this.schedulerRefreshCount++;

    this._dataEntityService.hasDirtyCustomForm = false;
  }

  public uploadDataFile() {
    if (this.csvFile == null) return;
    if (this.lockable) {
      this.scope['uploadForm'].form.rowLockOverride.$validate();
      if (this.rowLockOverride == null) {
        this._languageService.warning('AC.EQUIP.SELECT_WHETHER_LOCK');
        return;
      }
    }
    let units = this.uploadUnitNames.map((name) => `"${name}":"${this.uploadUnits[name].selection}"`).join(',');
    units = '{' + units + '}';
    this._ngFileUpload
      .upload({
        url: CommonHelper.getApiUrl('workagents/uploadDataFile'),
        data: { file: this.csvFile },
        params: {
          assetId: this.equipmentId,
          units: units,
          numberOfFutureDays: this.isFutureDataEnabled ? this.numberOfFutureDays : null,
          rowLockOverride: this.rowLockOverride,
        },
      })
      .then(() => {
        this.csvFile = null;
        this._languageService.success('AC.EQUIP.FILE_RECEIVED');
        this._languageService.info('AC.EQUIP.PLEASE_CHECK_EMAIL');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private getSensorTimezone(): void {
    try {
      this.sensorTimezone = JSON.parse(this.equipment_.Asset.TZStandardName) as IGoogleTimeZone;
      this.sensorTimezone.timezoneid = this.sensorTimezone.timeZoneId ?? this.sensorTimezone.timezoneid;
      this.sensorTimezone.timezonename = this.sensorTimezone.timeZoneName ?? this.sensorTimezone.timezonename;
      this.sensorTimezone.rawoffset = this.sensorTimezone.rawOffset ?? this.sensorTimezone.rawoffset;
    } catch (err) {
      this.sensorTimezone = null;
    }
  }

  private setCaptureStatus() {
    this.autoDataCaptureIcon = this.getCaptureStatus(this.equipment_.NextRun, 'icon');
    this.autoDataCaptureText = this.getCaptureStatus(this.equipment_.NextRun, 'text');
  }

  private getCaptureStatus(iValue: Date, what: string): string {
    let captureStatus: string;
    let theIcon: string;
    if (iValue == null || this.equipment_.Asset.Status != 'Active' ) {
      captureStatus = 'OFF';
      theIcon = 'icon-cancel red-500-fg';
    } else {
      captureStatus = 'ON';
      theIcon = 'icon-checkbox-marked-circle swanGreen';
    }
    if (what == 'text')
      return captureStatus;

    if (what == 'icon')
      return theIcon;

    return captureStatus;
  }

  private fetchSensor(assetId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    const setEquipmentType = (equipment: Sensor) => {
      const assetClassNameEnum = equipment.Asset.AssetClass.Name as AssetClassNameEnum;
      this.equipmentAssetClassId = equipment.Asset.AssetClassId;
      this.equipmentAssetClassName = assetClassNameEnum;

      this.equipmentType = {
        isDepthMeter: AssetUtils.isDepthMeter(assetClassNameEnum),
        isNormalFlowMeter: false, // Set below.
        isSiteHealthArea: AssetUtils.isSiteHealthArea(assetClassNameEnum),
        isSiteHealthPoint: AssetUtils.isSiteHealthPoint(assetClassNameEnum),
        isSiteHealthAreaOrPoint: AssetUtils.isSiteHealthArea(assetClassNameEnum) || AssetUtils.isSiteHealthPoint(assetClassNameEnum),
        isSoilMoisture: AssetUtils.isSoilMoisture(assetClassNameEnum),
        isTrueFlowMeter: false, // Set in 'getSettings' function.
        isWaterFlowMeter: AssetUtils.isWaterFlowMeter(assetClassNameEnum),
        isWaterStore: AssetUtils.isWaterStore(assetClassNameEnum),
        isWeatherStation: AssetUtils.isWeatherStation(assetClassNameEnum),
      };

      this.equipmentType.isNormalFlowMeter = !this.equipment_.UnitId && this.equipmentType.isWaterFlowMeter;
    };

    this._fetchDataService.fetchSensors(assetId).then((sensors) => {
      this.equipment_ = sensors[0];

      setEquipmentType(this.equipment_);

      this.meterUnit = this._unitOfMeasureService.getUnitById(this.equipment_.UnitId);

      this.setTopText();

      defer.resolve();
    })
    .catch((reason) => {
      console.log(reason);

      defer.reject(reason);
    });

    return defer.promise;
  }

  private fetchValueRanges(createDefaults: boolean): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._equipmentService
      .getHealthIndexValueRanges(this.equipmentId)
      .then((data) => {
        this.allHealthRanges = data as fuse.healthIndexValueRangeDto[];

        let defaultsCreated: boolean = false;

        if (createDefaults) {
          this.healthIndexEquipmentDataInputLookup = this.equipmentAccountInfo.healthIndexDataInputs.reduce((acc, dataInput) => {
            acc[dataInput.dataInputId] = this.equipmentAccountInfo.healthIndexDataInputs.find(
              (v) => v.dataInputId == dataInput.dataInputId,
            );

            return acc;
          }, {} as Record<number, fuse.equipmentDataInputDto>);

          this.equipmentAccountInfo.healthIndexDataInputs.forEach((dataInput) => {
            const defaultTarget = this.equipmentAccountInfo.defaultValueRanges.find(
              (v) => v.attribute == 'Reading' && v.dataInputId == dataInput.dataInputId,
            );
            const defaultVariability = this.equipmentAccountInfo.defaultValueRanges.find(
              (v) => v.attribute == 'Variability' && v.dataInputId == dataInput.dataInputId,
            );

            if (
              this.allHealthRanges.find(
                (r) => r.dayNumber == this.irrigationSeasonStartDayNumber && r.dataInputId == dataInput.dataInputId,
              ) == null
            ) {
              const date = moment(this.irrigationSeasonStartDate).toDate();
              const newValueStart = {
                assetId: this.equipmentId,
                effectiveFrom: date,
                dayNumber: this._dayNumberService.convertLocaleDateToLocaleDayNumber(date),
                dataInputId: dataInput.dataInputId,
                targetRangeLow: defaultTarget == null ? 0.6 : defaultTarget.readingLow,
                targetRangeHigh: defaultTarget == null ? 0.8 : defaultTarget.readingHigh,
                variabilityLow: defaultVariability == null ? 0.2 : defaultVariability.readingLow,
                variabilityHigh: defaultVariability == null ? 0.3 : defaultVariability.readingHigh,
                modified: true,
              } as fuse.healthIndexValueRangeDto;

              this.allHealthRanges.push(newValueStart);

              defaultsCreated = true;
            }

            if (
              this.allHealthRanges.find(
                (r) => r.dayNumber == this.irrigationSeasonEndDayNumber && r.dataInputId == dataInput.dataInputId,
              ) == null
            ) {
              const dateEnd = moment(this.irrigationSeasonEndDate).toDate();
              const newValueEnd = {
                assetId: this.equipmentId,
                effectiveFrom: dateEnd,
                dayNumber: this._dayNumberService.convertLocaleDateToLocaleDayNumber(dateEnd),
                dataInputId: dataInput.dataInputId,
                targetRangeLow: defaultTarget == null ? 0.6 : defaultTarget.readingLow,
                targetRangeHigh: defaultTarget == null ? 0.8 : defaultTarget.readingHigh,
                variabilityLow: defaultVariability == null ? 0.2 : defaultVariability.readingLow,
                variabilityHigh: defaultVariability == null ? 0.3 : defaultVariability.readingHigh,
                modified: true,
              } as fuse.healthIndexValueRangeDto;

              this.allHealthRanges.push(newValueEnd);

              defaultsCreated = true;
            }
          });
        }

        this.filterRanges();

        if (defaultsCreated) {
          this._languageService.info('AC.EQUIP.DEFAULTS_CREATED');
        }

        defer.resolve();
      })
      .catch((err) => {
        console.log(err);
        defer.reject(err);
      });

    return defer.promise;
  }

  public refreshObs(dateType: string) {
    if (this.obsToDate < this.obsFromDate) {
      this.obsFromDate = this.obsToDate;
      this.obsToDate = this.obsFromDate;

      dateType = dateType == 'obsFromDate' ? 'obsToDate' : 'obsFromDate';
    }

    const maxDays: number = this.maximumDaysLength;
    const milliSecondPerDay: number = 1000 * 60 * 60 * 24;

    if (Math.round((this.obsToDate.getTime() - this.obsFromDate.getTime()) / milliSecondPerDay) > maxDays) {
      //get the number of days between 2 dates - see if greater than 90 days
      if (dateType == 'obsToDate') {
        // Set toDate + maxmimumDaysLength days from new fromDate
        this.obsFromDate = new Date(this.obsToDate.getTime() - maxDays * milliSecondPerDay);
        this._languageService.warning('AC.EQUIP.RANGE_ADJUSTED_FROM_DATE', 'COMMON.WARNING', { d: maxDays });
      } else {
        // Set toDate + maximumDaysLength days from new fromDate
        this.obsToDate = new Date(this.obsFromDate.getTime() + maxDays * milliSecondPerDay);
        this._languageService.warning('AC.EQUIP.RANGE_ADJUSTED_TO_DATE', 'COMMON.WARNING', { d: maxDays });
      }
    }

    if (this.equipmentType.isTrueFlowMeter || this.equipmentType.isDepthMeter) {
      this.getInfraObs();
    } else {
      this.fetchObs();
    }
  }

  private fetchObs(): angular.IPromise<void> {
    let obsResult: angular.IPromise<void>;
    let targetObsEntityName: string;

    if (this.equipmentType.isSoilMoisture) {
      targetObsEntityName = 'ObsSoilMoisture';
    } else if (this.equipmentType.isWaterFlowMeter) {
      targetObsEntityName = 'ObsWaterFlow';
    } else if (this.equipmentType.isWeatherStation) {
      targetObsEntityName = 'ObsWeatherRollup';
    }

    const queryObs = new breeze.EntityQuery(targetObsEntityName).withParameters({
      AssetId: this.equipmentId,
      DayFrom: this._dayNumberService.convertDateToLocaleDayNumber(this.obsFromDate, 'UTC'),
      DayTo: this._dayNumberService.convertDateToLocaleDayNumber(this.obsToDate, 'UTC'),
    });

    const finaliseDataLoaded = (): void => {
      this.isShowObs = true;
      this.handleGenerateChart();

      this._languageService.success('AC.EQUIP.LOADING_COMPLETE');
    };

    switch (targetObsEntityName) {
      case 'ObsSoilMoisture':
        obsResult = this.entityManager.executeQuery(queryObs).then(
          (data) => {
            if (this._state.current.name != 'app.account.equipments.detail') {
              return;
            }

            const obsSoilMoistures = data.results as ObsSoilMoisture[];

            // Round the moisture values to 2 decimal places (keep in sync with 'number-step' directive config in the view)
            obsSoilMoistures.forEach((x) => {
              x.Moisture = NumberUtils.round(x.Moisture, 2);
              x.entityAspect.acceptChanges(); // HACK: Accept changes to prevent the Breeze 'modified' flag from being set.
            });

            this.obsSoilMoistures = obsSoilMoistures;
            this.obsDisplayDates = [];

            this.obsDisplayDates = this.obsSoilMoistures.map((obsSoilMoisture) => ({
              date: this._dayNumberService.convertDayNumberToDate(obsSoilMoisture.dayNumber),
              dayDisplayYMD: obsSoilMoisture.dayDisplayYMD,
              dayNumber: obsSoilMoisture.dayNumber,
              depth: obsSoilMoisture.Depth,
              id: obsSoilMoisture.Id,
              newDepth: obsSoilMoisture.Depth,
            }));

            finaliseDataLoaded();
          },
          (error) => {
            console.log(error);
          },
        );

        break;

      case 'ObsWaterFlow':
        obsResult = this.entityManager.executeQuery(queryObs).then(
          (data) => {
            if (this._state.current.name != 'app.account.equipments.detail') {
              return;
            }

            this.obsWaterFlows = this.attachFilterDatesFn(data.results as ObsWaterFlow[], this.filterObsWaterFlowDates);

            finaliseDataLoaded();
          },
          (error) => {
            console.log(error);
          },
        );

        break;

      case 'ObsWeatherRollup':
        obsResult = this.entityManager.executeQuery(queryObs).then(
          (data) => {
            if (this._state.current.name != 'app.account.equipments.detail') {
              return;
            }

            this.obsWeatherRollups = this.attachFilterDatesFn(data.results as ObsWeatherRollup[], this.filterObsWeatherStationDates);

            this.obsWeatherRollups.forEach((wrr) => {
              wrr.ManualDateTime = moment(wrr.dayDisplayYMD, 'YYYY-MM-DD').toDate();
            });

            finaliseDataLoaded();
          },
          (error) => {
            console.log(error);
          },
        );

        break;
    }

    // if not fulfilled, then return a ready-resolved Promise
    if (!obsResult) {
      const defer = this._q.defer<void>();

      // Resolve the defer $q object before returning the promise
      defer.resolve();

      obsResult = defer.promise;
    }

    return obsResult;
  }

  private attachFilterDatesFn<T>(data: T[], filterDatesFn: (index: number) => (date: Date) => boolean): T[] {
    data.forEach((a, idx) => {
      a['filterDates'] = filterDatesFn.apply(this, [idx]);
    });

    return data;
  }

  private getInfraObs(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const fromDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.obsFromDate);
    const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.obsToDate);

    if (this.equipmentType.isWaterFlowMeter) {
      this._http
        .get(
          CommonHelper.getApiUrl(`equipment/obsInfraWaterFlows?assetId=${this.equipmentId}&fromDayNumber=${fromDayNumber}&endDayNumber=${endDayNumber}`),
        )
        .then(
          (res) => {
            this.obsInfraWaterFlows = res.data as fuse.obsInfraWaterFlowDto[];
            // obs is ordered by daynumber
            let lastReading = this.obsInfraWaterFlows.find((a) => a.dayNumber < fromDayNumber)?.reading;
            if (lastReading == null) {
              lastReading = this.equipment_.MeterInitialReading;
            }
            this.obsInfraWaterFlows.forEach((obs) => {
              obs.date = this._dayNumberService.convertDayNumberToLocaleDate(obs.dayNumber);
              obs.volumeKL = this.meterUnit.toBase((obs.reading - lastReading) * this.equipment_.MeterIncrement);
              lastReading = obs.reading;
            });
            this.reloadCount++;
            this._languageService.success('AC.EQUIP.LOADING_COMPLETE');
            if (this.obsInfraWaterFlows.length) {
              this.isObsExisted = true;
            }
            defer.resolve();
          },
          (error) => {
            defer.reject();
            console.log(error);
          },
        );
      return defer.promise;
    } else {
      this._http
        .get(
          CommonHelper.getApiUrl(`equipment/obsInfraWaterDepths?assetId=${this.equipmentId}&fromDayNumber=${fromDayNumber}&endDayNumber=${endDayNumber}`),
        )
        .then(
          (res) => {
            this.obsInfraWaterDepths = res.data as fuse.obsInfraWaterDepthDto[];
            this.obsInfraWaterDepths.forEach((obs) => {
              obs.date = this._dayNumberService.convertDayNumberToLocaleDate(obs.dayNumber);
            });
            this.reloadCount++;
            this._languageService.success('AC.EQUIP.LOADING_COMPLETE');
            defer.resolve();
          },
          (error) => {
            defer.reject();
            console.log(error);
          },
        );
      return defer.promise;
    }
  }

  private getDepthMeterBoundaries() {
    this._http.get(CommonHelper.getApiUrl(`equipment/depthMeterBoundary?assetId=${this.equipmentId}`)).then(
      (res) => {
        this.depthMeterBoundaries = res.data as fuse.depthMeterBoundaryValuesDto[];
      },
      (error) => {
        console.log(error);
        this._languageService.whoops();
      },
    );
  }

  public updateDateTime(obs: ObsWeatherRollup) {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(obs.ManualDateTime);
    const foundExistingObs = this.obsWeatherRollups.some((a) => a.AssetId == this.equipmentId && a.dayNumber == dayNumber);
    if (foundExistingObs) {
      obs.localDate = null;
      this._languageService.warning('AC.EQUIP.RECORD_DATE_EXISTS', 'COMMON.WARNING', { d: DateUtils.Locale.asDateDefault(obs.ManualDateTime) });
      return;
    } else {
      obs.localDate = obs.ManualDateTime.clone();
      obs.dayDisplayYMD = this._dayNumberService.convertDayNumberToYMD(dayNumber);
    }
  }

  public openDataCaptureDialog(): void {
    this._mdDialog
      .show({
        controller: DataCaptureDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/equipment/settings/data-capture-dialog.html',
        clickOutsideToClose: false,
      })
      .then((params: fuse.dataCaptureDto) => {
        if (params) {
          params.assetIds = [this.equipmentId];
          this._http
            .post(CommonHelper.getApiUrl('scheduler/rundatacapture'), params)
            .then(() => {
              if (params.isIntervalMode) {
                this._languageService.info('AC.EQUIP.YOUR_REQUEST_FROM', 'COMMON.INFORMATION', {
                  f: params.fromDateYMD,
                  t: params.endDateYMD,
                });
              } else {
                this._languageService.info('AC.EQUIP.YOUR_REQUEST_FOR', 'COMMON.INFORMATION', { d: params.runDays });
              }
            })
            .catch(() => {
              this._languageService.error('COMMON.DATA_REQUEST_FAILED');
            });
        }
      });
  }

  public calcWindspeedAtTwoMeters(theValue: number): number {
    if (this.equipment_.Height == null || this.equipment_.Height == 0) {
      return theValue;
    } else {
      return theValue * (4.87 / Math.log(67.8 * this.equipment_.Height - 5.42));
    }
  }

  public addObsWeatherRollupEntity() {
    // default date is the account tomorrow
    const dayDisplayYMD = this._dayNumberService.convertDayNumberToYMD(this.adjustedTodayDayNumber + 1);

    if (this.obsWeatherRollups.some((a) => a.dayDisplayYMD == dayDisplayYMD)) {
      this._languageService.warning('AC.EQUIP.OBS_WEATHER_EXISTS');
      return;
    }

    // default to tomorrow to ensure row is always created; this will be updated on save by Manual entry
    const value = {
      dayDisplayYMD: dayDisplayYMD,
      dayNumber: -999,
      AssetId: this.equipmentId,
      EToShort: null,
      HumidityMax: null,
      HumidityMean: null,
      HumidityMin: null,
      PanEvaporation: null,
      Rainfall: null,
      SolarRadiation: null,
      TemperatureMax: null,
      TemperatureMean: null,
      TemperatureMin: null,
      WindSpeedMean: null,
    } as ObsWeatherRollup;

    const obsWeatherRollupType = this.entityManager.metadataStore.getEntityType(
      'ObsWeatherRollup',
    ) as breeze.EntityType;
    const newObsWeatherRollupEntity = obsWeatherRollupType.createEntity(value);
    this.entityManager.addEntity(newObsWeatherRollupEntity);

    // refresh respective obsEntity
    this.reloadObsEntities('ObsWeatherRollup');
  }

  private reloadObsEntities(obsName: string) {
    const defer = this._q.defer<void>();

    switch (obsName) {
      case 'ObsWaterFlow':
        this.obsWaterFlows = this.attachFilterDatesFn(this.entityManager.getEntities(obsName) as ObsWaterFlow[], this.filterObsWaterFlowDates);

        break;

      case 'ObsSoilMoisture':
        this.obsSoilMoistures = ArrayUtils.sortByString(this.entityManager.getEntities(obsName) as ObsSoilMoisture[], (x) => x.dayDisplayYMD);

        this.obsDisplayDates = this.obsSoilMoistures.map((obsSoilMoisture) => ({
          date: moment(obsSoilMoisture.dayDisplayYMD, 'YYYY-MM-DD').toDate(),
          dayNumber: obsSoilMoisture.dayNumber,
          dayDisplayYMD: obsSoilMoisture.dayDisplayYMD,
          id: obsSoilMoisture.Id,
          depth: obsSoilMoisture.Depth,
          newDepth: obsSoilMoisture.Depth,
        }));

        break;

      case 'ObsWeatherRollup': {
        this.obsWeatherRollups = this.attachFilterDatesFn(
          ArrayUtils.sortByString(this.entityManager.getEntities(obsName) as ObsWeatherRollup[], (x) => x.dayDisplayYMD),
          this.filterObsWeatherStationDates
        );

        const dayDisplayYMD = this._dayNumberService.convertDayNumberToYMD(this.adjustedTodayDayNumber);

        this.obsWeatherRollups.map((wrr) => {
          if (wrr.dayDisplayYMD >= dayDisplayYMD) {
            wrr.localDate = null;
          } else {
            wrr.ManualDateTime = moment(wrr.dayDisplayYMD, 'YYYY-MM-DD').toDate();
          }
        });

        break;
      }
    }
  }

  public viewScheduleChange() {
    this.primaryTab = this.getPrimaryTabIndex(PrimaryTabEnum.Settings);
    this.secondaryTab = this.getSecondaryTabIndex(SecondarySettingsTabEnum.Automated);
  }

  public onWeatherTypeChanged() {
    this.generateObsWeatherChart();
  }

  public onTabSelected_Observations() {
    this.handleGenerateChart();
  }

  private handleGenerateChart(): void {
    if (this.equipmentType.isSoilMoisture) {
      this.generateObsSoilMoistureChart();
    } else if (this.equipmentType.isWaterFlowMeter) {
      this.generateObsWaterFlowChart();
    } else if (this.equipmentType.isWeatherStation) {
      this.generateObsWeatherChart();
    }
  }

  private renderChart(chartDivId: string, chartDataProvider: any[], chartGraphs: AmCharts.AmGraph[], axisTitle: string, axisMinimum = 0) {
    const generateChart = (
      chartDivId: string,
      chartDataProvider: any[],
      axisTitle: string,
      chartGraphs: AmCharts.AmGraph[],
      axisMinimum: number,
    ): void => {
      const chartColours = [
        '#2196F3',
        '#1A237E',
        '#90CAF9',
        '#1976D2',
        '#42A5F5',
        '#0D47A1',
        '#9FA8DA',
        '#303F9F',
        '#5C6BC0',
        '#2962FF',
      ];

      this.obsChart = (AmCharts as any).makeChart(chartDivId, {
        type: 'serial',
        theme: 'light',
        dataProvider: chartDataProvider,
        dataDateFormat: 'YYYY-MM-DD',
        marginTop: 20,
        marginRight: 20,
        marginLeft: 100,
        mouseWheelZoomEnabled: true,
        valueAxes: [
          {
            id: 'v1',
            axisAlpha: 0,
            position: 'left',
            ignoreAxisWidth: true,
            title: axisTitle,
            minimum: axisMinimum,
          },
        ],
        balloon: {
          borderThickness: 1,
          shadowAlpha: 0,
        },
        colors: chartColours,
        graphs: chartGraphs,
        chartScrollbar: {
          graph: 'g1',
          oppositeAxis: false,
          offset: 30,
          scrollbarHeight: 50,
          backgroundAlpha: 0,
          selectedBackgroundAlpha: 0.1,
          selectedBackgroundColor: '#888888',
          graphFillAlpha: 0.1,
          graphLineAlpha: 0.1,
          selectedGraphFillAlpha: 0.1,
          selectedGraphLineAlpha: 0.1,
          autoGridCount: true,
          color: '#AAAAAA',
        },
        chartCursor: {
          pan: true,
          valueLineEnabled: true,
          valueLineBalloonEnabled: true,
          cursorAlpha: 1,
          cursorColor: '#258cbb',
          limitToGraph: 'g1',
          valueLineAlpha: 0.2,
          valueZoomable: true,
        },
        categoryField: 'dayDisplayYMD',
        categoryAxis: {
          parseDates: true,
          dashLength: 1,
          minorGridEnabled: true,
        },
      });

      this.obsChart.validateData();

      if (this.obsChart.dataProvider.length) {
        const lastIndex = this.obsChart.dataProvider.length;
        const data = this.obsChart.dataProvider;
        const startDate = moment(data[0].dayDisplayYMD, 'YYYY-MM-DD').toDate().addDays(-1);
        const endDate = moment(data[lastIndex - 1].dayDisplayYMD, 'YYYY-MM-DD')
          .toDate()
          .addDays(1);

        this.obsChart.zoomToDates(startDate, endDate);
      }
    };

    const setChartVisibility = (hasData: boolean): void => {
      const getChartDivId = () => {
        if (this.equipmentType.isWaterFlowMeter) {
          return 'obs-water-flows-chart';
        } else if (this.equipmentType.isWeatherStation) {
          return 'obs-weather-rollups-chart';
        } else if (this.equipmentType.isSoilMoisture) {
          return 'obs-soil-moistures-chart';
        } else {
          return '';
        }
      };

      const chartDivId = getChartDivId();

      if (!chartDivId) {
        return;
      }

      const chartDiv = document.getElementById(chartDivId);

      if (chartDiv) {
        chartDiv.style.display = hasData ? 'block' : 'none';
      }
    };

    // Draw the chart - onload, onreload, etc, small timeout to control redraw.
    this._timeout(() => {
      const hasData = !!chartDataProvider?.length;

      if (hasData) {
        generateChart(chartDivId, chartDataProvider, axisTitle, chartGraphs, axisMinimum);
      }

      setChartVisibility(hasData);
    }, 1000);
  }

  public gotoEquipmentList() {
    this._equipmentService.setKeepFilter(true);
    this._state.go('app.account.equipments');
  }

  public litresPerSec(litres: number, dechours: number): string {
    if (dechours <= 0) {
      return '-';
    } else {
      return (litres / (24 * dechours * 3600)).toFixed(2);
    }
  }

  public gotoEquipmentDetail(equip: fuse.equipmentProfileDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.assetId = equip.assetId;
      context.tab1 = this.primaryTab;
      context.tab2 = this.secondaryTab;
    });

    this._state.go('app.account.equipments.detail', {
      id: equip.assetId,
      isSwitchEquipment: true,
      viewRange: false,
    });
  }

  public clickShowObsButton(): void {
    this.isShowObs = !this.isShowObs;
  }

  private removeSiteLink(siteId: number) {
    const saPred: breeze.Predicate = breeze.Predicate.create('ParentAssetId', '==', siteId).and('ChildAssetId', '==', this.equipmentId);

    const query = breeze.EntityQuery.from('SiteAsset').where(saPred);

    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(query); // query the cache (synchronous)

    if (sa.length == 1) {
      sa[0].entityAspect.setDeleted();
    }
  }

  private addSiteLink(siteId: number) {
    const siteAssetRec = {
      ParentAssetId: siteId, // Site - Asset Id
      ChildAssetId: this.equipmentId, //  equipment Asset Id
      DataInputId: 1, // Management Area (Site) Link this.equipment_.Asset.AssetClass.DataInputId,
      Coefficient: 1,
      Priority: 1,
      Factor: 1,
    };

    const newSiteAsset = this.entityManager.createEntity('SiteAsset', siteAssetRec);
    this.entityManager.addEntity(newSiteAsset);
  }

  // *** On categories selector open
  public onSitesSelectorOpen() {
    this.savedSelection = this.associatedSites;
    this._document.find('md-select-header input[type="search"]').on('keydown', (e) => {
      e.stopPropagation();
    });
  }

  public onSitesSelectorChanged() {
    if (this.savedSelection == null) this.savedSelection = [];
    if (this.associatedSites == null) this.associatedSites = [];
    this.savedSelection.forEach((siteId) => {
      if (this.associatedSites.indexOf(siteId) < 0) {
        this.removeSiteLink(siteId);
      }
    });
    this.associatedSites.forEach((siteId) => {
      if (this.savedSelection.indexOf(siteId) < 0) {
        this.addSiteLink(siteId);
      }
    });
    this.savedSelection = this.associatedSites;
  }

  // *** On categories selector close
  public onSitesSelectorClose() {
    // Clear the filter
    this.searchTerm = '';
    this._document.find('md-select-header input[type="search"]').off('keydown');
    if (this.savedSelection == null) this.savedSelection = [];
    if (this.associatedSites == null) this.associatedSites = [];
    this.savedSelection.forEach((siteId) => {
      if (this.associatedSites.indexOf(siteId) < 0) {
        this.removeSiteLink(siteId);
      }
    });
    this.associatedSites.forEach((siteId) => {
      if (this.savedSelection.indexOf(siteId) < 0) {
        this.addSiteLink(siteId);
      }
    });
    angular.forEach(this.scope['summaryForm'].siteLinkForm, (field) => {
      if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
        if (field.$name == 'associatedSites') {
          if (!this.associatedSites?.length) {
            field.$error = { required: true };
            field.$invalid = true;
          } else {
            field.$error = {};
            field.$valid = true;
            field.$setValidity('required', true);
          }
        }
      }
    });
  }

  private loadAssetSettings(assetConfig: string, currSettings: object) {
    if (assetConfig) {
      const configs = assetConfig.split(',');
      for (let instance = 0; instance < this.AssetRepeat; instance++) {
        // load properties and default values
        for (let jdx = 0; jdx < configs.length; jdx++) {
          // Particulars and Default settings
          const keyVal = configs[jdx].split('=');
          const key = keyVal[0];
          const val = keyVal.length > 1 ? keyVal[1] : undefined;

          this.AssetSettings[instance][key] = val; // default setting

          // override default (if any) with existing settings
          if (currSettings[instance] && currSettings[instance][key]) {
            this.AssetSettings[instance][key] = currSettings[instance][key];
          }
        }
      }
    }
  }

  public getSettings() {
    this.AssetSettings = [];
    this.setTopText();

    if (this.equipment_.DataCollectionProfileId) {
      if (this.equipmentAccountInfo.dataCollectors && this.equipment_) {
        const defaultWorkAgent = this.equipmentAccountInfo.dataCollectors.find((a) => a.id == this.equipment_.DataCollectionProfileId);

        if (defaultWorkAgent == null) {
          return;
        } else {
          this.isAutomaticCollector = !(defaultWorkAgent.isManual ?? false);

          if (defaultWorkAgent.assetClassName == AssetClassNameEnum.WaterFlowMeter && defaultWorkAgent.isTrueMeter) {
            this.equipmentType.isTrueFlowMeter = true;
            this.meterUnits = SWANConstants.units.filter((a) => a.baseClassId == defaultWorkAgent.unitBaseClassId);

            if (this.isObsExisted) {
              this.isProfileChangable = false;
            } else {
              this.isProfileChangable = true;
            }

            this._timeout(() => {
              this.changeMeterUnit();
            }, 100);
          } else {
            this.equipmentType.isTrueFlowMeter = false;
          }
        }

        // existing settings?
        this._sensorPropertyService
          .loadCollectorProperties(defaultWorkAgent, this.equipment_.Asset.PropertyValues, this.equipmentId)
          .then((result) => {
            this.collectorProperties = result as PropertyValue[];

            // If no new sensor property settings found, default to old json format settings
            if (this.collectorProperties?.length) {
              return;
            }

            this.loadOldSettings(defaultWorkAgent);
          });
      }
    } else if (this.collectorProperties?.length) {
      this._sensorPropertyService.deleteOldProperties([], this.collectorProperties);
      this.collectorProperties = [];
    }
  }

  public loadOldSettings(defaultWorkAgent: fuse.dataCollectorProfileDto) {
    const currSettings = this.equipment_.AssetConfig ? JSON.parse(this.equipment_.AssetConfig) : {};
    this.AssetRepeat = 1;

    if (defaultWorkAgent.workAgentCollectorTemplate) {
      const keyVal = defaultWorkAgent.workAgentCollectorTemplate.split('=');
      const key = keyVal[0]; // "REPEAT"
      const value = keyVal[1]; // repeat number

      if (key === 'REPEAT') {
        this.AssetRepeat = parseInt(value);
      }
    }
    for (let instance = 0; instance < this.AssetRepeat; instance++) {
      this.AssetSettings.push({}); // create AssetSettings[instance]
    }
    if (defaultWorkAgent.workAgentCollectorAssetConfig) {
      const configItems = defaultWorkAgent.workAgentCollectorAssetConfig.split(',');

      // Handle conglomerate collectors (different types of request packets from one sensor)
      if (configItems && configItems.length == 1 && configItems[0].indexOf('COLLECTORS=|') == 0) {
        // such as Collectors=|1|2|3|4|5|6|
        const keyVal = configItems[0].split('=');
        const collectorItems: string[] = keyVal[1].split('|'); // listing split by pipe character
        collectorItems.forEach((collectorItem) => {
          const collectorId = parseInt(collectorItem);
          if (isFinite(collectorId) && collectorId != null) {
            const workAgent = this.equipmentAccountInfo.workAgentCollectors.find((a) => a.id == collectorId);
            if (workAgent != null) {
              this.loadAssetSettings(workAgent.workAgentCollectorAssetConfig, currSettings);
            }
          }
        });
      } else {
        this.loadAssetSettings(defaultWorkAgent.workAgentCollectorAssetConfig, currSettings);
      }
    }
    this.updateAssetSettings();
  }

  public addNewRange(): void {
    let lastValue: fuse.healthIndexValueRangeDto;
    let newValue: fuse.healthIndexValueRangeDto;
    if (this.filteredHealthRanges.length > 2) {
      lastValue = this.filteredHealthRanges[this.filteredHealthRanges.length - 2];
    } else {
      lastValue = this.filteredHealthRanges[0];
    }

    if (lastValue != null) {
      if (moment(lastValue.effectiveFrom) >= moment(this.irrigationSeasonEndDate).subtract(1, 'days')) {
        return;
      }

      const date = moment(lastValue.effectiveFrom).add(1, 'days').toDate();
      const dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

      newValue = {
        assetId: this.equipmentId,
        effectiveFrom: date,
        dayNumber: this._dayNumberService.convertDateToLocaleDayNumber(dateUTC, 'UTC'),
        dataInputId: this.currentDataInputId,
        targetRangeLow: lastValue.targetRangeLow,
        targetRangeHigh: lastValue.targetRangeHigh,
        variabilityLow: lastValue.variabilityLow,
        variabilityHigh: lastValue.variabilityHigh,
        modified: true,
      } as fuse.healthIndexValueRangeDto;
    } else {
      const defaultTarget = this.equipmentAccountInfo.defaultValueRanges.find(
        (v) => v.attribute == 'Reading' && v.dataInputId == this.currentDataInputId,
      );
      const defaultVariability = this.equipmentAccountInfo.defaultValueRanges.find(
        (v) => v.attribute == 'Variability' && v.dataInputId == this.currentDataInputId,
      );
      const date = moment(this.irrigationSeasonStartDate).toDate();
      newValue = {
        assetId: this.equipmentId,
        effectiveFrom: date,
        dayNumber: this._dayNumberService.convertDateToLocaleDayNumber(date, 'UTC'),
        dataInputId: this.currentDataInputId,
        targetRangeLow: defaultTarget == null ? 0.6 : defaultTarget.readingLow,
        targetRangeHigh: defaultTarget == null ? 0.8 : defaultTarget.readingHigh,
        variabilityLow: defaultVariability == null ? 0.2 : defaultVariability.readingLow,
        variabilityHigh: defaultVariability == null ? 0.3 : defaultVariability.readingHigh,
        modified: true,
      } as fuse.healthIndexValueRangeDto;
    }
    this.allHealthRanges.push(newValue);
    this.setSettingFormDirty();
    this.filterRanges();
    this.isValueRangeChanged = true;
  }

  public deleteRange(range: fuse.healthIndexValueRangeDto): void {
    const confirm = this._mdDialog
      .confirm()
      .title(this._languageService.instant('COMMON.CONFIRM'))
      .htmlContent(this._languageService.instant('AC.EQUIP.CONFIRM_DELETE', { range: DateUtils.Locale.asDateDayAndMonth(range.effectiveFrom) }))
      .parent(angular.element(document.body))
      .ok(this._languageService.instant('COMMON.CONFIRM'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));
    this._mdDialog.show(confirm).then(() => {
      this._mdDialog.hide();
      const rangeIndex = this.allHealthRanges.findIndex((a) => a == range);
      this.allHealthRanges.splice(rangeIndex, 1);
      this.setSettingFormDirty();
      this.filterRanges();
      this.isValueRangeChanged = true;
    });
  }

  public editRange(range: fuse.healthIndexValueRangeDto): void {
    //range.effectiveFrom = moment.utc(moment(range.effectiveFrom).format("YYYY-MM-DD")).startOf("day").toDate();
    const dateUTC = new Date(
      Date.UTC(range.effectiveFrom.getFullYear(), range.effectiveFrom.getMonth(), range.effectiveFrom.getDate()),
    );
    range.dayNumber = this._dayNumberService.convertBrowserDateToLocaleDayNumber(dateUTC, 'UTC');
    range.modified = true;
    this.filterRanges();
    this.setSettingFormDirty();
    this.isValueRangeChanged = true;
  }

  public shiftRange(range: fuse.healthIndexValueRangeDto, shiftDirection: number): void {
    const rangeIndex = this.filteredHealthRanges.findIndex((a) => a == range);
    if (shiftDirection > 0) {
      const nextVal = this.filteredHealthRanges[rangeIndex + 1];
      const nextNext = this.filteredHealthRanges[rangeIndex + 2];
      let newDate = moment(nextVal.effectiveFrom).add(1, 'days').toDate();
      if (newDate >= nextNext.effectiveFrom) {
        newDate = moment(nextVal.effectiveFrom).toDate();
        nextVal.effectiveFrom = moment(nextVal.effectiveFrom).subtract(1, 'days').toDate();
        nextVal.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(nextVal.effectiveFrom);
      }
      range.effectiveFrom = newDate;
      range.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(newDate);
    } else if (shiftDirection < 0) {
      const prevVal = this.filteredHealthRanges[rangeIndex - 1];
      const prevPrev = this.filteredHealthRanges[rangeIndex - 2];
      let newDate = moment(prevVal.effectiveFrom).subtract(1, 'days').toDate();
      if (newDate <= prevPrev.effectiveFrom) {
        newDate = moment(prevVal.effectiveFrom).toDate();
        prevVal.effectiveFrom = moment(prevVal.effectiveFrom).add(1, 'days').toDate();
        prevVal.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(prevVal.effectiveFrom);
      }
      range.effectiveFrom = newDate;
      range.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(newDate);
    }
    this.filterRanges();
    this.setSettingFormDirty();
    this.isValueRangeChanged = true;
  }

  public copyRepeatDialog(): void {
    this._mdDialog
      .show({
        controller: CopyValueRangeDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/equipment/settings/copy-value-range-dialog.html',
        clickOutsideToClose: false,
        locals: {
          assetId: this.equipmentId,
        },
      })
      .then((dto: fuse.valueRangeCopyDto) => {
        if (dto) {
          dto.dataInputId = this.currentDataInputId;
          this.copyValueRange(dto).then(
            () => {
              this._languageService.success('AC.EQUIP.VALUE_RANGE_COPIED');
              this.fetchValueRanges(true);
            },
            (err) => {
              console.log('Copy Value range failed ' + JSON.stringify(err));
              this._languageService.whoops();
            },
          );
        }
      });
  }

  public noFutureDates(): Date {
    const date = this._dayNumberService.convertDayNumberToDate(this.adjustedTodayDayNumber - 1);
    return date;
  }

  public updateAssetSettings() {
    if (this.equipment_ && this.AssetSettings.length) {
      const jsonAssetSettings = JSON.stringify(this.AssetSettings);
      if (this.equipment_.AssetConfig != jsonAssetSettings) {
        this.equipment_.AssetConfig = jsonAssetSettings;
      }
    }
  }

  public lockDialog(obs: ObsWeatherRollup | ObsWaterFlow) {
    const lock = obs.Locked ? 'UNLOCK_ROW' : 'LOCK_ROW';
    const lockText = this._languageService.instant(`AC.EQUIP.${lock}_MSG`);
    const title = this._languageService.instant(`AC.EQUIP.${lock}`);

    const confirm = this._mdDialog
      .confirm()
      .title(title)
      .htmlContent(lockText)
      .ariaLabel(title)
      .ok(this._languageService.instant('COMMON.CONFIRM'))
      .cancel(this._languageService.instant('COMMON.CANCEL'));

    this._mdDialog.show(confirm).then((toggle) => {
      if (toggle) {
        obs.toggleLock();
      }
    });
  }

  public saveBeforeAdd() {
    const alert = this._languageService
      .basicAlert({ title: 'COMMON.ALERT', content: 'AC.EQUIP.SAVE_BEFORE_ADD', ok: 'COMMON.CLOSE' } as DialogParams)
      .targetEvent();
    this._mdDialog.show(alert).then(() => {
      this._mdDialog.hide();
    });
  }

  public addSoilMoistureReading() {
    if (this.isReadOnly) {
      return;
    }

    if (this.hasDataChanges) {
      return this.saveBeforeAdd();
    }

    this._mdDialog
      .show({
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: AddSoilMoistureReadingDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/account/views/equipment/observations/add-soil-moisture-reading-dialog.html',
        locals: {
          assetId: this.equipmentId,
          fromDate: this.obsFromDate,
          toDate: this.obsToDate,
          readings: this.obsSoilMoistures,
        },
      } as angular.material.IDialogOptions)
      .then((updated) => {
        if (updated) {
          this.reloadObsEntities('ObsSoilMoisture');
          this.refreshObs(null);
        }
      });
  }

  public addWaterFlowReading() {
    if (this.isReadOnly) {
      return;
    }

    if (this.hasDataChanges) {
      return this.saveBeforeAdd();
    }

    this._mdDialog
      .show({
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: AddWaterFlowReadingDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/account/views/equipment/observations/add-water-flow-reading-dialog.html',
        locals: {
          assetId: this.equipmentId,
          fromDate: this.obsFromDate,
          toDate: this.obsToDate,
          readings: this.obsWaterFlows,
          isTodayDateAllowed: false,
        },
      })
      .then((updated) => {
        if (updated) {
          this.reloadObsEntities('ObsWaterFlow');
          this.refreshObs(null);
        }
      });
  }

  public changeObsSoilMoistureDepth(obs: ObsSoilMoisture, displayDate: IObsDisplayDate): void {
    if (this.obsSoilMoistures.some((sm) => sm.Id != displayDate.id && sm.dayNumber == obs.dayNumber && sm.Depth == displayDate.newDepth)) {
      this._languageService.warning('AC.EQUIP.SOIL_READING_EXISTS');

      return;
    }

    displayDate.depth = displayDate.newDepth;
    obs.Depth = displayDate.newDepth;

    this.generateObsSoilMoistureChart();
  }

  public changeObsSoilMoistureDate(obs: ObsSoilMoisture, displayDate: IObsDisplayDate): void {
    if (this.obsSoilMoistures.some((a) => a.dayDisplayYMD == moment(displayDate.date).format('YYYY-MM-DD') && a.Depth == obs.Depth)) {
      this._languageService.warning('AC.EQUIP.SOIL_READING_EXISTS');

      return;
    }

    displayDate.dayDisplayYMD = moment(displayDate.date).format('YYYY-MM-DD');
    obs.localDate = displayDate.date;

    this.generateObsSoilMoistureChart();
  }

  public changeObsSoilMoistureValue(): void {
    this.generateObsSoilMoistureChart();
  }

  public changeObsWaterFlowValue(): void {
    this.generateObsWaterFlowChart();
  }

  public changeObsWeatherValue(): void {
    this.generateObsWeatherChart();
  }

  private generateObsSoilMoistureChart(): void {
    // Soil Moisture Probe - N graphs, where N is the number of flux capacitors attached to the probe

    const getObsSoilMoistureChartData = (): { chartDataProvider: any[]; chartGraphs: AmCharts.AmGraph[] } => {
      // Transform the data into Depth series
      const chartData = {};
      const uniqueDepths = [];
      const uniqueDepthsObj = {};

      for (let idx = 0; idx < this.obsSoilMoistures.length; idx++) {
        const sm = this.obsSoilMoistures[idx];
        const prop = sm.Depth.toString();

        if (sm.dayDisplayYMD) {
          if (!chartData[sm.dayDisplayYMD]) {
            // new record
            chartData[sm.dayDisplayYMD] = {
              dayDisplayYMD: sm.dayDisplayYMD,
            };
          }

          // extend the record
          chartData[sm.dayDisplayYMD][prop] = sm.Moisture;

          if (!uniqueDepthsObj[sm.Depth]) {
            uniqueDepthsObj[sm.Depth] = { Depth: sm.Depth };
            uniqueDepths.push(sm.Depth);
          }
        }
      }

      // Build the data array - sorted by date
      const chartDataProvider = ArrayUtils.sortStrings(Object.keys(chartData)).map((v) => chartData[v]);
      const chartGraphs =
        uniqueDepths.map((depth, idx) => ({
          balloonText: `${this.soilDepthUnit.fromBaseRounded(depth)}${this.soilDepthUnit.name} <span style='font-size: 14px;'>[[value]]</span> %`,
          bullet: 'round',
          bulletBorderAlpha: 1,
          bulletSize: 5,
          connect: false,
          hideBulletsCount: 50,
          id: `g${idx + 1}`,
          lineThickness: 1,
          title: depth,
          useLineColorForBulletBorder: true,
          valueField: depth.toString(),
        }) as AmCharts.AmGraph);

      return {
        chartDataProvider,
        chartGraphs,
      };
    };

    const chartDivId = 'obs-soil-moistures-chart';
    const { chartDataProvider, chartGraphs } = getObsSoilMoistureChartData();
    const axisTitle = this._languageService.instant('COMMON.WITH_UNITS.SOIL_MOISTURE', { units: '%' }, false);

    this.renderChart(chartDivId, chartDataProvider, chartGraphs, axisTitle);
  }

  private generateObsWaterFlowChart(): void {
    const getObsWaterFlowChartData = (): { chartDataProvider: any[]; chartGraphs: AmCharts.AmGraph[] } => {
      const chartDataProvider = this.obsWaterFlows.map((item) => ({
        dayDisplayYMD: item.dayDisplayYMD,
        VolumeKL: this.volumeUnit.fromBaseRounded(item.VolumeKL),
      }));

      const chartGraphs = [
        {
          id: 'g1',
          useLineColorForBulletBorder: true,
          valueField: 'VolumeKL',
          balloonText: `<span style='font-size:14px;'>[[value]]</span>`,
          type: 'column',
          fillAlphas: 0.8,
          lineAlpha: 0.2,
        } as AmCharts.AmGraph,
      ];

      return {
        chartDataProvider,
        chartGraphs,
      };
    };

    const chartDivId = 'obs-water-flows-chart';
    const { chartDataProvider, chartGraphs } = getObsWaterFlowChartData();
    const axisTitle = this._languageService.instant('COMMON.WITH_UNITS.VOLUME', { units: this.volumeUnit.name }, false);

    this.renderChart(chartDivId, chartDataProvider, chartGraphs, axisTitle);
  }

  private generateObsWeatherChart(): void {
    let vertValueField1: string;
    let vertValueField2: string;
    let vertValueField3: string;
    let balloonText1: string;
    let balloonText2: string;
    let balloonText3: string;
    let axisTitle = '';
    let axisMinimum = 0;

    const getWeatherTypeData = (obs: ObsWeatherRollup) => {
      const temperatureMin = this.temperatureUnit.fromBaseRounded(obs.TemperatureMin);

      if (axisMinimum > temperatureMin && this.selectedWeatherType === 'TEMPERATURE') {
        axisMinimum = temperatureMin;
      }

      return {
        // ETo
        etoShort: this.etoUnit.fromBaseRounded(obs.EToShort),
        // Humidity
        humidityMax: this.humidityUnit.fromBaseRounded(obs.HumidityMax),
        humidityMean: this.humidityUnit.fromBaseRounded(obs.HumidityMean),
        humidityMin: this.humidityUnit.fromBaseRounded(obs.HumidityMin),
        // Rainfall
        rainfall: this.rainfallUnit.fromBaseRounded(obs.Rainfall),
        // Solar Radiation
        solarRadiation: this.solarRadiationUnit.fromBaseRounded(obs.SolarRadiation),
        // Temperature
        temperatureMax: this.temperatureUnit.fromBaseRounded(obs.TemperatureMax),
        temperatureMean: this.temperatureUnit.fromBaseRounded(obs.TemperatureMean),
        temperatureMin: temperatureMin,
        // Wind Speed
        windSpeedMean: this.windSpeedUnit.fromBaseRounded(obs.WindSpeedMean),
      };
    };

    const configureWeatherTypesWithData = (chartDataProvider: any[]) => {
      const getPropPrefixForWeatherType = (weatherType: string) => {
        switch (weatherType) {
          case 'ETO': {
            return 'eto';
          }

          case 'HUMIDITY': {
            return 'humidity';
          }

          case 'RAINFALL': {
            return 'rainfall';
          }

          case 'SOLAR_RADIATION': {
            return 'solarRadiation';
          }

          case 'TEMPERATURE': {
            return 'temperature';
          }

          case 'WINDSPEED': {
            return 'windSpeed';
          }
        }
      }

      const setInitialSelectedWeatherType = () => {
        if (!this.selectedWeatherType) {
          const preferredWeatherType = 'TEMPERATURE';

          // Check if the preffered weather type is disabled. If so, then try to select the first non-disabled weather type, otherwise stick with the preffered option.
          if (this.weatherTypeOptions.find((option) => option.key === preferredWeatherType).isDisabled) {
            this.selectedWeatherType = this.weatherTypeOptions.find((option) => !option.isDisabled)?.key ?? preferredWeatherType;
          } else {
            this.selectedWeatherType = preferredWeatherType;
          }
        }
      };

      // Set each weather type 'isDisabled' flag based on its data existance.
      this.weatherTypeOptions.forEach((option) => {
        option.isDisabled = !chartDataProvider.some((data) =>
          Object.keys(data).filter((key) => key !== 'dayDisplayYMD').some((key) => {
            const propPrefix = getPropPrefixForWeatherType(option.key);

            if (key.startsWith(propPrefix)) {
              return data[key] != null;
            }
          })
        );
      });

      setInitialSelectedWeatherType();
    };

    const getObsWeatherChartData = (): { chartDataProvider: any[]; chartGraphs: AmCharts.AmGraph[] } => {
      const chartDataProvider = this.obsWeatherRollups.map((obs) => ({
        ...getWeatherTypeData(obs),
        dayDisplayYMD: obs.dayDisplayYMD,
      }));

      configureWeatherTypesWithData(chartDataProvider);

      switch (this.selectedWeatherType) {
        case 'ETO': {
          vertValueField1 = 'etoShort';
          balloonText1 = `<span style='font-size:14px;'>[[value]]</span> ${this.etoUnit.name}`;
          axisTitle = this._languageService.instant('COMMON.WITH_UNITS.EVAPORATION', { units: this.etoUnit.name }, false);

          break;
        }

        case 'HUMIDITY': {
          vertValueField1 = 'humidityMax';
          vertValueField2 = 'humidityMin';
          vertValueField3 = 'humidityMean';
          balloonText1 = this._languageService.instant('COMMON.MAX') + " <span style='font-size:14px;'>[[value]]</span> %";
          balloonText2 = this._languageService.instant('COMMON.MIN') + " <span style='font-size:14px;'>[[value]]</span> %";
          balloonText3 = this._languageService.instant('COMMON.MEAN') + " <span style='font-size:14px;'>[[value]]</span> %";
          axisTitle = this._languageService.instant('COMMON.WITH_UNITS.HUMIDITY', { units: '%' }, false);

          break;
        }

        case 'RAINFALL': {
          vertValueField1 = 'rainfall';
          balloonText1 = `<span style='font-size:14px;'>[[value]]</span> ${this.rainfallUnit.name}`;
          axisTitle = this._languageService.instant('COMMON.WITH_UNITS.RAINFALL', { units: this.rainfallUnit.name }, false);

          break;
        }

        case 'SOLAR_RADIATION': {
          vertValueField1 = 'solarRadiation';
          balloonText1 = `<span style='font-size:14px;'>[[value]]</span> ${this.solarRadiationUnit.name}`;
          axisTitle = this._languageService.instant('COMMON.WITH_UNITS.SOLAR_RADIATION', {
            units: this.solarRadiationUnit.name,
          }, false);

          break;
        }

        case 'TEMPERATURE': {
          vertValueField1 = 'temperatureMax';
          vertValueField2 = 'temperatureMin';
          vertValueField3 = 'temperatureMean';
          balloonText1 = `${this._languageService.instant('COMMON.MAX')} <span style='font-size:14px;'>[[value]]</span> ${
            this.temperatureUnit.name
          }`;
          balloonText2 = `${this._languageService.instant('COMMON.MIN')} <span style='font-size:14px;'>[[value]]</span> ${
            this.temperatureUnit.name
          }`;
          balloonText3 = `${this._languageService.instant('COMMON.MEAN')} <span style='font-size:14px;'>[[value]]</span> ${
            this.temperatureUnit.name
          }`;
          axisTitle = this._languageService.instant('COMMON.WITH_UNITS.TEMPERATURE', { units: this.temperatureUnit.name }, false);

          break;
        }

        case 'WINDSPEED': {
          vertValueField1 = 'windSpeedMean';
          balloonText1 = `<span style='font-size:14px;'>[[value]]</span> ${this.windSpeedUnit.name}`;
          axisTitle = this._languageService.instant('COMMON.WITH_UNITS.WIND_SPEED', { units: this.windSpeedUnit.name }, false);

          break;
        }
      }

      const chartGraphs = [
        {
          id: 'g1',
          title: 'Max',
          useLineColorForBulletBorder: true,
          valueField: vertValueField1,
          balloonText: balloonText1,
          type: 'column',
          fillAlphas: 0.8,
          lineAlpha: 0.2,
        },
        {
          id: 'g2',
          bullet: 'round',
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          bulletSize: 5,
          hideBulletsCount: 50,
          lineThickness: 2,
          title: 'Temp Min',
          useLineColorForBulletBorder: true,
          valueField: vertValueField2,
          balloonText: balloonText2,
          connect: false,
        },
        {
          id: 'g3',
          bullet: 'round',
          bulletBorderAlpha: 1,
          bulletColor: '#FFFFFF',
          bulletSize: 5,
          hideBulletsCount: 50,
          lineThickness: 2,
          title: 'Temp Mean',
          useLineColorForBulletBorder: true,
          valueField: vertValueField3,
          balloonText: balloonText3,
          connect: false,
        },
      ] as AmCharts.AmGraph[];

      return {
        chartDataProvider,
        chartGraphs,
      };
    };

    const chartDivId = 'obs-weather-rollups-chart';
    const { chartDataProvider, chartGraphs } = getObsWeatherChartData();

    this.renderChart(chartDivId, chartDataProvider, chartGraphs, axisTitle, axisMinimum);
  }

  private filterRanges() {
    if (this.currentDataInputId != null && this.allHealthRanges != null) {
      this.filteredHealthRanges =
        this.attachFilterDatesFn(
          ArrayUtils.sortByNumber(this.allHealthRanges.filter((a) => a.dataInputId == this.currentDataInputId), (x) => x.dayNumber),
          this.filterValueRangeDates
        );
    } else {
      this.filteredHealthRanges = [];
    }
  }

  public copyValueRange(dto: fuse.valueRangeCopyDto): angular.IPromise<any> {
    const defer = this._q.defer();
    this._http
      .post<any>(CommonHelper.getApiUrl('equipment/CopyValueRange/'), dto)
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });
    return defer.promise;
  }

  public changeDataInput() {
    this._equipmentService.setDataInputId(this.currentDataInputId);
    this.filterRanges();
    this.setSettingFormDirty();
  }

  private setSettingFormDirty() {
    this._timeout(() => {
      angular.forEach(this.scope['settingForm'], (childForm) => {
        let lowValue: number;
        let lowVarValue: number;

        angular.forEach(childForm, (field) => {
          if (typeof field === 'object' && field.hasOwnProperty('$modelValue')) {
            field.$setDirty();
            field.$setTouched();

            if (field.$name.includes('effectiveFrom')) {
              field.$error = {};
              field.$invalid = false;
            }
            if (angular.equals(field.$error, {}) || angular.equals(field.$error, { invalid: true })) {
              if (field.$name.includes('readingLow')) lowValue = field.$modelValue;
              if (field.$name.includes('readingHigh')) {
                const highValue = field.$modelValue;
                if (lowValue > highValue) {
                  field.$error = { invalid: true };
                  field.$invalid = true;
                  field.$modelValue = undefined;
                } else {
                  field.$error = {};
                  field.$invalid = false;
                }
              }
              if (field.$name.includes('variabilityLow')) lowVarValue = field.$modelValue;
              if (field.$name.includes('variabilityHigh')) {
                const highVarValue = field.$modelValue;
                if (lowVarValue > highVarValue) {
                  field.$error = { invalid: true };
                  field.$invalid = true;
                  field.$modelValue = undefined;
                } else {
                  field.$error = {};
                  field.$invalid = false;
                }
              }
            }
          }
        });
      });
    }, 500);
  }

  public selectCsvFile() {
    const fInput: any = document.getElementById('file');
    this.csvFile = fInput.files[0];
  }

  public downloadFlowMeterExcelTemplate() {
    const ws = XLSX.utils.json_to_sheet(this.flowMeterUploadTemplateCsv);
    /* add to workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* write workbook and force a download */
    XLSX.writeFile(wb, this._languageService.instant('AC.EQUIP.UPLOAD.TEMPLATE_FLOW') + '.xlsx');
  }

  public addInfraWaterFlow() {
    if (this.hasDataChanges) {
      return this.saveBeforeAdd();
    }
    this._mdDialog
      .show({
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: AddInfraWaterFlowDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/account/views/equipment/observations/add-infra-water-flow-dialog.html',
        locals: {
          assetId: this.equipmentId,
          equipment: this.equipment_,
          fromDate: this.obsFromDate,
          toDate: this.obsToDate,
          readings: this.obsInfraWaterFlows,
        },
      })
      .then((res) => {
        if (res) {
          this.getInfraObs();
        }
      });
  }

  public addInfraWaterDepth() {
    if (this.hasDataChanges) {
      return this.saveBeforeAdd();
    }
    this._mdDialog
      .show({
        clickOutsideToClose: false,
        escapeToClose: false,
        controller: AddInfraDepthVolumeDialogController,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        templateUrl: 'src/app/pages/account/views/equipment/observations/add-infra-depth-volume-dialog.html',
        locals: {
          assetId: this.equipmentId,
          fromDate: this.obsFromDate,
          toDate: this.obsToDate,
          obsDepths: this.obsInfraWaterDepths,
          depthMeterBoundaries: this.depthMeterBoundaries,
        },
      })
      .then((res) => {
        if (res) {
          this.getInfraObs();
        }
      });
  }

  public onInfraObsChanged(isValid: boolean) {
    this.isObsChanged = true;
    this.isObsValid = isValid;
    this._dataEntityService.hasDirtyCustomForm = true;
  }

  public changeMeterUnit() {
    const automatedForm = this.scope['settingForm']['automatedForm'];

    if (!automatedForm) {
      return;
    }

    if (this.equipment_.UnitId == null) {
      automatedForm.unit.$setValidity('required', false);
    } else {
      this.meterUnit = this._unitOfMeasureService.getUnitById(this.equipment_.UnitId);

      automatedForm.unit.$setValidity('required', true);
    }

    automatedForm.unit.$setTouched();
  }

  public isTrueFlowMeterSelectable(collector: fuse.dataCollectorProfileDto) {
    if (collector.isTrueMeter) {
      if (this.isObsExisted) {
        return 'disabled';
      }
    }
  }

  private getPrimaryTabIndex(primaryTabEnum: PrimaryTabEnum): number {
    const visibleTabs = ObjectUtils.filterObject(this.primaryTabs, (x) => x.isVisible);
    const index = Object.keys(visibleTabs).findIndex((key) => this.primaryTabs[key].enum === primaryTabEnum);

    return index >= 0 ? index : 0;
  }

  private getSecondaryTabIndex(secondaryTabEnum: SecondarySettingsTabEnum): number {
    const visibleSecondaryTabs = ObjectUtils.filterObject(this.secondaryTabs.Settings, (x) => x.isVisible);
    const index = Object.keys(visibleSecondaryTabs).findIndex((key) => this.secondaryTabs.Settings[key].enum === secondaryTabEnum);

    return index >= 0 ? index : 0;
  }
}

angular.module('app.account').controller('EquipmentDetailController', EquipmentDetailController);
