import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { EquipmentService } from '@services/equipment.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { BaseController } from 'src/app/base.controller';

export class CopyValueRangeDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _equipmentService: EquipmentService;
  private _languageService: LanguageService;

  public frmCopyRepeat: angular.IFormController;

  private sourceAssetId: number;

  private assets: Asset[] = [];
  private selectedAssets: number[] = [];
  private nameFilter: string = '';

  private unselectedChosen: number[] = [];
  private selectedChosen: number[] = [];

  private selectAllUnselectedState: boolean = false;
  private selectAllSelectedState: boolean = false;

  public disabled: boolean = false;

  private step: number = 0;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    EquipmentService: EquipmentService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    assetId: number,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._equipmentService = EquipmentService;
    this._languageService = LanguageService;
    this._mdDialog = $mdDialog;

    this.sourceAssetId = assetId;
  }

  $onInit() {
    this.fetchData();
  }

  public unselectedAssetList(): Asset[] {
    return this.assets.filter(
      (a) => this.selectedAssets.indexOf(a.AssetId) < 0 && a.Name.toLowerCase().includes(this.nameFilter.toLowerCase()),
    );
  }

  public selectedAssetList(): Asset[] {
    return this.assets.filter((a) => this.selectedAssets.indexOf(a.AssetId) >= 0);
  }

  public pushSelectedAssets(): void {
    this.selectedAssets = this.selectedAssets.concat(this.unselectedChosen);
    this.unselectedChosen = [];
    this.selectAllSelectedState = false;
    this.selectAllUnselectedState = false;
  }

  public popSelectedAssets(): void {
    this.selectedAssets = this.selectedAssets.filter((s) => this.selectedChosen.indexOf(s) < 0);
    this.selectedChosen = [];
    this.selectAllSelectedState = false;
    this.selectAllUnselectedState = false;
  }

  public selectAllUnselected(): void {
    if (this.selectAllUnselectedState) {
      this.unselectedChosen = this.unselectedAssetList().map((a) => a.AssetId);
    } else {
      this.unselectedChosen = [];
    }
  }

  public selectAllSelected(): void {
    if (this.selectAllSelectedState) {
      this.selectedChosen = this.selectedAssetList().map((a) => a.AssetId);
    } else {
      this.selectedChosen = [];
    }
  }

  private fetchData(): void {
    this._equipmentService.getSiteHealthAssets(this.accountId).then(
      (data) => {
        this.assets = ArrayUtils.sortByString(
          (data as Asset[]).filter((a) => a.Status == 'Active' && a.AssetId != this.sourceAssetId),
          (a) => a.Name,
        );
      },
      (error) => {
        console.log('Unable to load active site health asset list: ' + JSON.stringify(error));
        this._languageService.whoops();
        this._mdDialog.hide();
      },
    );
  }

  public setStep(step: number) {
    if (step < 0) {
      this.step = this.step + step;
    } else {
      this.step = step;
    }
  }

  public finish() {
    this.disabled = true;
    const dto = {
      sourceAssetId: this.sourceAssetId,
      targetAssetIds: this.selectedAssets.map((a) => a),
    } as fuse.valueRangeCopyDto;

    this._mdDialog.hide(dto);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('CopyValueRangeDialogController', CopyValueRangeDialogController);
