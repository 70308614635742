import * as angular from 'angular';
import * as moment from 'moment';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { UnitTypes, unitSizes } from '@common/enums';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { GroupSettingService } from '@services/account/group-setting.service';
import { ISiteCropCoeff, IWaterDays } from '../../AccountConstants';
import { Crop } from 'src/app/_DBContext/Crop';
import { CropCoefficient } from 'src/app/_DBContext/CropCoefficient';
import { SiteSettingsSoil } from 'src/app/_DBContext/SiteSettingsSoil';
import { BaseController } from 'src/app/base.controller';

class GroupSettingsController extends BaseController {
  public groupSettingService: GroupSettingService; // IMPORTANT: Must be public - referenced inside html (e.g., `gc.groupSettingService.*`).

  public rangeArray = []; // soil Moisture Ranges
  public rangeViews: any;
  public rangeView: number = 0;
  public longDayNames: string[] = DateUtils.Locale.getWeekdayNamesLong();

  // slider config - soil
  public sliderSoilMoistureTarget: any;
  public sliderDrainage = {};
  public sliderWorkingDepth = {};

  // Water
  public sliderWaterBudgetTarget: any;
  public sliderSystemEfficiencyCoefficient: any;
  public sliderEmitterLosses: any;
  public sliderWaterWettedArea: any;
  public sliderWaterWettedAreaDefault: number = 100;

  // Crop
  public sliderWSC;
  public sliderDSC;
  public sliderLAT;
  public sliderHAT;

  public maxPenRainValue_min: number;
  public minPenRainValue_max: number;

  // UI button / rollup controls
  public ShowingSoilMoistureInfo: boolean = false;
  public ShowingCropTypeHistory: boolean = false;
  public ShowingGrowthPhase: boolean = false;
  public ShowingCropCoeff: boolean = false;
  public ShowingCropCoeffHistory: boolean = false;
  public ShowingSprinklerInfo: boolean = false;

  // Water
  public imuWaterDays: IWaterDays[] = [];
  public imuWaterIntervalStart: Date;
  public imuWaterIntervalMaxDate: Date;

  // Crop
  public siteCropCoeffsCurrent: ISiteCropCoeff[] = []; // transposed siteCropCoeffs[0]

  private crops: Crop[] = []; // id, Name, etc from Crop
  private cropCoeffs: CropCoefficient[] = []; // cropCoefficient

  public fluidDepthUnit: uomUnit;
  public soilDepthUnit: uomUnit;

  public workingSoilDepth_mm: number;

  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;

  constructor(
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    GroupSettingService: GroupSettingService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.SiteFull);

    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;

    this.entityManager = DataEntityService.manager;
    this.groupSettingService = GroupSettingService;

    this.setDefaultWaterDays();

    this.fluidDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.FluidDepth, unitSizes.normal);
    this.soilDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.SoilDepth, unitSizes.normal);
  }

  public get soilSettings(): SiteSettingsSoil {
    return this.groupSettingService.siteSoil;
  }

  public set soilSettings(value: SiteSettingsSoil) {
    this.groupSettingService.siteSoil = value;
  }

  private setDefaultWaterDays(): void {
    this.imuWaterDays = this.longDayNames.map((day) => ({ day: day, isSelected: false }));
  }

  $onInit() {
    this.groupSettingService.siteSoil = null;
    this._fetchData();

    this._notifyingService.subscribe(NotifyEvents.Group.Settings.Soil.Base, this.scope, (event, data) => {
      this.groupSettingService.siteSoil = data;
      this.refreshSiteSoilSetting();
    });

    this._notifyingService.subscribe(NotifyEvents.Group.Settings.Crop.Base, this.scope, (event, data) => {
      this.groupSettingService.siteCrop = data;
      this.initSiteCropStressSliders();
      this.initialiseCropCoefficients();
    });

    this._notifyingService.subscribe(NotifyEvents.Group.Settings.Water.Base, this.scope, (event, data) => {
      this.groupSettingService.siteWater = data;
      this.refreshSiteWaterSetting();
    });

    const unbindWatcherWater = this.scope.$watch('vm.groupSettingService.siteWater', (setting) => {
      if (setting != null) {
        this.refreshSiteWaterSetting();
        // Once the site Water Setting load is complete,
        // there's no more need to watch the change
        // in the model value.
        unbindWatcherWater();
      }
    });

    const unbindWatcherSoil = this.scope.$watch('vm.groupSettingService.siteSoil', (setting) => {
      if (setting != null) {
        this.refreshSiteSoilSetting();
        //look at this in the future as it fires off the redraw of the weather twice when on weather tab and change sites
        unbindWatcherSoil();
      }
    });

    const unbindWatcherNoots = this.scope.$watch('vm.groupSettingService.siteNoots', (setting) => {
      if (setting != null) {
        unbindWatcherNoots();
      }
    });
  }

  private refreshSiteSoilSetting() {
    this.initSiteSoilMoistureSliders();
    this.initSiteDrainageCoefficientSlider();
    this.initSiteWorkingDepthSlider();
    this.initPenetratingRainFallRange();
    this.checkSoilDepth();
  }

  private refreshSiteWaterSetting() {
    this.initSiteWaterSliders();
    this.initWaterDays();
    this.initWaterInterval();
    this.initSystemEfficiencyCoefficientSlider();
  }

  private initSiteSoilMoistureSliders() {
    this.groupSettingService.initRangeArray();
    this.groupSettingService.initSoilMoistureSlider(!(this.apf.hasSiteSettingsSoilFull && this.apf.hasGroupSettingsSoilFull));
  }

  private initSiteWaterSliders() {
    if (!this.groupSettingService.siteWater.WaterBudgetLowerTarget_percent)
      this.groupSettingService.siteWater.WaterBudgetLowerTarget_percent = 70;

    if (!this.groupSettingService.siteWater.WaterBudgetUpperTarget_percent)
      this.groupSettingService.siteWater.WaterBudgetUpperTarget_percent = 100;

    this.sliderWaterBudgetTarget = {
      maxValue: this.groupSettingService.siteWater.WaterBudgetUpperTarget_percent,
      minValue: this.groupSettingService.siteWater.WaterBudgetLowerTarget_percent,
      options: {
        ceil: 200,
        disabled: !this.apf.hasSiteSettingsWaterFull,
        floor: 0,
        hideLimitLabels: true,
        onChange: () => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Water.DataChanges);
        },
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this.groupSettingService.siteWater.WaterBudgetLowerTarget_percent = value;
              return `<b>${this._languageService.instant('COMMON.UNDER_BUDGET')}:</b> ${value}%`;
            case 'high':
              this.groupSettingService.siteWater.WaterBudgetUpperTarget_percent = value;
              return `<b>${this._languageService.instant('COMMON.OVER_BUDGET')}:</b> ${value}%`;
            default:
              return value + '%';
          }
        },
      },
    };

    if (!this.groupSettingService.siteWater.CropWettedArea_perc) {
      this.groupSettingService.siteWater.CropWettedArea_perc = 100;
    }

    this.sliderWaterWettedArea = {
      options: {
        ceil: 100,
        disabled: !this.apf.hasSiteSettingsWaterWettedAreaFull,
        floor: 1,
        hideLimitLabels: true,
        onChange: () => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Water.DataChanges);
        },
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this.groupSettingService.siteWater.CropWettedArea_perc = value;
              //this.sliderWaterWettedAreaDefault = value;
              return value + '%';
            default:
              return value + '%';
          }
        },
      },
      value: 100,
    };

    this.sliderSystemEfficiencyCoefficient = {
      options: {
        ceil: 2,
        disabled: !(this.apf.hasSiteSettingsCropCoefficientFull && this.apf.hasGroupSettingsCropCoefficientFull),
        floor: 0,
        hideLimitLabels: false,
        precision: 2 /* decimal places ? */,
        showTicks: 0.25,
        step: 0.01,
        onChange: () => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Water.DataChanges);
        },
        translate: (value, sliderId, label) => {
          switch (label) {
            case 'model':
              this.groupSettingService.siteWater.SystemEfficiencyCoefficient = value;
              return `<b>${this._languageService.instant('COMMON.EFFICIENCY')}:</b> ${(100 * value).toFixed(0)}%`;
            default:
              return (100 * value).toFixed(0) + '%';
          }
        },
      },
      value: this.groupSettingService.siteWater.SystemEfficiencyCoefficient,
    };

    this.sliderEmitterLosses = {
      options: {
        ceil: 2,
        disabled: !(this.apf.hasSiteSettingsWaterEmitterLossesFull && this.apf.hasGroupSettingsWaterEmitterLossesFull),
        floor: 0,
        id: 'slider-EmitterLoss',
        precision: 2,
        step: 0.01,
        onChange: () => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Water.DataChanges);
        },
        translate: (value) => {
          if (value <= 0.1) return this._languageService.instant('AC.SETTINGS.LOSS.SUB_DRIP');
          if (value <= 0.33) return this._languageService.instant('AC.SETTINGS.LOSS.SURFACE_DRIP');
          if (value <= 0.65) return this._languageService.instant('AC.SETTINGS.LOSS.POPUP_LG');
          if (value <= 0.95) return this._languageService.instant('AC.SETTINGS.LOSS.POPUP_SM');
          if (value <= 1.25) return this._languageService.instant('AC.SETTINGS.LOSS.PIVOT_LG');
          if (value <= 1.75) return this._languageService.instant('AC.SETTINGS.LOSS.PIVOT_SM');
          return this._languageService.instant('AC.SETTINGS.LOSS.MIST');
        },
      },
      value: this.groupSettingService.siteWater.SprinklerLossConstantA,
    };
  }

  public DisableIrrigatedArea(): boolean {
    const disabled =
      (!this.groupSettingService.isGroupSetting && !this.apf.hasSiteSettingsWaterIrrigatedAreaFull) ||
      (this.groupSettingService.isGroupSetting && !this.apf.hasGroupSettingsWaterIrrigatedAreaFull);
    return disabled;
  }

  private initSiteDrainageCoefficientSlider() {
    const initValue = this.groupSettingService.siteSoil.DrainageCoefficient ? this.groupSettingService.siteSoil.DrainageCoefficient * 100 : 0.5;

    this.sliderDrainage = {
      value: initValue,
      options: {
        ceil: 100,
        disabled: !(this.apf.hasSiteSettingsWaterFull && this.apf.hasSiteSettingsWaterFull),
        floor: 1,
        id: 'slider-DRG',
        precision: 1,
        showSelectionBar: true,
        step: 1,
        onChange: (id, value) => {
          this.groupSettingService.siteSoil.DrainageCoefficient = value / 100;
          this._notifyingService.notify(NotifyEvents.Group.Settings.Soil.DataChanges);
        },
        translate: (value) => {
          if (value <= 10) return this._languageService.instant('AC.SETTINGS.DRAINAGE.SLOW');
          if (value <= 30) return this._languageService.instant('AC.SETTINGS.DRAINAGE.COMPACT_CLAY');
          if (value <= 33) return this._languageService.instant('AC.SETTINGS.DRAINAGE.HEAVY_CLAY');
          if (value <= 35) return this._languageService.instant('AC.SETTINGS.DRAINAGE.CLAY');
          if (value <= 40) return this._languageService.instant('AC.SETTINGS.DRAINAGE.LOAMY_CLAY');
          if (value <= 50) return this._languageService.instant('AC.SETTINGS.DRAINAGE.LOAM');
          if (value <= 60) return this._languageService.instant('AC.SETTINGS.DRAINAGE.SANDY_LOAM');
          if (value <= 70) return this._languageService.instant('AC.SETTINGS.DRAINAGE.SAND');
          if (value <= 80) return this._languageService.instant('AC.SETTINGS.DRAINAGE.GRAVEL_SAND');
          if (value <= 95) return this._languageService.instant('AC.SETTINGS.DRAINAGE.GRAVEL');
          return this._languageService.instant('AC.SETTINGS.DRAINAGE.RAPID');
        },
      },
    };
  }

  private initPenetratingRainFallRange() {
    this.maxPenRainValue_min = this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm
      ? this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm + 1
      : 1;
    this.minPenRainValue_max = this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm
      ? this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm - 1
      : null;
    this.scope.$watch('vm.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm', () => {
      this.maxPenRainValue_min = this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm + 1
        : 1;
      this.minPenRainValue_max = this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm - 1
        : null;
    });
    this.scope.$watch('vm.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm', () => {
      this.maxPenRainValue_min = this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MinPenetratingRainfall_24Hours_mm + 1
        : 1;
      this.minPenRainValue_max = this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm
        ? this.groupSettingService.siteSoil.MaxPenetratingRainfall_24Hours_mm - 1
        : null;
    });
  }

  private initSiteWorkingDepthSlider() {
    // SOIL MOISTURE working depth slider
    const isDisabled = !(this.apf.hasSiteSettingsSoilFull && this.apf.hasSiteSettingsSoilFull);

    this.sliderWorkingDepth = this.groupSettingService.initWorkingDepthSlider(this.soilSettings, isDisabled);
  }

  public checkSoilDepth() {
    this.groupSettingService.updateWorkingDepths();
    this.groupSettingService.updateMoistureCategories();
  }

  public onChangeIrrigationDay(selectedWaterDay: IWaterDays): void {
    if (this.imuWaterDays.every((s) => !s.isSelected)) {
      this._languageService.warning('AC.SETTINGS.MUST_SELECT_DAY');
    }

    if (this.groupSettingService.siteWater) {
      switch (selectedWaterDay.day) {
        case DateUtils.Locale.Weekdays.Long.Monday: {
          this.groupSettingService.siteWater.WaterMonday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Tuesday: {
          this.groupSettingService.siteWater.WaterTuesday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Wednesday: {
          this.groupSettingService.siteWater.WaterWednesday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Thursday: {
          this.groupSettingService.siteWater.WaterThursday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Friday: {
          this.groupSettingService.siteWater.WaterFriday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Saturday: {
          this.groupSettingService.siteWater.WaterSaturday = selectedWaterDay.isSelected;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Sunday: {
          this.groupSettingService.siteWater.WaterSunday = selectedWaterDay.isSelected;
          break;
        }
      }
    }
  }

  private initWaterDays() {
    this.imuWaterDays.forEach((x) => {
      switch (x.day) {
        case DateUtils.Locale.Weekdays.Long.Monday: {
          x.isSelected = this.groupSettingService.siteWater.WaterMonday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Tuesday: {
          x.isSelected = this.groupSettingService.siteWater.WaterTuesday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Wednesday: {
          x.isSelected = this.groupSettingService.siteWater.WaterWednesday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Thursday: {
          x.isSelected = this.groupSettingService.siteWater.WaterThursday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Friday: {
          x.isSelected = this.groupSettingService.siteWater.WaterFriday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Saturday: {
          x.isSelected = this.groupSettingService.siteWater.WaterSaturday;
          break;
        }

        case DateUtils.Locale.Weekdays.Long.Sunday: {
          x.isSelected = this.groupSettingService.siteWater.WaterSunday;
          break;
        }
      }
    });
  }

  private initWaterInterval() {
    if (this.groupSettingService.siteWater.WaterIntervalFromDayNumber != null) {
      this.imuWaterIntervalStart = this._dayNumberService.convertDayNumberToDate(
        this.groupSettingService.siteWater.WaterIntervalFromDayNumber,
      );
    } else {
      this.imuWaterIntervalStart = null;
    }
    this.imuWaterIntervalMaxDate = moment().toDate();
  }

  // end region

  private initSystemEfficiencyCoefficientSlider() {
    if (!this.groupSettingService.siteWater.SystemEfficiencyCoefficient) {
      this.groupSettingService.siteWater.SystemEfficiencyCoefficient = 1.0; // 0.8;
    }
    if (!this.groupSettingService.siteWater.IrrigationApplicationOneHour_mm) {
      this.groupSettingService.siteWater.IrrigationApplicationOneHour_mm = 50;
    }
  }

  public intervalTypeChanged(): void {
    this._notifyingService.notify('SettingController.siteWater.IrrigationDays', this.groupSettingService.siteWater.IrrigationDays);
  }

  public imuWaterIntervalDateChanged(): void {
    if (this.imuWaterIntervalStart != null) {
      this.groupSettingService.siteWater.WaterIntervalFromDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(
        this.imuWaterIntervalStart,
      );
    } else {
      this.groupSettingService.siteWater.WaterIntervalFromDayNumber = null;
    }
  }

  public updateSiteCropCoeffSettings(name: string, value: number, slopeOption: string): void {
    if (!value) {
      return;
    }
    
    this.groupSettingService.siteCrop.PhaseName = name;
    this.groupSettingService.siteCrop.PhaseCropCoefficient = value;
    this.groupSettingService.siteCrop.SlopeOption = slopeOption;

    this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.DataChanges);
  }

  private initSiteCropStressSliders() {
    // 0=Poor, 1=Average, 2=Good
    this.sliderWSC = {
      options: {
        ceil: 2,
        disabled: !(this.apf.hasSiteSettingsCropFull && this.apf.hasGroupSettingsCropFull),
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderWSC',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.DataChanges);
        },
      },
      value: this.groupSettingService.siteCrop.TolWetSoil,
    };

    this.sliderDSC = {
      options: {
        ceil: 2,
        disabled: !(this.apf.hasSiteSettingsCropFull && this.apf.hasGroupSettingsCropFull),
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderDSC',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.DataChanges);
        },
      },
      value: this.groupSettingService.siteCrop.TolDrySoil,
    };

    this.sliderLAT = {
      options: {
        ceil: 2,
        disabled: !(this.apf.hasSiteSettingsCropFull && this.apf.hasGroupSettingsCropFull),
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderLAT',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.DataChanges);
        },
      },
      value: this.groupSettingService.siteCrop.TolLowTemp,
    };

    this.sliderHAT = {
      options: {
        ceil: 2,
        disabled: !(this.apf.hasSiteSettingsCropFull && this.apf.hasGroupSettingsCropFull),
        floor: 0,
        hideLimitLabels: true,
        id: 'sliderHAT',
        showTicksValues: true,
        translate: this.groupSettingService.translateTolerance,
        getPointerColor: (value: number) => {
          if (value <= 0) return 'red';
          if (value > 1) return '#2AE02A';
          return '#0DB9F0';
        },
        onChange: (sliderId, modelValue, highValue, pointerType) => {
          this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.DataChanges);
        },
      },
      value: this.groupSettingService.siteCrop.TolHighTemp,
    };
  }

  private getCropCoefficients(cropId: number) {
    const qryCropCoeffs = new breeze.EntityQuery('CropCoefficient').withParameters({ CropId: cropId });

    return this.entityManager.executeQuery(qryCropCoeffs).then(
      (data) => {
        this.cropCoeffs = data.results as CropCoefficient[];
        this.cropCoeffs = ArrayUtils.sortByNumber(this.cropCoeffs, (x) => x.GrowthPhase);
      },
      (error) => {
        console.error('Error: Could not load CropCoefficients entity (' + error.message + ')');
      },
    );
  }

  private _fetchData() {
    const qryCrops = new breeze.EntityQuery('Crop').withParameters({ accountId: this.accountId }).expand('CropCoefficients');

    this.entityManager.executeQuery(qryCrops).then(
      (data) => {
        this.crops = data.results as Crop[];
      },
      (error) => {
        console.error('Error: Could not load Crops entity (' + error.message + ')');
      },
    );
  }

  private initialiseCropCoefficients() {
    this.getCropCoefficients(this.groupSettingService.siteCrop.CropId).then(() => {
      const tempArray: ISiteCropCoeff[] = [];
      const crop = this.crops.find((c) => c.Id == this.groupSettingService.siteCrop.CropId);
      const budgetStart = crop != null && crop.BudgetStartDate != null ? moment(crop.BudgetStartDate).toDate() : null;

      const phaseStart: Date =
        crop != null && crop.BudgetStartDate != null
          ? new Date(budgetStart.getUTCFullYear(), budgetStart.getUTCMonth(), budgetStart.getUTCDate())
          : null;
      for (let idx = 0; idx < this.cropCoeffs.length; idx++) {
        let phaseStr: string = null;
        if (phaseStart != null && this.cropCoeffs[idx].GrowthPhaseDuration > 0) {
          const phaseDates: { from: Date; to: Date } = {
            from: moment(phaseStart).toDate(),
            to: moment(phaseStart)
              .add(this.cropCoeffs[idx].GrowthPhaseDuration - 1, 'days')
              .toDate(),
          };
          phaseStr = moment(phaseDates.from).isSame(phaseDates.to)
            ? moment(phaseDates.from).format('D MMM')
            : moment(phaseDates.from).format('D MMM') + ' - ' + moment(phaseDates.to).format('D MMM');
          phaseStart.addDays(this.cropCoeffs[idx].GrowthPhaseDuration);
        }
        const sccRec: ISiteCropCoeff = {
          id: this.cropCoeffs[idx].GrowthPhase,
          name: this.cropCoeffs[idx].Description,
          value: this.cropCoeffs[idx].WaterCoefficient,
          duration: this.cropCoeffs[idx].GrowthPhaseDuration,
          slopeOption: this.cropCoeffs[idx].SlopeOption == null ? 'F' : this.cropCoeffs[idx].SlopeOption,
          phaseDates: phaseStr,
        };
        tempArray.push(sccRec);
      }
      this.siteCropCoeffsCurrent = ArrayUtils.sortByNumber(tempArray, (x) => x.id);
    });
  }

  public siteCropChanged(setDefault: boolean) {
    this.groupSettingService.siteCrop.CropGrowthPhase = 0;
    // get the default crop coeffs
    this.initialiseCropCoefficients();
    if (setDefault) {
      // get the default stress factors
      const cropDetail = this.crops.find((a) => a.Id == this.groupSettingService.siteCrop.CropId);
      this.groupSettingService.siteCrop.TolWetSoil = cropDetail.TolWet;
      this.groupSettingService.siteCrop.TolDrySoil = cropDetail.TolDry;
      this.groupSettingService.siteCrop.TolLowTemp = cropDetail.TolCold;
      this.groupSettingService.siteCrop.TolHighTemp = cropDetail.TolHeat;

      this.groupSettingService.siteCrop.PhaseName =
        cropDetail.CropCoefficients.length ? cropDetail.CropCoefficients[0].Description.toString() : '';
      this.groupSettingService.siteCrop.SlopeOption =
        cropDetail.CropCoefficients.length ? cropDetail.CropCoefficients[0].SlopeOption : 'F';
      this.groupSettingService.siteCrop.PhaseCropCoefficient =
        cropDetail.CropCoefficients.length ? cropDetail.CropCoefficients[0].WaterCoefficient : null;
    }
  }
}

angular.module('app.account').controller('GroupSettingsController', GroupSettingsController);
