import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { Sensor } from 'src/app/_DBContext/Sensor';
import { BaseController } from 'src/app/base.controller';

export class AddInfraWaterFlowDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;
  private _dayNumberService: DayNumberService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public filterDates: (date: Date) => boolean;
  public fromDate: Date;
  public maxDate: Date;
  public initialReading: number;
  public date: Date;
  public reading: number;
  public volume: number;
  public isLocked = false;
  public volumeNormalUnit: uomUnit;
  public addForm: angular.IFormController;
  public isInVolume = false;

  private _assetId: number;
  private _equipment: Sensor;
  private _toDate: Date;
  private _readings: fuse.obsInfraWaterFlowDto[];
  private _meterUnit: uomUnit;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    assetId: number,
    equipment: Sensor,
    fromDate: Date,
    toDate: Date,
    readings: fuse.obsInfraWaterFlowDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._dayNumberService = DayNumberService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this._assetId = assetId;
    this._equipment = equipment;
    this._readings = readings;
    this.fromDate = fromDate;
    this._toDate = toDate;
    this.initialReading = equipment.MeterInitialReading;

    this.filterDates = (date: Date): boolean => {
      return this._readings.every((a) => a.date.getTime() != date.getTime());
    };

    this.volumeNormalUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.normal);
    this._meterUnit = this._unitOfMeasureService.getUnitById(this._equipment.UnitId);

    this.date = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    if (this.date.getTime() < this._toDate.getTime()) {
      this.maxDate = this.date.clone();
    } else {
      this.maxDate = this._toDate.clone();
    }
  }

  public changeDate() {
    this.changeOption();
  }

  public changeOption(): void {
    if (this.isInVolume) {
      this.addForm['reading'].$error = {};
      if (this.volume == null || isNaN(this.volume)) {
        this.reading = null;
      } else {
        this.changeVolume();
      }
    } else {
      this.addForm['volume'].$error = {};
      if (this.reading == null || isNaN(this.reading)) {
        this.volume = null;
      } else {
        this.changeReading();
      }
    }
  }

  public changeReading() {
    const reading = this.addForm['reading'].$modelValue;
    if (reading != null) {
      const nextReading = this._readings.find((a) => a.date.getTime() > this.date.getTime())?.reading;
      if (nextReading != null) {
        if (reading > nextReading) {
          this.addForm['reading'].$setValidity('nextvalid', false);
        } else {
          this.addForm['reading'].$setValidity('nextvalid', true);
        }
      } else {
        this.addForm['reading'].$setValidity('nextvalid', true);
      }
      let lastReading = this._readings.filter((a) => a.date.getTime() < this.date.getTime()).pop()?.reading;
      if (lastReading != null) {
        if (reading < lastReading) {
          this.addForm['reading'].$setValidity('lastvalid', false);
        } else {
          this.addForm['reading'].$setValidity('lastvalid', true);
        }
      } else {
        lastReading = this._equipment.MeterInitialReading;
        this.addForm['reading'].$setValidity('lastvalid', true);
      }
      const volume = this._meterUnit.toBase((reading - lastReading) * this._equipment.MeterIncrement);
      // need set modelvalue for volume in 3 decimal
      this.volume = Math.round(volume * 1000) / 1000;
    }
    this.addForm['reading'].$setTouched();
  }

  public changeVolume() {
    const volume = this.addForm['volume'].$modelValue;
    if (volume != null) {
      const meterVolume = this._meterUnit.fromBase(volume);
      const meterIncreasedReading = meterVolume / this._equipment.MeterIncrement;
      let lastReading = this._readings.filter((a) => a.date.getTime() < this.date.getTime()).pop()?.reading;
      if (lastReading == null) {
        lastReading = this._equipment.MeterInitialReading;
      }
      const reading = lastReading + meterIncreasedReading;
      this.reading = Math.round(reading * 1000) / 1000;
      const nextReading = this._readings.find((a) => a.date.getTime() > this.date.getTime())?.reading;
      if (nextReading != null) {
        if (this.reading > nextReading) {
          this.addForm['volume'].$setValidity('nextvalid', false);
        } else {
          this.addForm['volume'].$setValidity('nextvalid', true);
        }
      } else {
        this.addForm['volume'].$setValidity('nextvalid', true);
      }
    }
    this.addForm['volume'].$setTouched();
  }

  public closeDialog(updated: boolean) {
    this._mdDialog.hide(updated);
  }

  public submit() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.date);
    const value = {
      reading: this.reading,
      isLocked: this.isLocked,
      volumeKL: this.volume,
    } as fuse.obsInfraWaterFlowDto;
    this._http
      .put(CommonHelper.getApiUrl(`equipment/addobsInfraWaterFlow?assetid=${this._assetId}&dayNumber=${dayNumber}`), value)
      .then(
        (res) => {
          const result = res.data as boolean;
          if (result) {
            this._mdDialog.hide(true);
            this._languageService.success('COMMON.CHANGES_SAVED');
          } else {
            this._languageService.whoops();
          }
        },
        () => {
          this._languageService.whoops();
        },
      );
  }
}

angular.module('app.account').controller('AddInfraWaterFlowDialogController', AddInfraWaterFlowDialogController);
