import * as angular from 'angular';
import * as moment from 'moment';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ConversionUtils } from '@indicina/swan-shared/utils/ConversionUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { StringUtils } from '@indicina/swan-shared/utils/StringUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes, unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AccountSiteInfo } from '@common/models/ProviderPackets';
import { IDashboardParams } from '@common/models/interfaces';
import { DataEntityService } from '@services/data-entity.service';
import { DupeHandlerService } from '@services/dupe-handler.service';
import { EntityListService } from '@services/entity-list.service';
import { GroupSiteService } from '@services/group-site.service';
import { InitialisationService } from '@services/initialisation.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { GroupSettingService } from '@services/account/group-setting.service';
import { ReportService } from '@services/account/report.service';
import { FetchDataService } from '@services/fetch-data.service';
import { GroupKcRolloverDialogController } from './settingsTabs/crop/group-kcRollover-dialog.controller';
import { LocalStorageService } from '@services/local-storage.service';
import { DayNumberService } from '@services/day-number.service';
import { Asset } from 'src/app/_DBContext/Asset';
import { Site } from 'src/app/_DBContext/Site';
import { SiteSettingsCrop } from 'src/app/_DBContext/SiteSettingsCrop';
import { SiteSettingsSoil } from 'src/app/_DBContext/SiteSettingsSoil';
import { SiteSettingsWater } from 'src/app/_DBContext/SiteSettingsWater';
import { BaseController, PostSaveActions } from 'src/app/base.controller';

class updateOpt {
  decimal: number;
  field: string;
  irrigOpt: boolean;
  model: string; // display value
  title: string; // display label
  unit: uomUnit;
  units: string;
  update: boolean; // checkbox
  visible: boolean;
  updateChanged: Function;
  shouldUseTranslation: boolean;

  constructor(
    name: string,
    target: string,
    visible: boolean = true,
    uomUnit: uomUnit = null,
    unitDecimal: number = null,
    updateChanged: Function = null,
  ) {
    this.decimal = unitDecimal;
    this.field = target;
    this.model = target;
    this.title = name;
    this.unit = uomUnit;
    this.units = '';
    this.visible = visible;
    this.updateChanged = updateChanged;
    this.shouldUseTranslation = true;

    if (uomUnit && uomUnit?.name != '') {
      this.units = ` (${uomUnit.name})`;
    }
  }
}

class SiteDailyTable {
  id: number;
  name: string;
  area: number;
  date: string;
  applied_mm: number;
  planned_mm: number;
  planned_volume: number;
  run_time: string;
  planned_runtime: string;
  sm_status: string;
  sm_per: number;
  crop: string[];
  crop_coefficient: number;
  drainage: number;
  runoff: number;
  budget: number;
  actual: number;
  budget_running: number;
  actual_running: number;
  projected_running: number;
  low: number;
  ok: number;
  high: number;
}

enum GroupPrimaryTabEnums {
  Summary,
  WaterReports,
  NutrientApplication,
  NutrientReports,
  Settings,
}

enum GroupSettingsTabEnums {
  Soil,
  Crop,
  Water,
}

export class GroupController extends BaseController {
  private _document: angular.IDocumentService;
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _state: angular.ui.IStateService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _dupeHandlerService: DupeHandlerService;
  private _entityListService: EntityListService;
  private _fetchDataService: FetchDataService;
  private _groupSettingService: GroupSettingService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _notifyingService: NotifyingService;
  private _reportService: ReportService;
  private _unitOfMeasureService: UnitOfMeasureService;
  private dashboardParams: IDashboardParams;

  public canRevertChanges: boolean;
  public hasDataChanges: boolean;
  public convertToHHMM = ConversionUtils.convertToHHMM;
  public groupSiteService: GroupSiteService;
  public waterReportFromDate: Date;
  public waterReportToDate: Date;
  public currentDate = new Date().addDays(-1);
  public minDate = SWANConstants.MinDate;
  public irrigationEndDate: Date;
  public weekDate: Date;
  public waterReportFromMaxDate: Date;
  public waterReportToMaxDate: Date;

  private obsChart: AmCharts.AmSerialChart;
  private soilChart: AmCharts.AmSerialChart;
  private applicationChart: AmCharts.AmStockChart;
  private applicationSoilPanel: AmCharts.StockPanel;
  private applicationWaterPanel: AmCharts.StockPanel;

  public emitterImage: string;

  public imuSites: fuse.listEntityDto [] = []; // Sites of the account.
  public groupSites: fuse.listEntityDto [] = [] // Sites of the group.
  public selectedIMUSiteIds: number[] = [];
  private _tmpSelectedIMUSiteIds: number[] = [];

  public searchTerm: string = '';
  public searchGroup: string = '';

  public primaryTab: number;
  public settingsTab: number;

  private cropTop: updateOpt[] = [];
  private soilTop: updateOpt[] = [];
  private waterTop: updateOpt[] = [];

  public siteNutrientReadings: fuse.accountGroupSubInfo[];
  public cmus2: Asset[] = [];

  public resetSlider: (newValues: number[]) => void;

  //        private arrALLData: any[];
  public runTimeData: fuse.siteDailySummaryPacket[] = [];
  public waterApplicationEfficiencyData: fuse.waterApplicationEfficiencyResultDto[] = [];
  public filteredApplicationEfficiencyData: fuse.waterApplicationEfficiencyResultDto[] = [];
  public waterProductivityEfficiencyData: fuse.waterProductivityEfficiencyResultDto[] = [];
  public waterProductivityPricesMissing: fuse.waterProductivityEfficiencyMissingDto[] = [];

  private waterChartData: any[];
  private waterChartSummaryData: any;
  private waterChartSummaryHdr: string[];
  private waterChartSummaryForCsv: any[];
  private waterTableSummaryTotals: any;

  public isReportBtnEnabled: boolean = true;
  public isWaterCsvEnabled: boolean;

  public showCrop: boolean;
  public showCropCoefficient: boolean;
  public showApplied: boolean;
  public showPlanned: boolean;
  public showRunTime: boolean;
  public showVolume: boolean;
  public showSMstatus: boolean;
  public showSMperc: boolean;
  public showDrainage: boolean;
  public showRunoff: boolean;
  public showWBudget: boolean;
  public showWActual: boolean;
  public showWBudgetRunning: boolean;
  public showWActualRunning: boolean;

  public irrigationScheduleCsv = [] as any[];
  public irrigationScheduleCsvHdr = [] as string[];
  public irrigationScheduleData = [];
  public soilMoistureData: any;
  public soilMoistureDataCSVhdr = [] as string[];
  public updateSlider: (pcts: any) => void;

  public assStateCur = '';
  private waterEfficiencyFormat = 'Group Total';
  private waterEfficiencyPeriod = 'By Month';
  private efficiencyChartPeriod = 'Monthly';

  public waterEfficiencyFormatList = {
    GroupTotal: 'Group Total',
    SiteTotal: 'Site Total',
  };

  public waterEfficiencyPeriodList = {
    Monthly: 'By Month',
    Weekly: 'By Week',
    Whole: 'By Whole Interval',
  };

  public AssetStatesList = {
    DataExtract: 'Data Extract',
    IrrigationSchedule: 'Irrigation Planned and Applied',
    SoilMoisture: 'Soil Moisture Status',
    WaterUsage: 'Water Usage',
    WaterApplicationEfficency: 'Water Application Efficiency',
    WaterProductivityEfficiency: 'Water Productivity',
  };

  public waterYieldGraphChoiceList = {
    Both: 'Yield and Value (default)',
    Weight: 'Yield',
    Price: 'Value',
  };

  public waterYieldGraphChoice = this.waterYieldGraphChoiceList.Both;

  public waterProductivityStartDate = new Date();
  public waterProductivityEndDate = new Date();
  public waterProductivitySeason: number;
  public currency = '$';
  public volumeUnit: fuse.uomPreferenceDto;
  public cropWeightUnit: fuse.uomPreferenceDto;
  public _volumeUnitOptions: fuse.uomPreferenceDto[] = [];
  public _cropYieldOptions: fuse.uomPreferenceDto[] = [];
  public validYears = [] as number[];

  public AssetStates = [] as string[];

  public effectiveFrom: any;
  public minEffectiveDate: Date = null;
  public group: Asset;
  public isExistingGroupName = false;

  public groupId: number;
  public crops = [];
  public sites = [];

  public groupArea: number = 0;
  public volUnit: uomUnit;
  public soilDepthUnit: uomUnit;
  public fluidDepthUnit: uomUnit;
  public areaUnit: uomUnit;

  public largeVolAreaUnit: uomUnit;
  public largeVolUnit: uomUnit;
  public hugeVolAreaUnit: uomUnit;
  public hugeVolUnit: uomUnit;
  private waterRunningUsageUnit: uomUnit;
  private waterUsageUnit: uomUnit;

  public weightAreaUnit: uomUnit;
  public generateButtonLabel: string;

  public summaryForm: angular.IFormController;
  public sitesInGroupForm: angular.IFormController;
  public settingsTopPanelForm: angular.IFormController;
  public settingsSoilForm: angular.IFormController;
  public settingsCropForm: angular.IFormController;
  public settingsWaterForm: angular.IFormController;

  public reloadCounter = 0;
  public saveCounter = 0;

  private groupSite: Site; // CMU structure
  private dateSuffix: string = '';
  private groupAccountInfo: fuse.groupAccountInfoDto;
  private isSuperUser: boolean;
  private irrigationSeasonStart: Date;

  constructor(
    $document: angular.IDocumentService,
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DupeHandlerService: DupeHandlerService,
    EntityListService: EntityListService,
    FetchDataService: FetchDataService,
    GroupSettingService: GroupSettingService,
    GroupSiteService: GroupSiteService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    ReportService: ReportService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.GroupFull);

    this._document = $document;
    this._http = $http;
    this._mdDialog = $mdDialog;
    this._q = $q;
    this._state = $state;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._dupeHandlerService = DupeHandlerService;
    this._entityListService = EntityListService;
    this._fetchDataService = FetchDataService;
    this._groupSettingService = GroupSettingService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._notifyingService = NotifyingService;
    this._reportService = ReportService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this.groupSiteService = GroupSiteService;

    this.isSiteInThisGroup = this.isSiteInThisGroup.bind(this);

    this.generateButtonLabel = 'COMMON.GENERATE_REPORT';
    this._dupeHandlerService.setDuplicateMessage('AC.GROUP.GROUP_ALREADY_EXISTS');

    this.AssetStates.push(this.AssetStatesList.DataExtract);
    this.AssetStates.push(this.AssetStatesList.IrrigationSchedule);
    this.AssetStates.push(this.AssetStatesList.SoilMoisture);
    this.AssetStates.push(this.AssetStatesList.WaterUsage);
    this.AssetStates.push(this.AssetStatesList.WaterApplicationEfficency);

    this.volUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume);
    this.fluidDepthUnit = this._unitOfMeasureService.getUnits(UnitTypes.FluidDepth);
    this.soilDepthUnit = this._unitOfMeasureService.getUnits(UnitTypes.SoilDepth);
    this.areaUnit = this._unitOfMeasureService.getUnits(UnitTypes.Area);

    this.largeVolAreaUnit = this._unitOfMeasureService.getUnits(UnitTypes.VolumeArea, unitSizes.large);
    this.largeVolUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.large);
    this.hugeVolAreaUnit = this._unitOfMeasureService.getUnits(UnitTypes.VolumeArea, unitSizes.huge);
    this.hugeVolUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.huge);

    this.weightAreaUnit = this._unitOfMeasureService.getUnits(UnitTypes.WeightArea);

    if (this.apf.hasImpactModuleReportsView) {
      this.AssetStates.push(this.AssetStatesList.WaterProductivityEfficiency);
    }

    this.updateSlider = (pcts) => {
      this._groupSettingService.updateValues(pcts);
      this.scope.$digest();
      this._notifyingService.notify(NotifyEvents.Group.Settings.Soil.DataChanges);
    };

    this.isSuperUser = PermissionService.isSuperUser;
    this.groupId = Number(this._state.params.id);
    this.scope['groupForm'] = {};

    const context = LocalStorageUtils.contextData;

    if (context.tab1 >= 0) {
      this.primaryTab = context.tab1;

      if (context.tab2 >= 0) {
        this.settingsTab = context.tab2;
        context.tab2 = undefined;
      }

      context.tab1 = undefined;

      LocalStorageUtils.setContextData(context);
    }

    this.currency = this.account.currencyCode;

    this._notifyingService.subscribe(
      [
        NotifyEvents.Group.NutrientApplication,
        NotifyEvents.Group.Settings.Soil.DataChanges,
        NotifyEvents.Group.Settings.Crop.DataChanges,
        NotifyEvents.Group.Settings.Water.DataChanges,
      ],
      this.scope,
      (event, data) => {
        this.hasDataChanges = true;
      }
    );

    this._notifyingService.subscribe('SettingController.siteWater.IrrigationDays', this.scope, (_event: angular.IAngularEvent, data) => {
      this.irrigationDayChanged(data as string);
    });

    this._notifyingService.subscribe(NotifyEvents.App.SaveChanges.Group, this.scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });

    this.emitterImage = `assets/images/sites/emitterloss_${InitialisationService.selectedLanguageCode}.png`;
    this.validYears = this.getValidYears();

    this.getUnitOptions();
  }

  $onInit() {
    this.scope.$watchGroup(
      [
        'vm.summaryForm.$dirty',
        'vm.settingsSoilForm.$dirty',
        'vm.settingsCropForm.$dirty',
        'vm.settingsWaterForm.$dirty',
      ],
      (newValue) => {
        const [isDirtySummaryForm, isDirtySettingsSoilForm, isDirtySettingsCropForm, isDirtySettingsWaterForm] = newValue;

        this.hasDataChanges = isDirtySummaryForm || isDirtySettingsSoilForm || isDirtySettingsCropForm || isDirtySettingsWaterForm;
      }
    );

    this.dashboardParams = this._localStorageService.get('dashboardParams');

    this.showCrop = false;
    this.showCropCoefficient = false;
    this.showApplied = false;
    this.showPlanned = false;
    this.showRunTime = false;
    this.showVolume = false;
    this.showSMstatus = false;
    this.showSMperc = false;
    this.showDrainage = false;
    this.showRunoff = false;
    this.showWBudget = false;
    this.showWActual = false;
    this.showWBudgetRunning = false;
    this.showWActualRunning = false;

    const dummyUnit = this._unitOfMeasureService.createDummyUnit(0);
    const dummyUnit2dp = this._unitOfMeasureService.createDummyUnit(2);
    const percUnit = new uomUnit({
      symbol: '',
      fromBaseFormulaJS: 'return 100 * a;',
      toBaseFormulaJS: 'return 0.01 * a;',
      decimalPlaces: 0,
    } as any as fuse.uomPreferenceDto);

    // Crop Panel
    this.cropTop.push(new updateOpt('Crop', 'CropId'));
    this.cropTop.push(new updateOpt('Phase Name', 'PhaseName'));
    this.cropTop.push(new updateOpt('Coefficient', 'PhaseCropCoefficient'));
    this.cropTop.push(new updateOpt('Slope', 'SlopeOption'));
    this.cropTop.push(new updateOpt('Wet Soil', 'TolWetSoil'));
    this.cropTop.push(new updateOpt('Dry Soil', 'TolDrySoil'));
    this.cropTop.push(new updateOpt('Low Temp', 'TolLowTemp'));
    this.cropTop.push(new updateOpt('High Temp', 'TolHighTemp'));

    // Soil Panel
    this.soilTop.push(new updateOpt('PWP', 'WiltingPoint_pct', true, dummyUnit));
    this.soilTop.push(new updateOpt('FC', 'FieldCapacity_pct', true, dummyUnit));
    this.soilTop.push(new updateOpt('SP', 'Saturation_pct', true, dummyUnit));
    this.soilTop.push(new updateOpt('Lower Target', 'SoilMoistureLowerTarget_percent', true, dummyUnit));
    this.soilTop.push(new updateOpt('Upper Target', 'SoilMoistureUpperTarget_percent', true, dummyUnit));
    this.soilTop.push(new updateOpt('Drainage', 'DrainageCoefficient', true, dummyUnit, 2));
    this.soilTop.push(new updateOpt('Min Rain', 'MinPenetratingRainfall_24Hours_mm', true, this.fluidDepthUnit));
    this.soilTop.push(new updateOpt('Max Rain', 'MaxPenetratingRainfall_24Hours_mm', true, this.fluidDepthUnit));
    this.soilTop.push(new updateOpt('Min Depth', 'WorkingSoilDepthTop_mm', true, this.soilDepthUnit));
    this.soilTop.push(new updateOpt('Working Depth', 'WorkingSoilDepthBottom_mm', true, this.soilDepthUnit));

    // Water Panel
    const shortDayNames = DateUtils.Locale.getWeekdayNamesShort();
    const longDayEnglishNames = DateUtils.Locale.getWeekdayNamesLong(undefined, 'en');

    this.waterTop.push(new updateOpt('Wetted Area', 'CropWettedArea_perc', true, dummyUnit));
    this.waterTop.push(new updateOpt('Lower Target', 'WaterBudgetLowerTarget_percent', true, dummyUnit));
    this.waterTop.push(new updateOpt('Upper Target', 'WaterBudgetUpperTarget_percent', true, dummyUnit));
    this.waterTop.push(new updateOpt('Irrigation Type', 'IrrigationDays', true, dummyUnit, null, this.irrigationDayUpdateChanged.bind(this)));

    shortDayNames.forEach((shortDayName, idx) => {
      const irrigationDay = new updateOpt(shortDayName, `Water${longDayEnglishNames[idx]}`, false);

      irrigationDay.irrigOpt = true;
      irrigationDay.shouldUseTranslation = false;

      this.waterTop.push(irrigationDay);
    });

    this.waterTop.push(new updateOpt('Interval Days', 'WaterIntervalDays', false, dummyUnit));
    this.waterTop.push(new updateOpt('Interval Start', 'WaterIntervalFromDayNumber', false, dummyUnit));

    this.waterTop.push(new updateOpt('Emitter Losses', 'SprinklerLossConstantA', true, dummyUnit2dp));
    this.waterTop.push(new updateOpt('System Efficiency', 'SystemEfficiencyCoefficient', true, percUnit));
    this.waterTop.push(new updateOpt('Application Rate', 'IrrigationApplicationOneHour_mm', true, this.fluidDepthUnit, 4));

    this.searchTerm = '';
    this.searchGroup = '';
    this.effectiveFrom = null;

    const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    this.waterReportFromDate = adjustedToday.clone().addDays(-3);
    this.waterReportFromMaxDate = this.earliest(this.waterReportToDate, this.currentDate);
    this.waterReportToDate = adjustedToday.clone().addDays(6);

    this.selectedIMUSiteIds = [];

    this.getAccountDetail().then(() => {
      this.minEffectiveDate =
        this.isSuperUser
          ? this.minDate
          : moment(this.irrigationSeasonStart).subtract(1, 'year').toDate();

      this.fetchData();

      this.irrigationEndDate = new Date(this.irrigationSeasonStart.getTime());
      this.irrigationEndDate.addDays(-1).addYears(1);
      this.weekDate = new Date();
      this.weekDate.addDays(7);

      if (this.assStateCur != 'Water Usage') {
        this.waterReportToMaxDate = this.earliest(this.irrigationEndDate, this.weekDate);
      } else {
        this.waterReportToMaxDate = this.irrigationEndDate.clone();
      }

      this.waterProductivitySeason = this.irrigationSeasonStart.getFullYear();
    });
  }

  public isSiteInThisGroup(site: fuse.listEntityDto): boolean {
    return this.selectedIMUSiteIds?.includes(site.id) ?? false;
  }

  private irrigationDayChanged(irrigationDays: string) {
    const visible = irrigationDays == null || irrigationDays == 'D';

    this.waterTop.forEach((w) => {
      if (w.irrigOpt) {
        w.visible = visible;
      }
    });

    const irrigationDay = this.waterTop.find((w) => w.field == 'IrrigationDays');

    this.irrigationDayUpdateChanged(this, irrigationDay.update);
  }

  private irrigationDayUpdateChanged(obj: GroupController, state: boolean) {
    if (state && this._groupSettingService.siteWater.IrrigationDays == 'I') {
      obj.waterTop.find((w) => w.field == 'WaterIntervalDays').update = true;
      obj.waterTop.find((w) => w.field == 'WaterIntervalFromDayNumber').update = true;

      obj.waterTop.find((w) => w.field == 'WaterMonday').update = false;
      obj.waterTop.find((w) => w.field == 'WaterTuesday').update = false;
      obj.waterTop.find((w) => w.field == 'WaterWednesday').update = false;
      obj.waterTop.find((w) => w.field == 'WaterThursday').update = false;
      obj.waterTop.find((w) => w.field == 'WaterFriday').update = false;
      obj.waterTop.find((w) => w.field == 'WaterSaturday').update = false;
      obj.waterTop.find((w) => w.field == 'WaterSunday').update = false;
    } else {
      obj.waterTop.find((w) => w.field == 'WaterIntervalDays').update = false;
      obj.waterTop.find((w) => w.field == 'WaterIntervalFromDayNumber').update = false;
    }
  }

  public showReportPanel(report: string): boolean {
    let result = false;
    switch (report) {
      case this.AssetStatesList.DataExtract:
      case this.AssetStatesList.IrrigationSchedule: {
        result = !!this.runTimeData;
        break;
      }
      case this.AssetStatesList.WaterUsage: {
        result = !!this.waterTableSummaryTotals?.length;
        break;
      }
      case this.AssetStatesList.SoilMoisture: {
        result = !!this.soilMoistureData?.length;
        break;
      }
      case this.AssetStatesList.WaterApplicationEfficency: {
        result = !!this.filteredApplicationEfficiencyData?.length;
        break;
      }
      case this.AssetStatesList.WaterProductivityEfficiency: {
        result = !!this.waterProductivityEfficiencyData?.length;
        break;
      }
      default: {
        result = false;
        break;
      }
    }
    return result;
  }

  public onReportChange(): void {
    this.generateButtonLabel = this.assStateCur !== this.AssetStatesList.DataExtract
      ? 'COMMON.GENERATE_REPORT'
      : 'COMMON.GENERATE_CSV';

    const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    //allow the max of 'To' date to be 365 days into the future for the 'Water Usage' report
    if (this.assStateCur == this.AssetStatesList.WaterUsage) {
      this.waterReportToMaxDate = adjustedToday.clone().addDays(365);
      this.waterReportFromMaxDate = this.earliest(this.waterReportToDate, this.currentDate);
    } else if (this.assStateCur == this.AssetStatesList.WaterApplicationEfficency) {
      this.waterReportToMaxDate = adjustedToday.clone();
      const year = this.waterReportFromDate.clone().addYears(1).addDays(-1);
      if (year < this.waterReportToDate) {
        this.waterReportToDate = year;
      }
      if (year < this.waterReportToMaxDate) {
        this.waterReportToMaxDate = year;
      }
      this.waterReportFromMaxDate = this.waterReportToDate.clone();
    } else {
      //set the max date
      this.waterReportToMaxDate = adjustedToday.clone().addDays(6);

      //make sure we never have a 'From' date ahead of a 'To' date
      if (this.waterReportFromDate > this.waterReportToDate) {
        this.waterReportToDate = adjustedToday.clone().addDays(6);
        this.waterReportFromDate = adjustedToday;
      }

      //if we switch away from 'Water Usage' we reset the 'To' date
      if (this.waterReportToDate > adjustedToday.clone().addDays(6)) {
        this.waterReportToDate = adjustedToday.clone().addDays(6);
      }
      this.waterReportFromMaxDate = this.waterReportToDate.clone();
    }

    this.isReportBtnEnabled = true;
    // everytime user's change report type, all the data set must be cleared, user regenerate  from DB
    this.runTimeData = null;
    this.soilMoistureData = null;
    this.waterTableSummaryTotals = null;
    this.filteredApplicationEfficiencyData = [];
    this.waterProductivityEfficiencyData = [];
  }

  public onReportWaterProductivitySeasonChange() {
    this.waterProductivityEfficiencyData = [];
    this.isReportBtnEnabled = true;
  }

  public onReportWaterEfficiencyPeriodChange() {
    this.isReportBtnEnabled = true;
    this.filteredApplicationEfficiencyData = [];
    this.waterApplicationEfficiencyData = [];
  }

  public onReportWaterEfficiencyFormatChange() {
    if (
      this.waterEfficiencyFormat === this.waterEfficiencyFormatList.SiteTotal &&
      this.waterEfficiencyPeriod !== this.waterEfficiencyPeriodList.Whole
    ) {
      this.waterEfficiencyPeriod = this.waterEfficiencyPeriodList.Whole;
      this.onReportWaterEfficiencyPeriodChange();
    }

    if (this.waterApplicationEfficiencyData?.length) {
      this.filteredApplicationEfficiencyData = [];
      let applicationData = this.waterApplicationEfficiencyData.map((a) => Object.assign({}, a));
      applicationData = this.convertWaterApplicationUnits(applicationData);

      switch (this.waterEfficiencyFormat) {
        case this.waterEfficiencyFormatList.GroupTotal:
          // This is why we should have groupBy...
          const distinctDates = ArrayUtils.distinct(applicationData.map((item) => item.date)); // change to getmonth day etc...?

          distinctDates.forEach((datePoint) =>
            this.filteredApplicationEfficiencyData.push(
              applicationData
                .filter((w) => w.date === datePoint)
                .reduce((previousValue, currentValue) => {
                  return {
                    demandMl: previousValue.demandMl + currentValue.demandMl,
                    irrigationMl: previousValue.irrigationMl + currentValue.irrigationMl,
                    reservesAndUsefulRainfallMl:
                      previousValue.reservesAndUsefulRainfallMl + currentValue.reservesAndUsefulRainfallMl,
                    wetDay: previousValue.wetDay + currentValue.wetDay,
                    okDay: previousValue.okDay + currentValue.okDay,
                    dryDay: previousValue.dryDay + currentValue.dryDay,
                    siteArea: previousValue.siteArea + currentValue.siteArea,
                    date: datePoint,
                    siteName: this.waterEfficiencyFormatList.SiteTotal,
                    siteId: 0,
                  };
                }),
            ),
          );
          break;
        case this.waterEfficiencyFormatList.SiteTotal:
          const distinctSiteIds = ArrayUtils.distinct(applicationData.map((item) => item.siteId));

          for (let index = 0; index < distinctSiteIds.length; index++) {
            const filtered = applicationData.filter((w) => w.siteId === distinctSiteIds[index]);
            const amount = filtered.length;
            const final = filtered.reduce((previousValue, currentValue) => {
              return {
                demandMl: previousValue.demandMl + currentValue.demandMl,
                irrigationMl: previousValue.irrigationMl + currentValue.irrigationMl,
                reservesAndUsefulRainfallMl: previousValue.reservesAndUsefulRainfallMl + currentValue.reservesAndUsefulRainfallMl,
                wetDay: previousValue.wetDay + currentValue.wetDay,
                okDay: previousValue.okDay + currentValue.okDay,
                dryDay: previousValue.dryDay + currentValue.dryDay,
                siteArea: previousValue.siteArea + currentValue.siteArea,
                date: currentValue.date, // date doesn't matter
                siteName: currentValue.siteName, // site name should be the same as filtering by siteId
                siteId: currentValue.siteId,
              };
            });
            final.siteArea = final.siteArea / amount;
            this.filteredApplicationEfficiencyData.push(final);
          }
          this.filteredApplicationEfficiencyData.forEach((f) => (f.demandMl = f.demandMl / f.siteArea));
          this.filteredApplicationEfficiencyData.forEach((f) => (f.irrigationMl = f.irrigationMl / f.siteArea));
          this.filteredApplicationEfficiencyData.forEach(
            (f) => (f.reservesAndUsefulRainfallMl = f.reservesAndUsefulRainfallMl / f.siteArea),
          );
          this.loadSiteTotalEfficiencyChart();
          return;
        default:
          this.filteredApplicationEfficiencyData = applicationData.filter((w) => w.siteName === this.waterEfficiencyFormat);
      }

      this.filteredApplicationEfficiencyData.forEach((f) => (f.demandMl = f.demandMl / f.siteArea));
      this.filteredApplicationEfficiencyData.forEach((f) => (f.irrigationMl = f.irrigationMl / f.siteArea));
      this.filteredApplicationEfficiencyData.forEach((f) => (f.reservesAndUsefulRainfallMl = f.reservesAndUsefulRainfallMl / f.siteArea));

      this.loadApplicationEfficiencyChart();
    }
  }

  private convertWaterApplicationUnits(data: fuse.waterApplicationEfficiencyResultDto[]) {
    const convertMLToKL = 1000; // these are ML but base is KL
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      d.demandMl = this.largeVolAreaUnit.fromBase(d.demandMl) * convertMLToKL;
      d.irrigationMl = this.largeVolAreaUnit.fromBase(d.irrigationMl) * convertMLToKL;
      d.reservesAndUsefulRainfallMl = this.largeVolAreaUnit.fromBase(d.reservesAndUsefulRainfallMl) * convertMLToKL;
    }
    return data;
  }

  public onWaterReportDateChange(): void {
    if (this.assStateCur != 'Water Usage') {
      this.waterReportToMaxDate = this.earliest(this.irrigationEndDate, this.weekDate);
    } else {
      this.waterReportToMaxDate = this.irrigationEndDate.clone();
    }
    this.waterReportFromMaxDate = this.earliest(this.waterReportToDate, this.currentDate);
    this.isReportBtnEnabled = true;
    this.isWaterCsvEnabled = false;
    switch (this.assStateCur) {
      case this.AssetStatesList.WaterUsage: {
        this.runTimeData = null;
        break;
      }
      case this.AssetStatesList.SoilMoisture: {
        this.soilMoistureData = null;
        break;
      }
      case this.AssetStatesList.DataExtract: {
        this.waterTableSummaryTotals = null;
        break;
      }
      case this.AssetStatesList.IrrigationSchedule: {
        this.runTimeData = null;
        break;
      }
      case this.AssetStatesList.WaterApplicationEfficency: {
        // sets the date to maximum of 1 year above the from date
        const year = this.waterReportFromDate.clone().addYears(1).addDays(-1);

        if (year < this.waterReportToDate) {
          this.waterReportToDate = year;
        }

        const adjusted6DaysAhead = this._dayNumberService.convertBrowserDateTimeToLocaleDate().addDays(6);

        if (adjusted6DaysAhead < year) {
          this.waterReportToMaxDate = adjusted6DaysAhead;
        } else {
          this.waterReportToMaxDate = year;
        }
       
        this.filteredApplicationEfficiencyData = [];
        break;
      }
    }
  }

  private getSMstatus(low: number, ok: number, high: number, what: string): string {
    let msg: string;
    let theIcon: string;
    if (low == 1) {
      msg = 'Low';
      theIcon = 'icon-arrow-down-bold-circle soilTargetDry';
    } else if (high == 1) {
      msg = 'High';
      theIcon = 'icon-arrow-up-bold-circle soilTargetWet';
    } else if (ok == 1) {
      msg = 'OK';
      theIcon = 'icon-checkbox-marked-circle soilTargetOk';
    } else {
      msg = 'No Value';
      theIcon = 'icon-minus-circle-outline soilTargetNone';
    }

    if (what == 'icon') {
      return theIcon;
    } else {
      return msg;
    }
  }

  private replaceNanToZero(val) {
    if (isNaN(val)) {
      return 0;
    }
    if (val === Infinity) {
      return 0;
    }
    return val;
  }

  private loadSoilMoistureChart() {
    this.soilChart = (AmCharts as any).makeChart('soil-moisture-chart', {
      type: 'serial',
      theme: 'light',
      legend: {
        horizontalGap: 2,
        position: 'bottom',
        useGraphSettings: true,
        markerSize: 16,
      },
      dataProvider: this.soilMoistureData,
      valueAxes: [
        {
          stackType: 'regular',
          gridAlpha: 0,
          maximum: 100,
          title: '%',
        },
      ],
      graphs: [
        {
          balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[percents]]</b>%</span>",
          fillAlphas: 1,
          labelText: '[[percents]]',
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.LOW'),
          type: 'column',
          fillColors: '#FFC107',
          valueField: 'low',
        },
        {
          balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[percents]]</b>%</span>",
          fillAlphas: 1,
          labelText: '[[percents]]',
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.OK'),
          type: 'column',
          fillColors: '#00C853',
          valueField: 'ok',
        },
        {
          balloonText: "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[percents]]</b>%</span>",
          fillAlphas: 1,
          labelText: '[[percents]]',
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.HIGH'),
          type: 'column',
          fillColors: '#90CAF9',
          valueField: 'high',
        },
      ],
      categoryField: 'name',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        gridAlpha: 0,
        position: 'left',
        labelRotation: 90,
        fontSize: 12,
      },
    });

    this.soilChart.validateData();
  }

  public generateReport() {
    this.getWaterReports();
    this.isWaterCsvEnabled = true;
  }

  private labelFunc(title, unit: uomUnit) {
    return (graphDataItem, graph) => {
      const val = graphDataItem.values.value;
      if (val) {
        return `${title}: <b>${val.toFixed(unit.decimalPlaces)}</b>`;
      } else {
        return '';
      }
    };
  }

  // create the amchart for water usage
  private loadWaterUsageChart() {
    const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();
    const waterReportToDate_short = new Date(
      this.waterReportToDate.getFullYear(),
      this.waterReportToDate.getMonth(),
      this.waterReportToDate.getDate(),
    );
    const adjustedToday_short = new Date(adjustedToday.getFullYear(), adjustedToday.getMonth(), adjustedToday.getDate());
    const displayProjectedRunningInLegend: boolean = waterReportToDate_short > adjustedToday_short;

    this.obsChart = (AmCharts as any).makeChart('water-usage-chart', {
      theme: 'light',
      type: 'serial',
      dataProvider: this.waterChartData,
      autoresize: false,
      autoTransform: true,
      autoDisplay: true,
      legend: {
        useGraphSettings: true,
        horizontalGap: 2,
        position: 'bottom',
      },
      valueAxes: [
        {
          position: 'right',
          title: this._languageService.instant('COMMON.CUMULATIVE_TOTALS', { unit: this.waterRunningUsageUnit.name }, false),
          id: 'axisRunning',
        },
        {
          position: 'left',
          title: this._languageService.instant('COMMON.PERIODIC_VALUES', { unit: this.waterUsageUnit.name }, false),
          id: 'axisPeriod',
        },
      ],
      startDuration: 0,
      graphs: [
        {
          //"balloonText": "Running Budget: <b>[[value]]</b>",
          bullet: 'round',
          bulletAlpha: 0,
          fillAlphas: 0.1,
          lineAlpha: 0.9,
          title: this._languageService.instant('COMMON.RUNNING_BUDGET'),
          type: 'line',
          lineColor: '#27aae0',
          lineThickness: 3,
          valueField: 'budget_running',
          valueAxis: 'axisRunning',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('COMMON.RUNNING_BUDGET') + ': <b>' + val.toFixed(4) + '</b>';
            } else {
              return '';
            }
          },
        },
        {
          //"balloonText": "Running Actual: <b>[[value]]</b>",
          bullet: 'round',
          bulletAlpha: 0,
          fillAlphas: 0.1,
          lineAlpha: 0.9,
          title: this._languageService.instant('COMMON.RUNNING_ACTUAL'),
          type: 'line',
          clustered: false,
          lineColor: '#1A237E',
          lineThickness: 3,
          valueField: 'actual_running',
          valueAxis: 'axisRunning',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('COMMON.RUNNING_ACTUAL') + ': <b>' + val.toFixed(4) + '</b>';
            } else {
              return '';
            }
          },
        },
        {
          //"balloonText": "Running Actual: <b>[[value]]</b>",
          // make this one into projected running....
          bullet: 'round',
          bulletAlpha: 0,
          fillAlphas: 0.1,
          lineAlpha: 0.9,
          title: this._languageService.instant('COMMON.RUNNING_PROJECTED'),
          type: 'line',
          clustered: false,
          dashLength: 4,
          lineColor: '#1A237E',
          lineThickness: 3,
          valueField: 'projected_running',
          valueAxis: 'axisRunning',
          visibleInLegend: displayProjectedRunningInLegend,
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('COMMON.RUNNING_PROJECTED') + ': <b>' + val.toFixed(4) + '</b>';
            } else {
              return '';
            }
          },
        },
        {
          //"balloonText": "Budget: <b>[[value]]</b>",
          fillAlphas: 0.9,
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.BUDGET'),
          type: 'column',
          fillColors: '#0084CA',
          valueField: 'budget',
          valueAxis: 'axisPeriod',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('COMMON.BUDGET') + ': <b>' + val.toFixed(4) + '</b>';
            } else {
              return '';
            }
          },
        },
        {
          // "balloonText": "Actual: <b>[[value]]</b>",
          fillAlphas: 0.9,
          lineAlpha: 0.2,
          title: this._languageService.instant('COMMON.ACTUAL'),
          type: 'column',
          clustered: false,
          columnWidth: 0.5,
          fillColors: '#1A237E',
          valueField: 'actual',
          valueAxis: 'axisPeriod',
          balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
            const val = graphDataItem.values.value;
            if (val) {
              return this._languageService.instant('COMMON.ACTUAL') + ': <b>' + val.toFixed(4) + '</b>';
            } else {
              return '';
            }
          },
        },
      ],
      plotAreaFillAlphas: 0.1,
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'start',
        axisAlpha: 0,
        gridAlpha: 0,
        position: 'left',
        labelRotation: 90,
        fontSize: 12,
        autoWrap: true,
      },
    });

    this.obsChart.validateData();
  }

  private makePanels() {
    this.applicationSoilPanel = new AmCharts.StockPanel();
    this.applicationSoilPanel.stockGraphs = [] as AmCharts.StockGraph[];
    this.applicationSoilPanel.valueAxes = [] as AmCharts.ValueAxis[];

    const soilLabel = new AmCharts.Label();
    soilLabel.size = 16;
    soilLabel.x = 10;
    soilLabel.y = 10;
    soilLabel.text = this._languageService.instant('COMMON.SOIL_MOISTURE');
    this.applicationSoilPanel.allLabels = [soilLabel];

    const soilCatAxis = new AmCharts.CategoryAxis();
    soilCatAxis.showFirstLabel = true;
    soilCatAxis.showLastLabel = true;
    this.applicationSoilPanel.categoryAxis = soilCatAxis;

    this.applicationSoilPanel.marginTop = 0;
    this.applicationSoilPanel.percentHeight = 40;
    this.applicationSoilPanel.stockLegend = {
      horizontalGap: 2,
      maxColumns: 3,
      valueTextRegular: ' ',
      position: 'bottom',
      markerSize: 16,
      useGraphSettings: true,
      marginTop: 0,
    } as AmCharts.StockLegend;
    const axesSettings = {
      stackType: '100%',
      axisAlpha: 0.3,
      gridAlpha: 0,
      position: 'left',
    } as AmCharts.ValueAxis;

    this.applicationSoilPanel.stockGraphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.BELOW_TARGET_RANGE'),
        'dryDay',
        '#FFC107',
        '[[percents]]%',
        true,
        false,
      ) as AmCharts.StockGraph,
    );
    this.applicationSoilPanel.stockGraphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.ON_TARGET'),
        'okDay',
        '#00C853',
        '[[percents]]%',
        true,
        false,
      ) as AmCharts.StockGraph,
    );
    this.applicationSoilPanel.stockGraphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.ABOVE_TARGET_RANGE'),
        'wetDay',
        '#90CAF9',
        '[[percents]]%',
        true,
        false,
      ) as AmCharts.StockGraph,
    );
    this.applicationSoilPanel.valueAxes.push(axesSettings);

    this.applicationWaterPanel = new AmCharts.StockPanel();
    this.applicationWaterPanel.stockGraphs = [] as AmCharts.StockGraph[];
    this.applicationWaterPanel.valueAxes = [] as AmCharts.ValueAxis[];

    const waterLabel = new AmCharts.Label();
    waterLabel.size = 16;
    waterLabel.x = 10;
    waterLabel.y = 10;
    waterLabel.text = this._languageService.instant('AC.GROUP.RPT.Water Application Efficiency');
    this.applicationWaterPanel.allLabels = [waterLabel];

    const waterCatAxis = new AmCharts.CategoryAxis();
    waterCatAxis.showFirstLabel = true;
    waterCatAxis.showLastLabel = true;
    this.applicationWaterPanel.categoryAxis = waterCatAxis;

    this.applicationWaterPanel.marginTop = 0;
    this.applicationWaterPanel.percentHeight = 60;
    this.applicationWaterPanel.columnWidth = 0.9;
    this.applicationWaterPanel.stockLegend = {
      horizontalGap: 2,
      maxColumns: 3,
      valueTextRegular: ' ',
      position: 'bottom',
      markerSize: 16,
      useGraphSettings: true,
      marginTop: 0,
    } as AmCharts.StockLegend;
    this.applicationWaterPanel.stockGraphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.CROP_WATER'),
        'demandMl',
        '#5BB600',
        null,
        false,
        false,
        this.largeVolAreaUnit.symbol,
      ) as AmCharts.StockGraph,
    );
    this.applicationWaterPanel.stockGraphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.IRRIGATION_APPLIED'),
        'irrigationMl',
        '#1A237E',
        null,
        true,
        false,
        this.largeVolAreaUnit.symbol,
      ) as AmCharts.StockGraph,
    );
    this.applicationWaterPanel.stockGraphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.RESERVES_USEFUL'),
        'reservesAndUsefulRainfallMl',
        '#2196F3',
        null,
        true,
        false,
        this.largeVolAreaUnit.symbol,
      ) as AmCharts.StockGraph,
    );
    const waterAxesSettings = {
      stackType: 'regular',
      axisAlpha: 0.3,
      gridAlpha: 0,
      position: 'left',
      title: this.largeVolAreaUnit.symbol,
    } as AmCharts.ValueAxis;
    this.applicationWaterPanel.valueAxes.push(waterAxesSettings);
  }

  private makeGraphSeries(
    hideValue: boolean,
    title: string,
    value: string,
    colour: string,
    label: string,
    stackable: boolean,
    useDataColors: boolean = null,
    unitName: string = '',
    decimalPlaces: number = 2,
    useTotal: boolean = false,
  ) {
    const column = {
      fillAlphas: 1,
      labelText: label,
      lineAlpha: 0.2,
      stackable: stackable,
      title: title,
      type: 'column',
      fillColors: colour,
      valueField: value,
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = graphDataItem.values.value;
        const total = graphDataItem.values.total;
        let cat = graphDataItem.category;
        if (typeof cat == 'object') {
          cat = DateUtils.Locale.asDateDefault(cat as Date);
        }
        if (hideValue) {
          return cat + ' (' + title + ')';
        } else if (useTotal) {
          return cat + ' (' + title + ')</br>' + '<b>' + total.toFixed(decimalPlaces) + ' ' + unitName + '</b>';
        } else {
          return cat + ' (' + title + ')</br>' + '<b>' + val.toFixed(decimalPlaces) + ' ' + unitName + '</b>';
        }
      },
    };
    useDataColors !== null && (column['useDataSetColors'] = useDataColors);
    return column;
  }

  private makeDataSet(field) {
    const dataSet = {
      dataProvider: this.filteredApplicationEfficiencyData,
      categoryField: field,
      fieldMappings: [
        {
          fromField: 'okDay',
          toField: 'okDay',
        },
        {
          fromField: 'dryDay',
          toField: 'dryDay',
        },
        {
          fromField: 'wetDay',
          toField: 'wetDay',
        },
        {
          fromField: 'demandMl',
          toField: 'demandMl',
        },
        {
          fromField: 'irrigationMl',
          toField: 'irrigationMl',
        },
        {
          fromField: 'reservesAndUsefulRainfallMl',
          toField: 'reservesAndUsefulRainfallMl',
        },
      ],
    };
    return dataSet;
  }

  private loadApplicationEfficiencyChart() {
    this.applicationChart = null;
    let period = 'MM';
    const catAxesSet = new AmCharts.CategoryAxesSettings();
    catAxesSet.equalSpacing = true;
    catAxesSet.inside = false;
    catAxesSet.position = 'bottom';
    const chartCursorSet = new AmCharts.ChartCursorSettings();
    const chartScrollbarSet = new AmCharts.ChartScrollbarSettings();
    chartScrollbarSet.color = 'white';
    chartScrollbarSet.backgroundColor = '#e5e5e5';
    chartScrollbarSet.selectedBackgroundColor = 'lightgray';
    chartScrollbarSet.height = 24;
    (chartScrollbarSet as any).position = 'top';
    switch (this.efficiencyChartPeriod) {
      case 'Weekly':
        period = '7DD';
        catAxesSet.labelRotation = 45;
        catAxesSet.axisHeight = 35;
        break;
      case 'Whole':
        catAxesSet.dateFormats = [
          { period: 'MM', format: '' },
          { period: 'YYYY', format: '' },
          { period: 'WW', format: '' },
          { period: 'DD', format: '' },
        ];
        chartCursorSet.enabled = false;
        break;
      default:
        catAxesSet.labelRotation = 45;
        catAxesSet.axisHeight = 35;
        break;
    }
    if (this.filteredApplicationEfficiencyData.length <= 8) {
      chartScrollbarSet.enabled = false;
    }
    catAxesSet.minPeriod = period;
    this.applicationChart = new AmCharts.AmStockChart();
    this.applicationChart.dataSets = [] as AmCharts.DataSet[];
    this.applicationChart.panels = [] as AmCharts.StockPanel[];
    this.applicationChart.categoryAxesSettings = catAxesSet;
    this.applicationChart.chartCursorSettings = chartCursorSet;
    this.applicationChart.panelsSettings = {
      marginLeft: 80,
      marginTop: 40,
      marginBottom: 0,
    } as AmCharts.PanelsSettings;
    this.applicationChart.valueAxesSettings = {
      showLastLabel: true,
      showFirstLabel: true,
      inside: false,
    } as AmCharts.ValueAxesSettings;
    this.applicationChart.legendSettings = {
      position: 'bottom',
      marginTop: 0,
      marginBottom: 0,
      align: 'center',
    } as unknown as AmCharts.LegendSettings;
    this.applicationChart.chartScrollbarSettings = chartScrollbarSet as AmCharts.ChartScrollbarSettings;

    this.makePanels();
    const data = this.makeDataSet('date') as AmCharts.DataSet;
    this.applicationChart.dataSets.push(data);
    this.adjustStockColumns(this.filteredApplicationEfficiencyData.length);
    this.applicationChart.panels.push(this.applicationWaterPanel);
    this.applicationChart.panels.push(this.applicationSoilPanel);
    const totalChart = AmCharts.makeChart('application-efficiency-chart', this.applicationChart);
    totalChart.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endDate: Date;
        startDate: Date;
      }) => {
        const oneDay = 24 * 60 * 60 * 1000;
        const days = (e.endDate.valueOf() - e.startDate.valueOf()) / oneDay;
        let columns = 1;
        if (this.efficiencyChartPeriod == 'Weekly') {
          columns = days / 7;
        } else if (this.filteredApplicationEfficiencyData.length > 1) {
          columns = days / 30.4375; // only needs to be approximate as this will be rounded
        }
        this.adjustStockColumns(Math.round(columns));
        this.applicationSoilPanel.validateNow();
        this.applicationWaterPanel.validateNow();
      },
    );
  }

  private adjustStockColumns(difference: number) {
    switch (difference) {
      case 7:
        this.applicationSoilPanel.columnWidth = 0.77;
        this.applicationWaterPanel.columnWidth = 0.9;
        break;
      case 6:
        this.applicationSoilPanel.columnWidth = 0.67;
        this.applicationWaterPanel.columnWidth = 0.84;
        break;
      case 5:
        this.applicationSoilPanel.columnWidth = 0.577;
        this.applicationWaterPanel.columnWidth = 0.72;
        break;
      case 4:
        this.applicationSoilPanel.columnWidth = 0.48;
        this.applicationWaterPanel.columnWidth = 0.6;
        break;
      case 3:
        this.applicationSoilPanel.columnWidth = 0.385;
        this.applicationWaterPanel.columnWidth = 0.48;
        break;
      case 2:
        this.applicationSoilPanel.columnWidth = 0.29;
        this.applicationWaterPanel.columnWidth = 0.36;
        break;
      case 1:
        this.applicationSoilPanel.columnWidth = 0.2;
        this.applicationWaterPanel.columnWidth = 0.25;
        break;
      case 0:
        this.applicationSoilPanel.columnWidth = 0.2;
        this.applicationWaterPanel.columnWidth = 0.25;
        break;
      default:
        this.applicationSoilPanel.columnWidth = 0.8;
        this.applicationWaterPanel.columnWidth = 0.9;
        break;
    }
  }

  private makeSimpleSiteEfficiencyChart(
    dataProvider,
    stackType: string,
    hasScrollbar: boolean,
    axisTitle = null,
    chartTitle = '',
    columnWidth = 0.8,
  ): AmCharts.AmSerialChart {
    const chart = {
      type: 'serial',
      allLabels: [{ size: 16, x: 10, y: 0, text: chartTitle }],
      dataProvider: dataProvider,
      marginLeft: 120,
      marginRight: 120,
      marginBottom: 0,
      marginTop: 40,
      columnWidth: columnWidth,
      categoryAxis: { equalSpacing: true, labelRotation: 35, labelFunction: (value: string) => StringUtils.truncate(value, 23) } as any,
      categoryField: 'siteName',
      legend: { position: 'bottom', marginTop: 0, marginBottom: 0, align: 'center', useGraphSettings: true },
      valueAxes: [
        {
          stackType: stackType,
          position: 'left',
          axisAlpha: 0.3,
          gridAlpha: 0,
          title: axisTitle,
          ignoreAxisWidth: true,
          minimum: 0,
          strictMinMax: true,
        },
      ],
      graphs: [] as AmCharts.AmGraph[],
    } as AmCharts.AmSerialChart;
    if (hasScrollbar && dataProvider.length > 8) {
      const scrollbar = new AmCharts.ChartScrollbar();
      scrollbar.color = 'white';
      scrollbar.backgroundColor = '#e5e5e5';
      scrollbar.selectedBackgroundColor = 'lightgray';
      scrollbar.scrollbarHeight = 24;
      chart.chartScrollbar = scrollbar;
      chart.marginTop = 34;
    }
    return chart;
  }

  private loadSiteTotalEfficiencyChart() {
    const soilChart = this.makeSimpleSiteEfficiencyChart(
      this.filteredApplicationEfficiencyData,
      '100%',
      false,
      null,
      this._languageService.instant('COMMON.SOIL_MOISTURE'),
    );
    soilChart.graphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.BELOW_TARGET_RANGE'),
        'dryDay',
        '#FFC107',
        '[[percents]]%',
        true,
      ) as AmCharts.AmGraph,
    );
    soilChart.graphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.ON_TARGET'),
        'okDay',
        '#00C853',
        '[[percents]]%',
        true,
      ) as AmCharts.AmGraph,
    );
    soilChart.graphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.ABOVE_TARGET_RANGE'),
        'wetDay',
        '#90CAF9',
        '[[percents]]%',
        true,
      ) as AmCharts.AmGraph,
    );

    const waterChart = this.makeSimpleSiteEfficiencyChart(
      this.filteredApplicationEfficiencyData,
      'regular',
      true,
      this.largeVolAreaUnit.symbol,
      this._languageService.instant('AC.GROUP.RPT.Water Application Efficiency'),
      0.9,
    );
    waterChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.CROP_WATER'),
        'demandMl',
        '#5BB600',
        null,
        false,
        null,
        this.largeVolAreaUnit.symbol,
      ) as AmCharts.AmGraph,
    );
    waterChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.IRRIGATION_APPLIED'),
        'irrigationMl',
        '#1A237E',
        null,
        true,
        null,
        this.largeVolAreaUnit.symbol,
      ) as AmCharts.AmGraph,
    );
    waterChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.RESERVES_USEFUL'),
        'reservesAndUsefulRainfallMl',
        '#2196F3',
        null,
        true,
        null,
        this.largeVolAreaUnit.symbol,
      ) as AmCharts.AmGraph,
    );
    const soilChartObj = AmCharts.makeChart('application-efficiency-soil-chart', soilChart) as AmCharts.AmSerialChart;
    const waterChartObj = AmCharts.makeChart('application-efficiency-water-chart', waterChart) as AmCharts.AmSerialChart;
    waterChartObj.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endValue: string;
        startValue: string;
      }) => {
        // This should use strings but the abstract method has Dates ... even though it's comment says it uses strings
        soilChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
      },
    );
    soilChartObj.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endValue: string;
        startValue: string;
      }) => {
        waterChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
      },
    );
  }

  private loadWaterProductivityCharts() {
    if (!this.waterProductivityEfficiencyData.length) {
      return;
    }

    let productivityData = this.waterProductivityEfficiencyData.map((a) =>
      Object.assign({}, a),
    ) as fuse.waterProductivityEfficiencyResultDto[];
    productivityData = this.convertWaterProductivtyUnits(productivityData);

    const waterYieldChart = this.makewaterYieldChart(productivityData); //it is too complicated so moving to separate method

    const areaYieldChart = this.makeSimpleSiteEfficiencyChart(
      productivityData,
      'none',
      false,
      this._languageService.instant('AC.GROUP.YIELD_AREA', { wUnit: this.cropWeightUnit.symbol, aUnit: this.areaUnit.symbol }),
      this._languageService.instant('AC.GROUP.SITE_AREA_YIELD', { unit: this.areaUnit.symbol }),
    );
    areaYieldChart.legend = null;
    areaYieldChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.WEIGHT_YIELD_AREA'),
        'weightYieldHa',
        '#008080',
        null,
        false,
        null,
        this.cropWeightUnit.symbol + '/' + this.areaUnit.symbol,
      ) as AmCharts.AmGraph,
    );
    areaYieldChart.graphs[0].balloonText = '[[category]]</br>[[value]]';

    const soilChart = this.makeSimpleSiteEfficiencyChart(
      productivityData,
      '100%',
      false,
      null,
      this._languageService.instant('COMMON.SOIL_MOISTURE'),
    );
    soilChart.graphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.BELOW_TARGET_RANGE'),
        'dryDay',
        '#FFC107',
        '[[percents]]%',
        true,
      ) as AmCharts.AmGraph,
    );
    soilChart.graphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.ON_TARGET'),
        'okDay',
        '#00C853',
        '[[percents]]%',
        true,
      ) as AmCharts.AmGraph,
    );
    soilChart.graphs.push(
      this.makeGraphSeries(
        true,
        this._languageService.instant('COMMON.ABOVE_TARGET_RANGE'),
        'wetDay',
        '#90CAF9',
        '[[percents]]%',
        true,
      ) as AmCharts.AmGraph,
    );

    const waterChart = this.makeSimpleSiteEfficiencyChart(
      productivityData,
      'regular',
      false,
      this.volumeUnit.symbol + '/' + this.areaUnit.symbol,
      this._languageService.instant('AC.GROUP.RPT.Water Application Efficiency'),
      0.9,
    );
    waterChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.CROP_WATER'),
        'demandMlHa',
        '#5BB600',
        null,
        false,
        null,
        this.volumeUnit.symbol + '/' + this.areaUnit.symbol,
      ) as AmCharts.AmGraph,
    );
    waterChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.IRRIGATION_APPLIED'),
        'irrigationMlHa',
        '#1A237E',
        null,
        true,
        null,
        this.volumeUnit.symbol + '/' + this.areaUnit.symbol,
      ) as AmCharts.AmGraph,
    );
    waterChart.graphs.push(
      this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.RESERVES_USEFUL'),
        'reservesAndUsefulRainfallMlHa',
        '#2196F3',
        null,
        true,
        null,
        this.volumeUnit.symbol + '/' + this.areaUnit.symbol,
      ) as AmCharts.AmGraph,
    );

    const soilChartObj = AmCharts.makeChart('productivity-efficiency-soil-chart', soilChart) as AmCharts.AmSerialChart;
    const waterChartObj = AmCharts.makeChart('productivity-efficiency-water-chart', waterChart) as AmCharts.AmSerialChart;
    const areaYieldChartObj = AmCharts.makeChart(
      'productivity-efficiency-area-yield-chart',
      areaYieldChart,
    ) as AmCharts.AmSerialChart;
    const waterYieldChartObj = AmCharts.makeChart(
      'productivity-efficiency-water-yield-chart',
      waterYieldChart,
    ) as AmCharts.AmSerialChart;
    waterChartObj.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endValue: string;
        startValue: string;
      }) => {
        // This should use strings but the abstract method has Dates ... even though it's comment says it uses strings
        soilChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        waterYieldChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        areaYieldChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
      },
    );
    soilChartObj.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endValue: string;
        startValue: string;
      }) => {
        waterChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        waterYieldChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        areaYieldChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
      },
    );
    areaYieldChartObj.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endValue: string;
        startValue: string;
      }) => {
        waterChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        soilChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        waterYieldChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
      },
    );
    waterYieldChartObj.addListener(
      'zoomed',
      (e: {
        type: string;
        graph: AmCharts.AmGraph;
        item: AmCharts.GraphDataItem;
        index: number;
        chart: AmCharts.AmChart;
        endValue: string;
        startValue: string;
      }) => {
        waterChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        soilChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
        areaYieldChartObj.zoomToCategoryValues(e.startValue as any, e.endValue as any);
      },
    );
  }

  private makewaterYieldChart(productivityData) {
    let waterYieldChart = null;
    let singleAxisTitle = this._languageService.instant('AC.GROUP.YIELD_WEIGHT', {
      wUnit: this.cropWeightUnit.symbol,
      unit: this.volumeUnit.symbol,
    });

    if (this.waterYieldGraphChoice === this.waterYieldGraphChoiceList.Price) {
      singleAxisTitle = this._languageService.instant('AC.GROUP.YIELD_PRICE', {
        currency: this.currency,
        unit: this.volumeUnit.symbol,
      });

      waterYieldChart = this.makeSimpleSiteEfficiencyChart(
        productivityData,
        'regular',
        true,
        singleAxisTitle,
        this._languageService.instant('AC.GROUP.SITE_VALUE_TITLE', { unit: this.volumeUnit.symbol }),
      );

      const priceYieldIncluding = this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.PRICE_YIELD_INCLUDING'),
        'priceYieldWaterTotal',
        '#EB6600',
        null,
        true,
        null,
        this.currency + '/' + this.volumeUnit.symbol,
        0,
        false,
      ) as AmCharts.AmGraph;

      priceYieldIncluding.balloonFunction = (graphDataItem, graph: AmCharts.AmGraph) => {
        return graphDataItem.category + 
        ' (' + this._languageService.instant('AC.GROUP.PRICE_YIELD_INCLUDING') + 
        ')</br><b>' + this.currency + ' ' + (graphDataItem as any).values.value.toFixed(0) + ' / ' + this.volumeUnit.symbol + '</b>';
      }

      waterYieldChart.graphs.push(priceYieldIncluding);

      const priceYieldExcluding = this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.PRICE_YIELD_EXCLUDING'),
        'priceYieldIrrigationDifference',
        '#EB6600',
        null,
        true,
        null,
        this.currency + '/' + this.volumeUnit.symbol,
        0, 
        true,
      ) as AmCharts.AmGraph;

      priceYieldExcluding.balloonFunction = (graphDataItem, graph: AmCharts.AmGraph) => {
        return graphDataItem.category + 
        ' (' + this._languageService.instant('AC.GROUP.PRICE_YIELD_EXCLUDING') + 
        ')</br><b>' + this.currency + ' ' + (graphDataItem as any).values.total.toFixed(0) + ' / ' + this.volumeUnit.symbol + '</b>';
      }

      priceYieldExcluding.fillAlphas = 0.5;

      waterYieldChart.graphs.push(priceYieldExcluding);
    } else if (this.waterYieldGraphChoice === this.waterYieldGraphChoiceList.Weight) {
      waterYieldChart = this.makeSimpleSiteEfficiencyChart(
        productivityData,
        'regular',
        true,
        singleAxisTitle,
        this._languageService.instant('AC.GROUP.SITE_YIELD_TITLE', { unit: this.volumeUnit.symbol }),
      );

      waterYieldChart.graphs.push(
        this.makeGraphSeries(
          false,
          this._languageService.instant('AC.GROUP.WEIGHT_YIELD_INCLUDING'),
          'weightYieldWaterTotal',
          '#3399ff',
          null,
          true,
          null,
          this.cropWeightUnit.symbol + '/' + this.volumeUnit.symbol,
          2,
          false,
        ) as AmCharts.AmGraph,
      );

      const weightYieldExcluding = this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.WEIGHT_YIELD_EXCLUDING'),
        'weightYieldIrrigationDifference',
        '#3399ff',
        null,
        true,
        null,
        this.cropWeightUnit.symbol + '/' + this.volumeUnit.symbol,
        2,
        true,
      ) as AmCharts.AmGraph;

      weightYieldExcluding.fillAlphas = 0.5;

      waterYieldChart.graphs.push(weightYieldExcluding);
    } else {
      waterYieldChart = this.makeSimpleSiteEfficiencyChart(
        productivityData,
        'regular',
        true,
        singleAxisTitle,
        this._languageService.instant('AC.GROUP.SITE_YIELD_VALUE_TITLE', { unit: this.volumeUnit.symbol }),
      );

      const priceYieldValueAxis = new AmCharts.ValueAxis();

      priceYieldValueAxis.position = 'right';
      priceYieldValueAxis.ignoreAxisWidth = true;
      priceYieldValueAxis.strictMinMax = true;
      priceYieldValueAxis.minimum = 0;
      priceYieldValueAxis.stackType = 'regular';
      priceYieldValueAxis.title = this._languageService.instant('AC.GROUP.YIELD_PRICE', {
        currency: this.currency,
        unit: this.volumeUnit.symbol,
      });
      priceYieldValueAxis.axisAlpha = 0.3;
      priceYieldValueAxis.gridAlpha = 0;

      waterYieldChart.valueAxes.push(priceYieldValueAxis); //for whatever reason I couldn't get .addValueAxis to work

      const priceYieldExcluding = this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.PRICE_YIELD_EXCLUDING'),
        'priceYieldIrrigationDifference',
        '#EB6600',
        null,
        true,
        null,
        this.currency + '/' + this.volumeUnit.symbol,
        0,
        true,
      ) as AmCharts.AmGraph;

      priceYieldExcluding.balloonFunction = (graphDataItem, graph: AmCharts.AmGraph) => {
        return graphDataItem.category + 
        ' (' + this._languageService.instant('AC.GROUP.PRICE_YIELD_EXCLUDING') + 
        ')</br><b>' + this.currency + ' ' + (graphDataItem as any).values.total.toFixed(0) + ' / ' + this.volumeUnit.symbol + '</b>';
      }

      priceYieldExcluding.valueAxis = priceYieldValueAxis;
      priceYieldExcluding.fillAlphas = 0.5;

      const priceYieldIncluding = this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.PRICE_YIELD_INCLUDING'),
        'priceYieldWaterTotal',
        '#EB6600',
        null,
        true,
        null,
        this.currency + '/' + this.volumeUnit.symbol,
        0, 
        false,
      ) as AmCharts.AmGraph;

      priceYieldIncluding.balloonFunction = (graphDataItem, graph: AmCharts.AmGraph) => {
        return graphDataItem.category + 
        ' (' + this._languageService.instant('AC.GROUP.PRICE_YIELD_INCLUDING') + 
        ')</br><b>' + this.currency + ' ' + (graphDataItem as any).values.value.toFixed(0) + ' / ' + this.volumeUnit.symbol + '</b>';
      }

      priceYieldIncluding.valueAxis = priceYieldValueAxis;

      const weightYieldExcluding = this.makeGraphSeries(
        false,
        this._languageService.instant('AC.GROUP.WEIGHT_YIELD_EXCLUDING'),
        'weightYieldIrrigationDifference',
        '#3399ff',
        null,
        true,
        null,
        this.cropWeightUnit.symbol + '/' + this.volumeUnit.symbol,
        2,
        true,
      ) as AmCharts.AmGraph;

      weightYieldExcluding.fillAlphas = 0.5;

      waterYieldChart.graphs.push(priceYieldIncluding);
      waterYieldChart.graphs.push(
        this.makeGraphSeries(
          false,
          this._languageService.instant('AC.GROUP.WEIGHT_YIELD_INCLUDING'),
          'weightYieldWaterTotal',
          '#3399ff',
          null,
          true,
          null,
          this.cropWeightUnit.symbol + '/' + this.volumeUnit.symbol,
          2,
          false,
        ) as AmCharts.AmGraph,
      );

      waterYieldChart.columnWidth = 0.9;

      waterYieldChart.graphs.push(priceYieldExcluding);
      waterYieldChart.graphs.push(weightYieldExcluding);
    }

    waterYieldChart.legend.switchable = false;
    waterYieldChart.legend.reversedOrder = true;

    return waterYieldChart;
  }

  private convertWaterProductivtyUnits(data: fuse.waterProductivityEfficiencyResultDto[]) {
    const convertMLToKL = 1000; // these are ML but base is KL
    const cropConversion = new uomUnit(this.cropWeightUnit);
    const volConversion = new uomUnit(this.volumeUnit);

    for (let i = 0; i < data.length; i++) {
      const d = data[i];

      d.priceYieldWaterTotal = volConversion.toBase(d.priceYieldWaterTotal) / convertMLToKL;
      d.priceYieldIrrigationDifference = volConversion.toBase(d.priceYieldIrrigationDifference) / convertMLToKL;
      d.weightYieldWaterTotal = volConversion.toBase(cropConversion.fromBase(d.weightYieldWaterTotal)) / convertMLToKL;
      d.weightYieldIrrigationDifference =
        volConversion.toBase(cropConversion.fromBase(d.weightYieldIrrigationDifference)) / convertMLToKL;
      d.weightYieldHa = this.areaUnit.toBase(cropConversion.fromBase(d.weightYieldHa));
      d.demandMlHa = this.areaUnit.toBase(volConversion.fromBase(d.demandMlHa) * convertMLToKL);
      d.irrigationMlHa = this.areaUnit.toBase(volConversion.fromBase(d.irrigationMlHa) * convertMLToKL);
      d.reservesAndUsefulRainfallMlHa = this.areaUnit.toBase(
        volConversion.fromBase(d.reservesAndUsefulRainfallMlHa) * convertMLToKL,
      );
    }

    return data;
  }

  private getWaterApplicationEfficiency() {
    switch (this.waterEfficiencyPeriod) {
      case this.waterEfficiencyPeriodList.Weekly:
        this.efficiencyChartPeriod = 'Weekly';
        break;
      case this.waterEfficiencyPeriodList.Whole:
        this.efficiencyChartPeriod = 'Whole';
        break;
      default:
        this.efficiencyChartPeriod = 'Monthly';
    }

    const data = {
      accountId: this.accountId,
      groupId: this.groupId,
      dfr: this.waterReportFromDate.toString('yyyy-MM-dd'),
      dto: this.waterReportToDate.toString('yyyy-MM-dd'),
      granularityString: this.efficiencyChartPeriod,
    };

    this.isReportBtnEnabled = false;
    this._http
      .get(CommonHelper.getApiUrl('user/getWaterApplicationEfficiencyReport'), { params: data })
      .then((response) => {
        this.waterApplicationEfficiencyData = response.data as fuse.waterApplicationEfficiencyResultDto[];
        if (this.waterApplicationEfficiencyData !== null) {
          this.onReportWaterEfficiencyFormatChange();
        } else {
          this._languageService.whoops();
        }
      })
      .catch(() => {
        this._languageService.whoops();
        console.log('Error');
      });
  }

  private getWaterProductivityEfficiency() {
    const startDate = new Date(this.waterProductivitySeason, this.groupAccountInfo.irrigationSeasonStartMonth - 1, 1);
    const endDate = new Date(this.waterProductivitySeason + 1, this.groupAccountInfo.irrigationSeasonStartMonth - 1, 1).addDays(-1);
    const data = {
      accountId: this.accountId,
      groupId: this.groupId,
      dfr: startDate.toString('yyyy-MM-dd'),
      dto: endDate.toString('yyyy-MM-dd'),
    };

    this.isReportBtnEnabled = false;
    this._http
      .get(CommonHelper.getApiUrl('user/getWaterProductivityEfficiencyReport'), { params: data })
      .then((response) => {
        const data = response.data as fuse.waterProductivityEfficiencyDataDto;
        if (data !== null && data.pricesMissing !== null && data.results !== null) {
          this.waterProductivityEfficiencyData = data.results;
          this.waterProductivityPricesMissing = data.pricesMissing;
          this.waterProductivityStartDate = data.startDate === null ? startDate : data.startDate;
          this.waterProductivityEndDate = data.endDate === null ? endDate : data.endDate;
          this.loadWaterProductivityCharts();
        } else {
          this._languageService.whoops();
        }
      })
      .catch(() => {
        this._languageService.whoops();
        console.log('Error getting data from getWaterProductivityEfficiencyReport URL');
      });
  }

  private getValidYears() {
    const startYear = SWANConstants.MinDate.getFullYear();
    const years = [] as number[];

    let year = new Date().getFullYear();

    for (year; year >= startYear; year--) {
      years.push(year);
    }

    return years;
  }

  private getUnitOptions() {
    this._http.get(CommonHelper.getApiUrl('group/getYieldVolumeUnits')).then(
      (res) => {
        if (!res.data || res.data == null) {
          this._languageService.whoops();
        } else {
          const unitOptions = res.data as fuse.groupUnitChoicesDto;
          this._volumeUnitOptions = unitOptions.volumeUnits;
          this._cropYieldOptions = unitOptions.cropUnits;
          this.fixUnitOptions(this._volumeUnitOptions);
          this.fixUnitOptions(this._cropYieldOptions);
          // find the default starting units
          const volumePreference = this._unitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.large, true);
          this.volumeUnit = this._volumeUnitOptions.find((a) => a.unitId == volumePreference.unitId);
          const yieldPreference = this._unitOfMeasureService.getUnits(UnitTypes.Yield, unitSizes.normal, true);
          this.cropWeightUnit = this._cropYieldOptions.find((a) => a.unitId == yieldPreference.unitId);
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  private fixUnitOptions(unitOpts) {
    unitOpts.forEach((us) => {
      let displayName = us.symbol;

      if (!displayName || unitOpts.filter((u) => u.symbol == us.symbol).length > 1) {
        displayName = us.description ?? `${us.symbol} (${us.unitId})`;
      }

      us['displayName'] = displayName;
    });
  }

  private loadDefaultGroupSettings(): void {
    const dateNow = new Date();

    if (!this.groupSite.SoilSettings?.length) {
      const siteSettingsSoilInitialValues = this._dataEntityService.getDefault('SiteSettingsSoil', dateNow);

      siteSettingsSoilInitialValues.AssetId = this.groupId;

      this._dataEntityService.addEntity('SiteSettingsSoil', siteSettingsSoilInitialValues);
    }

    if (!this.groupSite.CropSettings?.length) {
      const siteSettingsCropInitialValues = this._dataEntityService.getDefault('SiteSettingsCrop', dateNow);

      siteSettingsCropInitialValues.AssetId = this.groupId;

      this._dataEntityService.addEntity('SiteSettingsCrop', siteSettingsCropInitialValues);
    }

    if (!this.groupSite.WaterSettings?.length) {
      const siteSettingsWaterInitialValues = this._dataEntityService.getDefault('SiteSettingsWater', dateNow);

      siteSettingsWaterInitialValues.AssetId = this.groupId;

      this._dataEntityService.addEntity('SiteSettingsWater', siteSettingsWaterInitialValues);
    }
  }

  private fetchData() {
    this._dataEntityService.clear();

    this._fetchDataService.fetchAccountCMUs(this.accountId).then((data) => {
      this.cmus2 = data.map((cmu) => {
        return {
          AssetId: cmu.AssetId,
          Name: cmu.Name,
          Status: cmu.Status,
        } as Asset;
      });

      this.group = this.entityManager.getEntityByKey('Asset', this.groupId) as Asset;
      this._reportService.groupId = this.groupId;
      this._reportService.groupName = this.group.Name;
    });

    // Get information about the sites in the group.
    const sitesPromise = breeze.EntityQuery.from('getSites')
      .withParameters({ AssetId: this.groupId })
      .expand(['Asset.ChildAssets.ChildAsset', 'SoilSettings', 'CropSettings', 'WaterSettings'])
      .using(this.entityManager)
      .execute();

    sitesPromise.then(
      (data) => {
        // update the entities here, then
        this.groupSite = data.results[0] as Site;

        // get the group's site associations for the checkbox
        this.selectedIMUSiteIds = this.groupSite.Asset.ChildAssets?.filter(x => !x.TrackDeletedWhen).map(x => x.ChildAsset.AssetId) ?? [];

        if (this.selectedIMUSiteIds.length) {
          // the following parameter for permission validation purpose
          this._groupSettingService.isGroupSetting = true;
        }

        this.loadSiteCards();

        this.loadDefaultGroupSettings();

        this._notifyingService.notify(NotifyEvents.Group.Settings.Soil.Base, this.groupSite.SoilSettings[0]);
        this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.Base, this.groupSite.CropSettings[0]);
        this._notifyingService.notify(NotifyEvents.Group.Settings.Water.Base, this.groupSite.WaterSettings[0]);

        this.irrigationDayChanged(this.groupSite.WaterSettings[0].IrrigationDays);
      },
      (error) => {
        console.error('Error: Could not load Group entity (' + error.message + ')');
      },
    );
  }

  private loadSiteCards() {
    const params = { groupId: this.groupId };
    const afterFunc = (result) => {
      this.imuSites = result.map((asset) => {
        asset['displayName'] = this.groupSiteService.getAssetNameWithDetails(asset);

        return asset;
      });

      this.groupSites = this.imuSites.filter((s) => this.selectedIMUSiteIds?.includes(s.id));
    };

    this._entityListService.getEntityList('group/getPossibleMemberAssets', afterFunc, false, params);
  }

  public rejectChanges() {
    this.setComponentDefaults();

    if (this.groupId) {
      // cancel our changes and stay here
      this._dataEntityService.rejectChanges();
      this.reloadCounter++;

      this._notifyingService.notify(NotifyEvents.Group.Settings.Soil.Base, this.groupSite.SoilSettings[0]);
      this._notifyingService.notify(NotifyEvents.Group.Settings.Crop.Base, this.groupSite.CropSettings[0]);
      this._notifyingService.notify(NotifyEvents.Group.Settings.Water.Base, this.groupSite.WaterSettings[0]);

      // Issue 235: Reset any group setting checkboxes back to unchecked (false).
      this.resetCheckBoxes();

      this._groupSettingService.initRangeArray();
      this.resetSlider?.(this._groupSettingService.rangeValues); // NOTE: 'resetSlider' function could be undefined if not passed down from parent react component.

      if (this.primaryTab === GroupPrimaryTabEnums.Summary) {
        // NOTE: Only refresh state for 'Summary' tab in order not to loose the selected tab context.
        this._state.reload();
      }
    } else {
      // cancel out of making a new group (too late, its already saved before we arrive)
      this.groupSiteService.gotoGroups();
    }
  }

  public gotoGroupDetail(groupId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.groupId = groupId;
      context.tab1 = this.primaryTab;
      context.tab2 = this.settingsTab;
    });

    this._state.go('app.account.groups.detail', { id: groupId });
  }

  public gotoSiteDetail(site: AccountSiteInfo) {
    LocalStorageUtils.updateContextData((context) => {
      context.siteId = site.id;
      context.assetId = site.id;
    });

    this._state.go('app.account.sites.detail', { id: site.id, viewSchedule: true });
  }

  private removeSite(siteId: number) {
    const saPred: breeze.Predicate = breeze.Predicate.create('ParentAssetId', '==', this.groupSite.AssetId).and(
      'ChildAssetId',
      '==',
      siteId,
    );

    const query = breeze.EntityQuery.from('SiteAsset').where(saPred);
    const sa: breeze.Entity[] = this.entityManager.executeQueryLocally(query); // query the cache (synchronous)

    if (sa.length == 1) {
      sa[0].entityAspect.setDeleted();
    }
  }

  private addSite(siteId: number) {
    const siteAssetRec = {
      ParentAssetId: this.groupSite.AssetId, // CMU - Asset Id
      ChildAssetId: siteId, // Site Asset Id
      DataInputId: 1,
      Coefficient: 1,
      Priority: 1,
      Factor: 1,
    };

    const newSiteAsset = this.entityManager.createEntity('SiteAsset', siteAssetRec);
    this.entityManager.addEntity(newSiteAsset);
  }

  public onSitesSelectorOpen() {
    // The md-select directive eats keydown events for some quick select
    // logic. Since we have a search input here, we don't need that logic.
    this._document.find('md-select-header input[type="search"]').on('keydown', (e) => {
      e.stopPropagation();
    });

    this._tmpSelectedIMUSiteIds = this.selectedIMUSiteIds;
  }

  public onSitesSelectorClose() {
    // Clear the filter
    this.searchTerm = '';

    // Remove 'keydown' event handler.
    this._document.find('md-select-header input[type="search"]').off('keydown');

    this._tmpSelectedIMUSiteIds?.filter((siteId) => !this.selectedIMUSiteIds?.includes(siteId)).forEach((siteId) => {
      this.removeSite(siteId);
    });

    this.selectedIMUSiteIds?.filter((siteId) => !this._tmpSelectedIMUSiteIds?.includes(siteId)).forEach((siteId) => {
      this.addSite(siteId);
    });

    // This check prevents a group from being saved without any sites when all existing sites in the group are removed.
    // NOTE: Since Breeze tracks added/removed groups (via addSite/removeSite above), this check must be performed here to prevent duplicate identity errors on the server.
    const hasEntityChanges = this.entityManager.hasChanges('SiteAsset');
    const shouldSave = !!this.selectedIMUSiteIds?.length && hasEntityChanges;

    if (shouldSave) {
      // Save and reload.
      this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
        () => {
          this._languageService.success('AC.GROUP.GROUP_SAVED');
          this._state.reload();
        },
        (error) => {
          this._dupeHandlerService.showError(error);
        },
      );
    } else {
      this.canRevertChanges = !this.sitesInGroupForm.$valid;
    }
  }

  private addNewSetting(sType: string, ssArray: any, copyIdx: number): any {
    return this._groupSettingService.addNewSetting(sType, ssArray[copyIdx]);
  }

  // do any additional manipulations before we save
  public saveChanges(postSaveActions: PostSaveActions = null): void {
    let isValidChanges = true;

    //summary form
    if (this.summaryForm?.$invalid) {
      isValidChanges = false;
      this.primaryTab = GroupPrimaryTabEnums.Summary;
    } else if (this.isExistingGroupName) {
      isValidChanges = false;
      this.primaryTab = GroupPrimaryTabEnums.Summary;
    } else if (this.settingsSoilForm?.$invalid) {
      isValidChanges = false;
      this.primaryTab = GroupPrimaryTabEnums.Settings;
      this.settingsTab = GroupSettingsTabEnums.Soil;
    } else if (this.settingsCropForm?.$invalid) {
      isValidChanges = false;
      this.primaryTab = GroupPrimaryTabEnums.Settings;
      this.settingsTab = GroupSettingsTabEnums.Crop;
    } else if (this.settingsWaterForm?.$invalid) {
      isValidChanges = false;
      this.primaryTab = GroupPrimaryTabEnums.Settings;
      this.settingsTab = GroupSettingsTabEnums.Water;
    }

    if (this._groupSettingService.siteSoil.WorkingSoilDepthBottom_mm - 10 < this._groupSettingService.siteSoil.WorkingSoilDepthTop_mm) {
      isValidChanges = false;
      this.primaryTab = GroupPrimaryTabEnums.Settings;
      this.settingsTab = GroupSettingsTabEnums.Soil;
    }

    if (isValidChanges == false) {
      const alert = this._languageService.fixErrorsDialog();

      this._mdDialog.show(alert).then(() => {
        this._mdDialog.hide();
      });

      return;
    }

    const siteSoil = this._groupSettingService.siteSoil;
    const siteWater = this._groupSettingService.siteWater;
    const getTitleWithUnits = (opt: updateOpt): string => {
      let title = opt.title;

      if (opt.shouldUseTranslation) {
        title = this._languageService.instant(`AC.GROUP.SETTINGS.${opt.title}`);
      }

      return `${title} ${opt.units}`;
    }

    const updatedSoilTopNames =
      this.soilTop.filter(x => x.update).map((soil) => {
        const val = soil.unit.fromBaseRounded(siteSoil[soil.model], soil.decimal);

        return [getTitleWithUnits(soil), val];
      });

    const updatedCropTopNames =
      this.cropTop.filter(x => x.update).map((crop) => {
        return [getTitleWithUnits(crop), this.displayCropSetting(crop)];
      });

    const updatedWaterTopNames =
      this.waterTop.filter(x => x.update && x.visible).map((water) => {
        return [getTitleWithUnits(water), this.displayWaterSetting(water)]; // Could add units if required (i.e., 'water.units').
      });

    const invalidIrrigationDays =
      this.waterTop.some((w) => w.title == 'Irrigation Type') &&
      siteWater.IrrigationDays == 'I' &&
      (siteWater.WaterIntervalDays == null || siteWater.WaterIntervalFromDayNumber == null);

    const updatedSoilTopCount = updatedSoilTopNames.length;
    const updatedCropTopCount = updatedCropTopNames.length;
    const updatedWaterTopCount = updatedWaterTopNames.length;
    const anyUpdatedTopItems = !!updatedSoilTopCount || !!updatedCropTopCount || !!updatedWaterTopCount;

    if (anyUpdatedTopItems && this.effectiveFrom == null) {
      const effectiveFromControl = this.settingsTopPanelForm.effectiveFrom as angular.IFormController;

      effectiveFromControl.$setDirty();
      this._languageService.error('AC.GROUP.FROM_DATE_MISSING');

      return;
    }

    if (invalidIrrigationDays) {
      this._languageService.error('AC.GROUP.INTERVAL_MISSING');

      return;
    }

    if (
      siteWater.IrrigationDays === 'D' &&
      !(
        siteWater.WaterMonday ||
        siteWater.WaterTuesday ||
        siteWater.WaterWednesday ||
        siteWater.WaterThursday ||
        siteWater.WaterFriday ||
        siteWater.WaterSaturday ||
        siteWater.WaterSunday
      )
    ) {
      this._languageService.error('AC.SETTINGS.MUST_SELECT_DAY');
      return;
    }

    if (anyUpdatedTopItems) {
      const getHtmlTable = () => {
        const r = [];
        let j = -1;

        r[++j] = '<table class="gridtable"><tr>';

        if (updatedSoilTopCount) {
          r[++j] = `<td colspan="2">${this._languageService.instant('COMMON.SOIL')}</td>`;
        }

        if (updatedCropTopCount) {
          r[++j] = `<td colspan="2">${this._languageService.instant('COMMON.CROP')}</td>`;
        }

        if (updatedWaterTopCount) {
          r[++j] = `<td colspan="2">${this._languageService.instant('COMMON.WATER')}</td>`;
        }

        r[++j] = '</tr>';

        const maxCount = ArrayUtils.max([updatedSoilTopCount, updatedWaterTopCount, updatedCropTopCount]);
        const table = [
          updatedSoilTopNames,
          updatedCropTopNames,
          updatedWaterTopNames,
        ].filter(x => x.length);

        for (let key = 0, size = maxCount; key < size; key++) {
          r[++j] = '<tr>';

          table.forEach((names) => {
            // makes 2 cells for soil
            if (names[key]) {
              r[++j] = `<td>${names[key][0]}</td><td>${names[key][1] ?? ''}</td>`;
            } else {
              // empty hidden cells
              r[++j] = '<td class="tableCellWithOutBorder"></td><td class="tableCellWithOutBorder"></td>';
            }
          });

          r[++j] = '</tr>';
        }

        r[++j] = '</table><br/>';

        return r.join('');
      };

      const htmlTable = getHtmlTable();

      // Appending dialog to document.body to cover sidenav in docs app
      const confirm = this._mdDialog
        .confirm()
        .title(this._languageService.instant('AC.GROUP.APPLY_TITLE'))
        .htmlContent(htmlTable + this._languageService.instant('AC.GROUP.APPLY_CONTENT'))
        .ariaLabel(this._languageService.instant('AC.GROUP.APPLY_TIP'))
        .ok(this._languageService.instant('COMMON.CONFIRM'))
        .cancel(this._languageService.instant('COMMON.CANCEL'));

      this._mdDialog.show(confirm).then(
        () => {
          this.saveChangesToDB();
          this.setComponentDefaults();
        },
        () => {},
      );
    } else {
      this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
        () => {
          this.saveCounter++;
          this._languageService.showSaveSuccess();
          this.setComponentDefaults();

          if (super.executeAnyPostSaveActions(postSaveActions)) {
            return;
          }
        },
        (error) => {
          this._dupeHandlerService.showError(error);
        },
      );
    }
  }

  public displayWaterSetting(water: updateOpt) {
    if (!this._groupSettingService?.siteWater) {
      return;
    }

    let val = this._groupSettingService.siteWater[water.model] as any;

    if (water.irrigOpt) {
      val = this._languageService.instant('COMMON.' + val);
    } else {
      if (water.title == 'Irrigation Type') {
        val = this._groupSettingService.translateDaysInterval(val);
      } else {
        val = water.unit.fromBaseRounded(val, water.decimal)
      };
    }

    return val;
  }

  public displayCropSetting(crop: updateOpt) {
    if (!this._groupSettingService?.siteCrop) {
      return;
    }

    const tolls = ['Wet Soil', 'Dry Soil', 'Low Temp', 'High Temp'];
    const val = this._groupSettingService.siteCrop[crop.model];

    switch (crop.title) {
      case 'Crop':
        return this._groupSettingService.siteCrop?.Crop?.Name;
      case 'Slope':
        return val == 'R' ? 'R' : 'F';
    }

    if (tolls.some((t) => t == crop.title)) {
      return this._groupSettingService.translateTolerance(val);
    }

    return val;
  }

  private checkSiteSoilSetting(siteName: string, soilSetting: SiteSettingsSoil): string {
    let result = '';

    if (
      soilSetting.WiltingPoint_pct > soilSetting.FieldCapacity_pct ||
      soilSetting.FieldCapacity_pct > soilSetting.Saturation_pct
    ) {
      result += this._languageService.instant('AC.GROUP.SOIL_ERROR', { site: siteName }) + '\n';
    }
    if (soilSetting.FieldCapacity_pct > soilSetting.Saturation_pct) {
      result += result += this._languageService.instant('AC.GROUP.TARGET_ERROR', { site: siteName }) + '\n';
    }
    return result;
  }

  private checkSiteWaterBudgetSetting(siteName: string, waterBudgetSetting: SiteSettingsWater): string {
    let result = '';
    if (waterBudgetSetting.WaterBudgetLowerTarget_percent > waterBudgetSetting.WaterBudgetUpperTarget_percent) {
      result += result += this._languageService.instant('AC.GROUP.BUDGET_ERROR', { site: siteName }) + '\n';
    }
    return result;
  }

  private saveChangesToDB() {
    // what is the date?
    const effectiveFrom: Date = new Date(this.effectiveFrom);
    const strDate: string = effectiveFrom.toString('yyyy-MM-dd');
    const promises = [];

    let checkStatus = true;
    let waterAreaConfirm = '';

    // apply group settings changes to each site
    for (let jdx = 0; jdx < this.imuSites.length; jdx++) {
      const imu = this.imuSites[jdx];

      if (this.selectedIMUSiteIds.includes(imu.id)) {
        // get information about each site - AssetID is IMU id
        const sitesPromise = breeze.EntityQuery.from('getSites')
          .withParameters({ AssetId: imu.id })
          .expand(['SoilSettings', 'CropSettings', 'WaterSettings'])
          .using(this.entityManager)
          .execute();

        promises.push(sitesPromise);

        sitesPromise.then(
          (data) => {
            const site: Site = data.results[0] as Site;

            // Necessary to clear erroneous objects out of breeze if a previous save failed due to WorkingDepthException.
            site.SoilSettings.filter((s) => s.Id < 0).forEach(
              (s) => (s.entityAspect.entityState = breeze.EntityState.Detached),
            );

            // Sort 'SoilSettings' by date to support search of the effective record.
            // NOTE: Due to Breeze's entity tracking mechanism, using the 'WithMutation' helper to preserve array reference.
            site.SoilSettings = ArrayUtils.sortByNumberWithMutation(site.SoilSettings, x => x.DayNumber);

            let sIdx: number = this._groupSettingService.findEffective(effectiveFrom, site.SoilSettings);
            let newSoil = site.SoilSettings[sIdx].localDate.toString('yyyy-MM-dd') != strDate;
            let sObj: SiteSettingsSoil = site.SoilSettings[sIdx];

            for (let idx = 0; idx < this.soilTop.length; idx++) {
              if (this.soilTop[idx].update) {
                if (newSoil) {
                  sObj = this.addNewSetting('SoilSettings', site.SoilSettings, sIdx) as SiteSettingsSoil;
                  sObj.localDate = new Date(effectiveFrom.valueOf());
                  sIdx = site.SoilSettings.push(sObj);
                  newSoil = false;
                }

                // There is only one CMU settings record, so use the first.
                sObj[this.soilTop[idx].field] = this.groupSite.SoilSettings[0][this.soilTop[idx].field];
              }
            }

            const checkSoilResult = this.checkSiteSoilSetting(site.Asset.Name, sObj);

            if (checkSoilResult != '') {
              this._languageService.error(checkSoilResult);
              checkStatus = false;
            }

            // Sort 'CropSettings' by date to support search of the effective record.
            // NOTE: Due to Breeze's entity tracking mechanism, using the 'WithMutation' helper to preserve array reference.
            site.CropSettings = ArrayUtils.sortByNumberWithMutation(site.CropSettings, x => x.DayNumber);

            let cIdx: number = this._groupSettingService.findEffective(effectiveFrom, site.CropSettings);
            let newCrop = site.CropSettings[cIdx].localDate.toString('yyyy-MM-dd') != strDate;
            let cObj: SiteSettingsCrop = site.CropSettings[cIdx];

            for (let idx = 0; idx < this.cropTop.length; idx++) {
              if (this.cropTop[idx].update) {
                if (newCrop) {
                  cObj = this.addNewSetting('CropSettings', site.CropSettings, cIdx) as SiteSettingsCrop;
                  cObj.localDate = new Date(effectiveFrom.valueOf());
                  cIdx = site.CropSettings.push(cObj);
                  newCrop = false;
                }

                // There is only one CMU settings record, so use the first.
                cObj[this.cropTop[idx].field] = this.groupSite.CropSettings[0][this.cropTop[idx].field];
              }
            }

            // Sort 'WaterSettings' by date to support search of the effective record.
            // NOTE: Due to Breeze's entity tracking mechanism, using the 'WithMutation' helper to preserve array reference.
            site.WaterSettings = ArrayUtils.sortByNumberWithMutation(site.WaterSettings, x => x.DayNumber);

            let wIdx: number = this._groupSettingService.findEffective(effectiveFrom, site.WaterSettings);
            let newWater = site.WaterSettings[wIdx].localDate.toString('yyyy-MM-dd') != strDate;
            let wObj: SiteSettingsWater = site.WaterSettings[wIdx];

            for (let idx = 0; idx < this.waterTop.length; idx++) {
              if (this.waterTop[idx].update) {
                if (newWater) {
                  wObj = this.addNewSetting('WaterSettings', site.WaterSettings, wIdx) as SiteSettingsWater;
                  //wObj.CropWettedArea_perc = 100;
                  wObj.localDate = new Date(effectiveFrom.valueOf());
                  wIdx = site.WaterSettings.push(wObj);
                  newWater = false;
                }

                // There is only one CMU settings record, so use the first.
                wObj[this.waterTop[idx].field] = this.groupSite.WaterSettings[0][this.waterTop[idx].field];

                if (this.waterTop[idx].title == 'Area') {
                  if (wObj.CropWaterArea > site.Area) {
                    waterAreaConfirm +=
                      this._languageService.instant('AC.GROUP.AREA_ERROR', {
                        cropArea: wObj.CropWaterArea,
                        siteArea: (site.Area ? site.Area : 0).toFixed(3),
                        site: site.Asset.Name,
                      }) + '<br/>';
                  }
                }
              }
            }

            const checkWaterBudgetResult = this.checkSiteWaterBudgetSetting(site.Asset.Name, wObj);

            if (checkWaterBudgetResult != '') {
              this._languageService.error(checkWaterBudgetResult);
              checkStatus = false;
            }
          },
          (error) => {
            console.error('Error: Could not load Site entity (' + error.message + ')');
          },
        );
      }
    }

    this._q.all(promises).then(() => {
      // Do NOT save GroupSettings
      if (checkStatus == false) {
        return null;
      }

      if (waterAreaConfirm != '') {
        const confirm = this._mdDialog
          .confirm()
          .title(this._languageService.instant('COMMON.CONFIRM'))
          .htmlContent(waterAreaConfirm)
          .ok(this._languageService.instant('COMMON.YES'))
          .cancel(this._languageService.instant('COMMON.NO'));

        promises.push(this._mdDialog.show(confirm));
      }

      this._q.all(promises).then(
        () => {
          this._dataEntityService.saveChanges(true, this._dupeHandlerService.duplicatesOnly).then(
            () => {
              this._languageService.showSaveSuccess();
              this.resetCheckBoxes();
            },
            (saveFailed) => {
              this._dupeHandlerService.showError(saveFailed);
            },
          );
        },
        () => {
          this._languageService.info('COMMON.CHANGES_CANCELLED');
        },
      );
    });
  }

  public onPrimaryTabSelected(): void {
    switch (this.primaryTab) {
      case GroupPrimaryTabEnums.WaterReports: {
        this.assStateCur = '';
        break;
      }
    }
  }

  public changeWaterUnits() {
    this.isReportBtnEnabled = true;
    this.isWaterCsvEnabled = false;
  }

  private downloadCSV() {
    this._languageService.info('AC.GROUP.DOWNLOAD_WARN');

    this._localStorageService.set('dashboardParams', this.dashboardParams);
    const defer = this._q.defer();

    const data = {
      dfr: this.waterReportFromDate.toString('yyyy-MM-dd'),
      dto: this.waterReportToDate.toString('yyyy-MM-dd'),
      groupId: this.groupId,
      period: 'day',
    };
    //Save dates now just in case user changes dates while report generating
    this.setDateSuffix();

    this.isReportBtnEnabled = false;
    this._http
      .get(CommonHelper.getApiUrl('user/getDailySummaryReportCSV'), { params: data })
      .then((result) => {
        defer.resolve(result.data);
      })
      .catch((error) => {
        defer.reject(error);
      });

    /*
      var file = new Blob([defer.promise] as BlobPart[], { type: 'application/csv' } as BlobPropertyBag);
      saveAs(file, 'Crop_CSVTemplate.csv');
    */

    defer.promise.then((result) => {
      const file = new Blob([result] as BlobPart[], { type: 'application/csv' } as BlobPropertyBag);
      saveAs(file, `${this.group.Name}_DataExtract_${this.dateSuffix}.csv`);
    });
  }

  private resetCheckBoxes(): void {
    const updateOptions = [this.soilTop, this.cropTop, this.waterTop];

    updateOptions.forEach((itemOptions) => {
      itemOptions.forEach((x) => {
        if (x.update) {
          x.update = false;
        }
      });
    });
  };

  private setDateSuffix() {
    this.dateSuffix = `${this.waterReportFromDate.toString('yyyyMMdd')}_to_${this.waterReportToDate.toString('yyyyMMdd')}`;
  }

  private applyUnitsToRunTimeData() {
    this.runTimeData.forEach((site) => {
      site.area = this.areaUnit.fromBase(site.area);
      site.periods.forEach((period) => {
        (period.Irrigation_mm = this.fluidDepthUnit.fromBase(period.Irrigation_mm)),
          (period.IrrigationPlanned_mm = this.fluidDepthUnit.fromBase(period.IrrigationPlanned_mm));
        period.Drainage = this.volUnit.fromBase(period.Drainage);
        period.Runoff = this.volUnit.fromBase(period.Runoff);
        period.WaterUse.budget = this.volUnit.fromBase(period.WaterUse.budget);
        period.WaterUse.actual = this.volUnit.fromBase(period.WaterUse.actual);
        period.WaterUse.planned = this.volUnit.fromBase(period.WaterUse.planned);
        period.WaterUse.budgetRunning = this.volUnit.fromBase(period.WaterUse.budgetRunning);
        period.WaterUse.actualRunning = this.volUnit.fromBase(period.WaterUse.actualRunning);
      });
    });
  }

  private getWaterReports() {
    const siteDailyTable: SiteDailyTable[] = [];

    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.setDateSuffix();

    if (this.assStateCur == this.AssetStatesList.DataExtract) {
      this.downloadCSV();
      return;
    }

    const data = {
      dfr: this.waterReportFromDate.toString('yyyy-MM-dd'),
      dto: this.waterReportToDate.toString('yyyy-MM-dd'),
      groupId: this.groupId,
      period: 'day',
    };

    let totalArea = 0;
    this.isReportBtnEnabled = true;

    if (this.assStateCur === this.AssetStatesList.WaterApplicationEfficency) {
      this.getWaterApplicationEfficiency();
      return;
    }

    if (this.assStateCur === this.AssetStatesList.WaterProductivityEfficiency) {
      this.getWaterProductivityEfficiency();
      return;
    }

    this._http.get(CommonHelper.getApiUrl('user/getDailySummaryReport'), { params: data }).then((response) => {
      // Object with daily data organised by site - this.runTimeData[SiteName][DayNumber]
      this.runTimeData = response.data as fuse.siteDailySummaryPacket[];

      // This data is used in html tables, graphs and various csv reports, so just converting everything upfront for simplicity
      // - unless we're on the water report, which may need to calculate rate-per-area first
      if (this.assStateCur != this.AssetStatesList.WaterUsage) this.applyUnitsToRunTimeData();

      this.runTimeData.forEach((site) => {
        totalArea += site.area;
        site.periods.forEach((period) => {
          period.RunTime = ConversionUtils.convertToHHMM(period.Irrigation_hrs);
          period.Status = this.getSMstatus(period.SMcounts.low, period.SMcounts.ok, period.SMcounts.high, 'text');
          // Reorganised daily data for csv export, flattened into one table with SiteName in first column
          siteDailyTable.push({
            id: site.id,
            name: site.name,
            area: site.area,
            date: period.dto,
            applied_mm: period.Irrigation_mm,
            planned_mm: period.IrrigationPlanned_mm,
            planned_volume: period.WaterUse.planned,
            run_time: ConversionUtils.convertToHHMM(period.Irrigation_hrs),
            planned_runtime: ConversionUtils.convertToHHMM(period.IrrigationPlanned_hrs),
            sm_status: this.getSMstatus(period.SMcounts.low, period.SMcounts.ok, period.SMcounts.high, 'text'),
            sm_per: period.SoilMoisture_pct,
            crop: period.Crop,
            crop_coefficient: period.CropCoefficient,
            drainage: period.Drainage,
            runoff: period.Runoff,
            budget: period.WaterUse.budget,
            actual: period.WaterUse.actual,
            budget_running: period.WaterUse.budgetRunning,
            actual_running: period.WaterUse.actualRunning,
            projected_running: period.WaterUse.projectedRunning,
            low: period.SMcounts.low,
            ok: period.SMcounts.ok,
            high: period.SMcounts.high,
          });
        });
      });

      //main array that holds all the data from date - to date
      this.groupArea = totalArea;

      switch (this.assStateCur) {
        case this.AssetStatesList.IrrigationSchedule:
          this.irrigationScheduleReport(siteDailyTable);
          break;
        case this.AssetStatesList.SoilMoisture:
          this.soilMoistureReport(siteDailyTable);
          break;
        case this.AssetStatesList.WaterUsage:
          this.waterUsageReport(siteDailyTable);
          break;
      }
    })
    .catch((err) => {
      console.log('Error');
    })
    .finally(() => {
      if (this.assStateCur == 'Water Usage') {
        this.loadWaterUsageChart();
        this.obsChart.validateData();
      }
      if (this.assStateCur == 'Soil Moisture Status') {
        this.loadSoilMoistureChart();
        this.soilChart.validateData();
      }
      this.isReportBtnEnabled = false;
    });
  }

  public irrigationScheduleReport(siteDailyTable) {
    this.irrigationScheduleCsv = [];
    // Main html display uses runTimeData (organised by site), whereas siteDailyTable used for csv export (site name in every row)
    siteDailyTable.forEach((item) => {
      this.irrigationScheduleCsv.push({
        id: item.id,
        name: item.name,
        date: item.date,
        planned_mm: item.planned_mm,
        planned_runtime: item.planned_runtime,
        planned_volume: item.planned_volume,
        applied_mm: item.applied_mm,
        run_time: item.run_time,
        volume: item.actual,
      });
    });
    this.irrigationScheduleData = siteDailyTable;
    this.irrigationScheduleCsvHdr = [
      this._languageService.instant('AC.GROUP.CSV.SITE_ID'),
      this._languageService.instant('COMMON.NAME'),
      this._languageService.instant('COMMON.DATE'),
      this._languageService.instant('AC.GROUP.CSV.PLANNED_IRRIGATION') + ` (${this.fluidDepthUnit.name})`,
      this._languageService.instant('AC.GROUP.CSV.PLANNED_RUNTIME'),
      this._languageService.instant('AC.GROUP.CSV.PLANNED_VOL') + ` (${this.volUnit.name})`,
      this._languageService.instant('AC.GROUP.CSV.APPLIED_IRRIGATION') + ` (${this.fluidDepthUnit.name})`,
      this._languageService.instant('AC.GROUP.CSV.APPLIED_RUNTIME'),
      this._languageService.instant('AC.GROUP.CSV.APPLIED_VOL') + ` (${this.volUnit.name})`,
    ];
  }

  public soilMoistureReport(siteDailyTable) {
    //do soil moisture stuff

    this.soilMoistureDataCSVhdr = ['NAME', 'LOW', 'OK', 'HIGH'].map((a) => this._languageService.instant('COMMON.' + a));

    const result = [];
    siteDailyTable.reduce((res, value) => {
      if (!res[value.name]) {
        res[value.name] = {
          name: value.name,
          low: 0,
          ok: 0,
          high: 0,
          thecount: 0,
        };
        result.push(res[value.name]);
      }
      res[value.name].low += value.low;
      res[value.name].ok += value.ok;
      res[value.name].high += value.high;
      res[value.name].thecount = 0;
      return res;
    }, {});

    const cresult = [];
    for (let c = 0; c < result.length; c++) {
      const ic = result[c].low + result[c].ok + result[c].high;
      cresult.push({
        name: result[c].name,
        low: (result[c].low / ic) * 100,
        ok: (result[c].ok / ic) * 100,
        high: (result[c].high / ic) * 100,
      });
    }

    this.soilMoistureData = cresult;
    // End Soil Moisture Report Calcs
  }

  public waterUsageReport(siteDailyTable: SiteDailyTable[]) {
    // Organises water data as: this.waterChartData -         total (over all sites) usage by date (for chart)
    //                          this.waterChartSummaryData -  total (over all dates) by site
    //                         this.waterTableSummaryTotals - total (over all sites and dates)
    //                         this.waterChartSummaryForCsv - this.waterChartSummaryData + this.waterTableSummaryTotals[0]

    const waterSums = [];
    const dayNum = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    const current = this._dayNumberService.convertDayNumberToYMD(dayNum);

    // Water usage may be shown as vol or vol/area
    this.waterUsageUnit = this.largeVolUnit;
    this.waterRunningUsageUnit = this.hugeVolUnit;
    let div = 1;
    if (this.dashboardParams.waterUsageAsRate) {
      this.waterUsageUnit = this.largeVolAreaUnit;
      this.waterRunningUsageUnit = this.hugeVolAreaUnit;
      div = this.groupArea;
    }

    //get run time csv extracts
    siteDailyTable.forEach((item) => {
      waterSums.push({
        id: item.id,
        name: item.name,
        date: item.date,
        area: item.area,
        actual: item.actual,
        budget: item.budget,
        variance: this.replaceNanToZero(item.actual - item.budget),
        variance_perc: this.replaceNanToZero((item.actual - item.budget) / item.budget),
        actual_running: item.actual_running,
        budget_running: item.budget_running,
        projected_running: item.projected_running,
        variance_running: this.replaceNanToZero(item.actual_running - item.budget_running),
        variance_running_perc: this.replaceNanToZero((item.actual_running - item.budget_running) / item.budget_running),
        yr_actual_running: item.actual_running,
        yr_budget_running: item.budget_running,
      });
    });

    //WATER USAGE (Chart) - group by date (Sum Data)
    const totalsByDate = [];
    waterSums.reduce((res, value) => {
      if (!res[value.date]) {
        res[value.date] = {
          date: value.date,
          budget: 0,
          actual: 0,
          budget_running: 0,
          actual_running: 0,
          projected_running: 0,
        };
        totalsByDate.push(res[value.date]);
      }
      res[value.date].budget += value.budget;
      res[value.date].budget_running += value.budget_running;
      if (value.projected_running === null) {
        res[value.date].projected_running = null;
      } else {
        res[value.date].projected_running += value.projected_running;
      }
      if (current <= value.date) {
        res.actual_running = null;
        res.actual = null;
      } else {
        res[value.date].actual_running += value.actual_running;
        res[value.date].actual += value.actual;
      }
      return res;
    }, {});
    this.waterChartData = angular.copy(totalsByDate);
    this.waterChartData.forEach((res) => {
      res.budget = this.waterUsageUnit.fromBase(res.budget / div);
      res.budget_running = this.waterRunningUsageUnit.fromBase(res.budget_running / div);
      // the graph
      if (res.projected_running === null) {
        res.projected_running = null;
      } else {
        res.projected_running = this.waterRunningUsageUnit.fromBase(res.projected_running / div);
      }
      if (current <= res.date) {
        res.actual_running = null;
        res.actual = null;
      } else {
        res.actual = this.waterUsageUnit.fromBase(res.actual / div);
        res.actual_running = this.waterRunningUsageUnit.fromBase(res.actual_running / div);
      }
    });
    // Divide by area (if necessary) and apply unit prefs
    totalsByDate.forEach((res) => {
      res.budget = this.waterUsageUnit.fromBase(res.budget / div);
      res.budget_running = this.waterUsageUnit.fromBase(res.budget_running / div);
      // the graph
      if (res.projected_running === null) {
        res.projected_running = null;
      } else {
        res.projected_running = this.waterUsageUnit.fromBase(res.projected_running / div);
      }
      if (current <= res.date) {
        res.actual_running = null;
        res.actual = null;
      } else {
        res.actual_running = this.waterUsageUnit.fromBase(res.actual_running / div);
        res.actual = this.waterUsageUnit.fromBase(res.actual / div);
      }
    });

    //do water usage summary - group by site
    const summaryBySite = [];
    waterSums.reduce((res, value) => {
      if (!res[value.id]) {
        res[value.id] = {
          name: value.name,
          area: 0,
          actual: 0,
          budget: 0,
          variance: 0,
          variance_perc: 0,
          actual_running: 0,
          budget_running: 0,
          variance_running: 0,
          variance_running_perc: 0,
        };
        summaryBySite.push(res[value.id]);
      }
      res[value.id].budget += value.budget;
      res[value.id].actual += value.actual;
      res[value.id].variance += value.variance;
      res[value.id].budget_running = value.budget_running;
      res[value.id].actual_running = value.actual_running;
      res[value.id].variance_running = value.variance_running;
      res[value.id].area = value.area;
      return res;
    }, {});

    // Get the total of the sites - GROUP TOTAL (maybe useful)
    let tbudget = 0;
    let tactual = 0;
    let tbudget_running = 0;
    let tactual_running = 0;
    summaryBySite.forEach((item) => {
      tbudget += item.budget;
      tactual += item.actual;
      tbudget_running += item.budget_running;
      tactual_running += item.actual_running;
      item.variance_perc = ((item.actual - item.budget) / item.budget) * 100;
      item.variance_running_perc = ((item.actual_running - item.budget_running) / item.budget_running) * 100;
    });
    const totalresult = [
      {
        name: this._languageService.instant('COMMON.TOTAL'),
        area: this.areaUnit.fromBase(this.groupArea),
        actual: this.waterUsageUnit.fromBase(tactual / div),
        budget: this.waterUsageUnit.fromBase(tbudget / div),
        variance: this.waterUsageUnit.fromBase((tactual - tbudget) / div),
        variance_perc:
          tbudget == 0 ? this._languageService.instant('COMMON.NO_BUDGET') : NumberUtils.round(((tactual - tbudget) / tbudget) * 100, 2),
        actual_running: this.waterUsageUnit.fromBase(tactual_running / div),
        budget_running: this.waterUsageUnit.fromBase(tbudget_running / div),
        variance_running: this.waterUsageUnit.fromBase((tactual_running - tbudget_running) / div),
        variance_running_perc:
          tbudget_running == 0
            ? this._languageService.instant('COMMON.NO_BUDGET')
            : NumberUtils.round(((tactual_running - tbudget_running) / tbudget_running) * 100, 2),
      },
    ];
    this.waterTableSummaryTotals = totalresult;

    //Divide site summaries by site area after calculating totals, so we aren't adding ML/ha values for diff sites together
    summaryBySite.forEach((res) => {
      if (this.dashboardParams.waterUsageAsRate) div = res.area;

      res.area = this.areaUnit.fromBase(res.area);
      res.budget = this.waterUsageUnit.fromBase(res.budget / div);
      res.actual = this.waterUsageUnit.fromBase(res.actual / div);
      res.budget_running = this.waterUsageUnit.fromBase(res.budget_running / div);
      res.actual_running = this.waterUsageUnit.fromBase(res.actual_running / div);
      res.variance = res.actual - res.budget;
      res.variance_running = res.actual_running - res.budget_running;
      res.variance_perc =
        res.active == 0
          ? 0
          : res.budget == 0
            ? this._languageService.instant('COMMON.NO_BUDGET')
            : NumberUtils.round((res.variance / res.budget) * 100, 2);
      res.variance_running_perc =
        res.actual_running == 0
          ? 0
          : res.budget_running == 0
            ? this._languageService.instant('COMMON.NO_BUDGET')
            : NumberUtils.round((res.variance_running / res.budget_running) * 100, 2);
    });
    this.waterChartSummaryData = summaryBySite;
    const dateStr =
      `${DateUtils.Locale.asDateMedium(this.waterReportFromDate)} ${this._languageService.instant('COMMON.TO')} ${DateUtils.Locale.asDateMedium(this.waterReportToDate)}`;
    const seasonStr = this._languageService.instant('AC.GROUP.CSV.THIS_SEASON');

    this.waterChartSummaryHdr = [
      this._languageService.instant('COMMON.NAME'),
      this._languageService.instant('COMMON.AREA') + ` (${this.areaUnit.name})`,
      this._languageService.instant('COMMON.ACTUAL') + ` (${this.waterUsageUnit.name}) ${dateStr}`,
      this._languageService.instant('COMMON.BUDGET') + ` (${this.waterUsageUnit.name}) ${dateStr}`,
      this._languageService.instant('COMMON.DIFFERENCE') + ` (${this.waterUsageUnit.name}) ${dateStr}`,
      '% ' + this._languageService.instant('COMMON.DIFFERENCE') + ` ${dateStr}`,
      this._languageService.instant('COMMON.RUNNING_ACTUAL') + ` (${this.waterUsageUnit.name}) ${seasonStr}`,
      this._languageService.instant('COMMON.RUNNING_BUDGET') + ` (${this.waterUsageUnit.name}) ${seasonStr}`,
      this._languageService.instant('COMMON.RUNNING_DIFFERENCE') + ` (${this.waterUsageUnit.name}) ${seasonStr}`,
      '% ' + this._languageService.instant('COMMON.RUNNING_DIFFERENCE') + ` ${seasonStr}`,
    ];
    this.waterChartSummaryForCsv = this.waterChartSummaryData.map((a) => a); //copy for csv with totals row
    this.waterChartSummaryForCsv.push(this.waterTableSummaryTotals[0]);

    this.groupArea = this.areaUnit.fromBase(this.groupArea);
    // End Water Usage
  }

  public rolloverKc() {
    this._mdDialog
      .show({
        controller: GroupKcRolloverDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/views/group/settingsTabs/crop/group-kcRollover-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        escapeToClose: true,
        locals: {
          siteIds: this.groupSite.Asset.ChildAssets.filter((a) => a.ChildAsset.Status == 'Active').map((s) => s.ChildAssetId),
        },
      })
      .then((returnData) => {
        if (returnData) {
          const siteNames = this.groupSite.Asset.ChildAssets.filter((a) => a.ChildAsset.Status == 'Active');
          const settings = returnData as fuse.siteSettingsRolloverDto;
          const message =
            this._languageService.instant('AC.GROUP.ROLLOVER1', { num: siteNames.length }) +
            (settings.rolloutWorkingDepth ? '/' + this._languageService.instant('COMMON.SOIL') : '') +
            this._languageService.instant('AC.GROUP.ROLLOVER2', {
              from: DateUtils.Locale.asDateDefault(settings.dateFrom),
              to: DateUtils.Locale.asDateDefault(settings.dateTo),
              year: settings.targetYear.toString(),
            }) +
            (settings.rolloutWorkingDepth ? this._languageService.instant('AC.GROUP.ROLLOVER3') : '') +
            this._languageService.instant('AC.GROUP.ROLLOVER4');
          const confirm = this._mdDialog
            .confirm()
            .title(this._languageService.instant('COMMON.CONFIRM'))
            .htmlContent(message)
            .parent(angular.element(document.body))
            .ok(this._languageService.instant('COMMON.ROLLOVER'))
            .cancel(this._languageService.instant('COMMON.CANCEL'));

          this._mdDialog.show(confirm).then(() => {
            this.groupSiteService
              .RolloverSiteKcSettings(settings)
              .then((result) => {
                this._languageService.success('AC.GROUP.ROLLOVER_SUCCESS');
              })
              .catch((e) => {
                this._languageService.handleError(e);
              });
          });
        }
      });
  }

  public groupNameChanged() {
    this.isExistingGroupName = false;

    if (this.group.Name) {
      this.isExistingGroupName = this.cmus2.some(
        (a) => a.AssetId != this.group.AssetId && a.Name.trim().toLowerCase() == this.group.Name.trim().toLowerCase(),
      );
    }
  }

  private setComponentDefaults(): void {
    this.canRevertChanges = false;
    this.hasDataChanges = false;
    this.effectiveFrom = null;
    this.isExistingGroupName = false;
    this.summaryForm.$setPristine();
    (this.settingsTopPanelForm.effectiveFrom as angular.IFormController).$setPristine();
    this.settingsSoilForm.$setPristine();
    this.settingsCropForm.$setPristine();
    this.settingsWaterForm.$setPristine();
  }

  private getAccountDetail(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };

    this._http.get(CommonHelper.getApiUrl('group/getAccountInfo'), { params: params }).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          this.groupAccountInfo = response.data as fuse.groupAccountInfoDto;
          const currentDate = new Date();
          this.irrigationSeasonStart = new Date(
            this.groupAccountInfo.irrigationSeasonStartMonth - 1 > currentDate.getMonth()
              ? currentDate.getFullYear() - 1
              : currentDate.getFullYear(),
            this.groupAccountInfo.irrigationSeasonStartMonth - 1,
            1,
          );
        }
        defer.resolve();
      },
      () => {
        defer.reject();
      },
    );
    return defer.promise;
  }

  private earliest(date1: Date, date2: Date) {
    if (date1 < date2) {
      return new Date(date1.getTime());
    } else {
      return new Date(date2.getTime());
    }
  }
}

angular.module('app.account').controller('GroupController', GroupController);

