// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infra-flow-chart {
  width: 100%;
  height: 460px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/equipment/components/infra-flow-chart.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;AACJ","sourcesContent":["#infra-flow-chart {\r\n    width: 100%;\r\n    height: 460px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
