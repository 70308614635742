import * as angular from 'angular';
import { UnitTypes, unitSizes } from '@common/enums';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';

class InfraDepthTableComponent implements angular.IComponentOptions {
  bindings = {
    isEditMode: '<',
    obsInfraDepths: '<',
    depthMeterBoundaries: '<',
    fromDate: '<',
    toDate: '<',
    reloadCount: '<',
    onChange: '&',
  };
  controller = InfraDepthTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/equipment/components/infra-depth-table.html';
}

class InfraDepthTableController extends BaseController {
  private _timeout: angular.ITimeoutService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public obsInfraDepths: (fuse.obsInfraWaterDepthDto & { filterDates: (date: Date) => boolean })[];
  public fromDate: Date;
  public toDate: Date;
  public volumeNormalUnit: uomUnit;
  public elevationSmallUnit: uomUnit;
  public obsForm: angular.IFormController;

  private onChange: ({ isValid }) => void;
  public isEditMode: boolean;
  public maxDate: Date;

  private popupNotOpen: boolean = true;
  public depthMeterBoundaries: fuse.depthMeterBoundaryValuesDto[];

  public displayDepthMax: number = null;
  public displayDepthMin: number = 0;
  public displayVolumeMax: number = null;
  public displayVolumeMin: number = 0;

  constructor(
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._timeout = $timeout;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.volumeNormalUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume);
    this.elevationSmallUnit = this._unitOfMeasureService.getUnits(UnitTypes.Elevation, unitSizes.small);
  }

  $onChanges() {
    if (this.toDate) {
      const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

      if (adjustedToday.getTime() < this.toDate.getTime()) {
        this.maxDate = adjustedToday.clone();
      } else {
        this.maxDate = this.toDate.clone();
      }

      this.obsInfraDepths.forEach((a, idx) => {
        // The validated ObsInfraDepth's row must be excluded from the dates validation.
        a.filterDates = this.filterDates(idx);
      });
    }
  }

  public changeObs(obs: fuse.obsInfraWaterDepthDto, index: number, type: string) {
    obs.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(obs.date);
    if (type == 'volume') {
      if (obs.depthM != null && this.popupNotOpen) {
        this.popupNotOpen = false;
        const confirm = this._languageService
          .confirm()
          .title('COMMON.CONFIRM')
          .htmlContent('AC.EQUIP.CONFIRM_OBS_VOLUME')
          .ok('COMMON.OK')
          .cancel('COMMON.CANCEL');
        this._languageService
          .show(confirm)
          .then(
            (res) => {
              if (res) {
                obs.depthM = null;
              }
            },
            () => {
              obs.volumeKL = null;
            },
          )
          .finally(() => {
            this.popupNotOpen = true;
            this._timeout(() => {
              this.validateForm(index, type);
              this.onChange({ isValid: this.obsForm.$valid });
            }, 0);
          });
      } else {
        this._timeout(() => {
          this.validateForm(index, type);
          this.onChange({ isValid: this.obsForm.$valid });
        }, 0);
      }
    } else if (type == 'depth') {
      if (obs.volumeKL != null && this.popupNotOpen) {
        this.popupNotOpen = false;
        const confirm = this._languageService
          .confirm()
          .title('COMMON.CONFIRM')
          .htmlContent('AC.EQUIP.CONFIRM_OBS_DEPTH')
          .ok('COMMON.OK')
          .cancel('COMMON.CANCEL');
        this._languageService
          .show(confirm)
          .then(
            (res) => {
              if (res) {
                obs.volumeKL = null;
              }
            },
            () => {
              obs.depthM = null;
            },
          )
          .finally(() => {
            this.popupNotOpen = true;
            this._timeout(() => {
              this.validateForm(index, type);
              this.onChange({ isValid: this.obsForm.$valid });
            }, 0);
          });
      } else {
        this._timeout(() => {
          this.validateForm(index, type);
          this.onChange({ isValid: this.obsForm.$valid });
        }, 0);
      }
    } else {
      this.onChange({ isValid: this.obsForm.$valid });
    }
  }

  public toggleLock(obs: fuse.obsInfraWaterFlowDto) {
    obs.isLocked = !obs.isLocked;
    this.onChange({ isValid: this.obsForm.$valid });
  }

  public validateForm(index: number, type: string) {
    const volume = this.obsForm['volume' + index].$modelValue;
    const depth = this.obsForm['depth' + index].$modelValue;
    if (volume == null && depth == null) {
      if (type == 'volume') {
        this.obsForm['volume' + index].$setValidity('valid', false);
        this.obsForm['depth' + index].$setValidity('valid', true);
      } else if (type == 'depth') {
        this.obsForm['volume' + index].$setValidity('valid', true);
        this.obsForm['depth' + index].$setValidity('valid', false);
      }
    } else if (volume != null && depth != null) {
      if (type == 'volume') {
        this.obsForm['volume' + index].$setValidity('valid', false);
        this.obsForm['depth' + index].$setValidity('valid', true);
      } else if (type == 'depth') {
        this.obsForm['volume' + index].$setValidity('valid', true);
        this.obsForm['depth' + index].$setValidity('valid', false);
      }
    } else {
      this.obsForm['volume' + index].$setValidity('valid', true);
      this.obsForm['depth' + index].$setValidity('valid', true);
    }
  }

  public getDepthMax(dayNumber: number) {
    if (this.depthMeterBoundaries) {
      const bounds = this.depthMeterBoundaries.find(
        (a) =>
          (a.fromDayNumber === null || a.fromDayNumber <= dayNumber) && (a.toDayNumber === null || a.toDayNumber >= dayNumber),
      );
      if (bounds && bounds.depthMax) {
        this.displayDepthMax = this.elevationSmallUnit.fromBaseRounded(bounds.depthMax);
        return bounds.depthMax;
      }
    }
    return null;
  }

  public getDepthMin(dayNumber: number) {
    if (this.depthMeterBoundaries) {
      const bounds = this.depthMeterBoundaries.find(
        (a) =>
          (a.fromDayNumber === null || a.fromDayNumber <= dayNumber) && (a.toDayNumber === null || a.toDayNumber >= dayNumber),
      );
      if (bounds && bounds.depthMin) {
        this.displayDepthMin = this.elevationSmallUnit.fromBaseRounded(bounds.depthMin);
        return bounds.depthMin;
      }
    }
    return 0;
  }

  public getVolumeMax(dayNumber: number) {
    if (this.depthMeterBoundaries) {
      const bounds = this.depthMeterBoundaries.find(
        (a) =>
          (a.fromDayNumber === null || a.fromDayNumber <= dayNumber) && (a.toDayNumber === null || a.toDayNumber >= dayNumber),
      );
      if (bounds && bounds.volumeMax) {
        this.displayVolumeMax = this.volumeNormalUnit.fromBaseRounded(bounds.volumeMax);
        return bounds.volumeMax;
      }
    }
    return null;
  }

  public getVolumeMin(dayNumber: number) {
    if (this.depthMeterBoundaries) {
      const bounds = this.depthMeterBoundaries.find(
        (a) =>
          (a.fromDayNumber === null || a.fromDayNumber <= dayNumber) && (a.toDayNumber === null || a.toDayNumber >= dayNumber),
      );
      if (bounds && bounds.volumeMin) {
        this.displayVolumeMin = this.volumeNormalUnit.fromBaseRounded(bounds.volumeMin);
        return bounds.volumeMin;
      }
    }
    return 0;
  }

  private filterDates(exclusionIndex: number): (date: Date) => boolean {
    return (date: Date) => {
      return this.obsInfraDepths
        .filter((_a, idx) => idx !== exclusionIndex)
        .every((a) => a.date.getTime() !== date.getTime());
    };
  }
}

angular.module('app.account').component('infraDepthTable', new InfraDepthTableComponent());
