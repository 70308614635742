import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { EntityList } from '@common/EntityList';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { EquipmentService } from '@services/equipment.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class EquipmentListController extends BaseController {
  public filteredEquipments: fuse.equipmentProfileDto[];
  public entityList: EntityList<any>;

  private _state: angular.ui.IStateService;
  private _http: angular.IHttpService;
  private _dataEntityService: DataEntityService;
  private _equipmentService: EquipmentService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;

  constructor(
    $http: angular.IHttpService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    DataEntityService: DataEntityService,
    EquipmentService: EquipmentService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._state = $state;
    this._http = $http;
    this._dataEntityService = DataEntityService;
    this._equipmentService = EquipmentService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
  }

  $onInit() {
    this._localStorageService.initAccountList();

    this._dataEntityService.reset();
    this.getEquipments();
  }

  // Used by GroupsListComponent on changing parent group
  public loadGroupData() {
    this.getEquipments();
  }

  private getEquipments() {
    this._http.get(CommonHelper.getApiUrl('equipment/GetAccountEquipments')).then(
      (response) => {
        if (response.data == null) {
          this._languageService.whoops();
        } else {
          const equipments = response.data as fuse.equipmentProfileDto[];

          this.entityList = new EntityList(equipments, this._equipmentService);

          this.entityList.entities.forEach((entity) => {
            entity['displayClassName'] = this._languageService.instant(`DB_VALUES.ASSETCLASS.${entity.assetClassName}`);
            entity['linkField'] = entity.parentAssets.join(', ');
          });
        }
      },
      () => {
        this._languageService.whoops();
      },
    );
  }

  public activeStatusClass(status: string) {
    if (status === 'Active') {
      return 'icon-checkbox-marked-circle green-500-fg';
    }
    if (status === 'Suspended') {
      return 'icon-minus-circle orange-500-fg';
    }
    if (status === 'Archived') {
      return 'icon-cancel red-500-fg';
    }
  }

  public gotoEquipmentDetail(equip: fuse.equipmentProfileDto) {
    LocalStorageUtils.updateContextData((context) => {
      context.assetId = equip.assetId;
    });

    this._state.go('app.account.equipments.detail', { id: equip.assetId });
  }

  public saveFilters() {
    this._equipmentService.saveFilters(this.entityList.filters, this.entityList.statusFilters);
  }
}

angular.module('app.account').controller('EquipmentListController', EquipmentListController);
