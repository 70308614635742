import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { DayNumberService } from '@services/day-number.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { Sensor } from 'src/app/_DBContext/Sensor';
import { BaseController } from 'src/app/base.controller';

class InfraFlowTableComponent implements angular.IComponentOptions {
  bindings = {
    isEditMode: '<',
    equipment: '<',
    obsInfraFlows: '<',
    reloadCount: '<',
    fromDate: '<',
    toDate: '<',
    onChange: '&',
  };
  controller = InfraFlowTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/equipment/components/infra-flow-table.html';
}

class InfraFlowTableController extends BaseController {
  public obsInfraFlows: fuse.obsInfraWaterFlowDto[];
  public displayObsInfraFlows: (fuse.obsInfraWaterFlowDto & { filterDates?: (date: Date) => boolean })[];
  public equipment: Sensor;
  public obsForm: angular.IFormController;
  public fromDate: Date;
  public toDate: Date;
  public maxDate: Date;
  public onChange: ({ isValid }) => void;
  public volumeNormalUnit: uomUnit;
  public meterUnit: uomUnit;

  private _dayNumberService: DayNumberService;
  private _unitOfMeasureService: UnitOfMeasureService;

  constructor(
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._dayNumberService = DayNumberService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.volumeNormalUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.normal);
  }

  $onChanges(changes) {
    if (this.toDate) {
      const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

      if (adjustedToday.getTime() < this.toDate.getTime()) {
        this.maxDate = adjustedToday.clone();
      } else {
        this.maxDate = this.toDate.clone();
      }
    }

    if (changes.obsInfraFlows || changes.fromDate || changes.toDate) {
      const fromTime = this.fromDate.clone().setHours(0, 0, 0, 0);
      const endTime = this.toDate.clone().setHours(0, 0, 0, 0);

      this.displayObsInfraFlows =
        this.obsInfraFlows.filter((a) => a.date.getTime() >= fromTime && a.date.getTime() <= endTime);

      this.displayObsInfraFlows.forEach((a, idx) => {
        // The validated ObsInfraFlow's row must be excluded from the dates validation.
        a.filterDates = this.filterDates(idx);
      });
    }

    if (changes.equipment) {
      this.meterUnit = this._unitOfMeasureService.getUnitById(this.equipment.UnitId);
    }
  }

  public changeObs(obs: fuse.obsInfraWaterFlowDto, index: number) {
    obs.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(obs.date);

    let previousReading = this.obsInfraFlows.filter((a) => a.dayNumber < obs.dayNumber).pop()?.reading;

    if (!previousReading) {
      previousReading = this.equipment.MeterInitialReading;
    }

    obs.volumeKL = this.meterUnit?.toBase((obs.reading - previousReading) * this.equipment.MeterIncrement) ?? 0;

    const nextObs = this.obsInfraFlows.find((a) => a.dayNumber > obs.dayNumber);

    if (nextObs) {
      nextObs.volumeKL = this.meterUnit?.toBase((nextObs.reading - obs.reading) * this.equipment.MeterIncrement) ?? 0;
    }

    this.validateForm(index);
    this.onChange({ isValid: this.obsForm.$valid });
  }

  public toggleLock(obs: fuse.obsInfraWaterFlowDto) {
    obs.isLocked = !obs.isLocked;
    this.onChange({ isValid: this.obsForm.$valid });
  }

  private filterDates(exclusionIndex: number): (date: Date) => boolean {
    return (date: Date) => {
      return this.displayObsInfraFlows
        .filter((_a, idx) => idx != exclusionIndex)
        .every((a) => a.date.getTime() !== date.getTime())
    };
  };

  private validateForm(index: number) {
    const reading = this.obsForm['reading' + index].$modelValue;
    const date = this.obsForm['date' + index].$modelValue;
    const lastReading = this.obsInfraFlows.filter((a) => a.date.getTime() < date.getTime()).pop();
    if (lastReading) {
      if (reading < lastReading.reading) {
        this.obsForm['reading' + index].$setValidity('lastvalid', false);
      } else {
        this.obsForm['reading' + index].$setValidity('lastvalid', true);
      }
    } else {
      this.obsForm['reading' + index].$setValidity('lastvalid', true);
    }
    const nextReading = this.obsInfraFlows.find((a) => a.date.getTime() > date.getTime());
    if (nextReading) {
      if (reading > nextReading.reading) {
        this.obsForm['reading' + index].$setValidity('nextvalid', false);
      } else {
        this.obsForm['reading' + index].$setValidity('nextvalid', true);
      }
    } else {
      this.obsForm['reading' + index].$setValidity('nextvalid', true);
    }
    this.obsForm['reading' + index].$setTouched();
  }
}

angular.module('app.account').component('infraFlowTable', new InfraFlowTableComponent());
