import * as angular from 'angular';
import { EntityList } from '@common/EntityList';
import { ApiService } from '@services/api.service';
import { DataEntityService } from '@services/data-entity.service';
import { EntityListService } from '@services/entity-list.service';
import { GroupSiteService } from '@services/group-site.service';
import { LanguageService } from '@services/language.service';
import { FilterService } from '@services/filter.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { BaseController, PostSaveActions } from 'src/app/base.controller';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';

export class AssetGroupController extends BaseController {
  public groupSiteService: GroupSiteService;
  public groupForm: angular.IFormController;
  public group: fuse.assetGroupDto;
  public possibleAssets: fuse.groupAssetDto[];
  public selectedAssetIds: number[] = [];
  public otherGroups: EntityList<fuse.listEntityDto>;

  public canRevertChanges: boolean;
  public hasDataChanges: boolean;
  public isEmptyName = false;
  public isExistingGroupName = false;

  public assetsInGroupForm: angular.IFormController;

  private _document: angular.IDocumentService;
  private _apiService: ApiService;
  private _dataEntityService: DataEntityService;
  private _entityListService: EntityListService;
  private _languageService: LanguageService;

  private _groupId: number;
  private _tmpSelectedAssetIds: number[] = [];

  constructor(
    $document: angular.IDocumentService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    ApiService: ApiService,
    DataEntityService: DataEntityService,
    EntityListService: EntityListService,
    NotifyingService: NotifyingService,
    GroupSiteService: GroupSiteService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.GroupFull);

    this._document = $document;
    this._apiService = ApiService;
    this._dataEntityService = DataEntityService;
    this._entityListService = EntityListService;
    this._languageService = LanguageService;

    this.groupSiteService = GroupSiteService;
    this._groupId = Number($state.params.id);

    $scope.$watch('vm.groupForm.$dirty',
      (isGroupFormDirty: boolean) => {
        if (!this.group) {
          return;
        }

        this.hasDataChanges = isGroupFormDirty;
        this._dataEntityService.hasDirtyCustomForm = isGroupFormDirty;
      }
    );

    NotifyingService.subscribe(NotifyEvents.App.SaveChanges.AssetGroup, $scope, (_event: angular.IAngularEvent, data: PostSaveActions) => {
      this.saveChanges(data);
    });
  }

  $onInit() {
    this.loadData();
  }

  onNameChanged(): void {
    this.isEmptyName = !this.group.name;
    this.isExistingGroupName = !this.isEmptyName
      && this.otherGroups.entities.some((g) =>
        g.id !== this._groupId
        && g.name.trim().toLowerCase() === this.group.name.trim().toLowerCase()
      );
  }

  public loadData(): void {
    const fetchPossibleMemberAssets = () => {
      const afterFunc = (result) => {
        result.entities.map((asset) => {
          asset['displayName'] = this.groupSiteService.getAssetNameWithDetails(asset);

          return asset;
        });

        this.possibleAssets = result.filterEntities();
      };

      this._entityListService.getEntityList('group/getPossibleMemberAssets', afterFunc, true, params, {
        filters: null,
        statusFilters: FilterService.AllStatusesFilter,
      });
    };

    const loadOtherGroups = () => {
      const afterFunc = (result) => {
        const setComponentInitialState = () => {
          this.groupForm.$setPristine();
          this.hasDataChanges = false;
          this._dataEntityService.hasDirtyCustomForm = false;
          this.onNameChanged();
        };

        this.otherGroups = result;
        this.otherGroups.filters[this.groupSiteService.groupClassFilter] = this.group.assetClassCode;

        setComponentInitialState();
      };

      this._entityListService.getEntityList('group/getAllGroupsList', afterFunc, true, { groupClassCode: this.group.assetClassCode }, this.groupSiteService);
    };

    const params = { groupId: this._groupId };

    const afterFunc = (res) => {
      this.group = res;
      this.selectedAssetIds = this.group.members?.map((m) => m.id);

      fetchPossibleMemberAssets();
      loadOtherGroups();
    };

    this.canRevertChanges = false;

    this._apiService.getGeneric('group/getAssetGroup', params, afterFunc, true);
  }

  public onAssetsSelectorOpen(): void {
    // The md-select directive eats keydown events for some quick select logic.
    // Since we have a search input here, we don't need that logic.
    this._document.find('md-select-header input[type="search"]').on('keydown', (e) => {
      e.stopPropagation();
    });

    this._tmpSelectedAssetIds = this.selectedAssetIds;
  }

  public saveChanges(postSaveActions: PostSaveActions = null): void {
    if (this.isEmptyName) {
      this._languageService.showSaveFailure('COMMON.NAME_REQUIRED');

      return;
    }

    if (this.isExistingGroupName) {
      this._languageService.showSaveFailure('AC.GROUP.GROUP_ALREADY_EXISTS');

      return;
    }

    this.canRevertChanges = !this.assetsInGroupForm.$valid;

    const hasAssetChanges = !ArrayUtils.equals(this.selectedAssetIds, this._tmpSelectedAssetIds);
    const shouldSave = !!this.selectedAssetIds?.length && hasAssetChanges;

    if (!shouldSave) {
      return;
    }

    const successCallback = () => {
      this.hasDataChanges = false;
      this._dataEntityService.hasDirtyCustomForm = false;
      this._languageService.showSaveSuccess();

      if (super.executeAnyPostSaveActions(postSaveActions)) {
        return;
      }

      this.loadData();
    };

    this.group.members = this.selectedAssetIds.map((assetId) => {
      return {
        id: assetId,
        name: this.possibleAssets.find((a) => a.id == assetId).name,
      } as fuse.listEntityDto;
    });

    this._apiService.postGeneric('group/updateAssetGroup', this.group, successCallback, true);
  }
}

angular.module('app.account').controller('AssetGroupController', AssetGroupController);
