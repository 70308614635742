import * as angular from 'angular';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { unitSizes, AmGraphDataValues, UnitTypes } from '@common/enums';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';

class InfraDepthChartComponent implements angular.IComponentOptions {
  bindings = {
    obsInfraDepths: '<',
    reloadCount: '<',
  };
  controller = InfraDepthChartController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/equipment/components/infra-depth-chart.html';
}

interface IObsInfraChartItem {
  date: Date;
  dayNumber: number;
  volume: number;
  depth: number;
}

class InfraDepthChartController {
  private _unitOfMeasureService: UnitOfMeasureService;
  private _languageService: LanguageService;

  private infraDepthChart: AmCharts.AmSerialChart;
  private volumeNormalUnit: uomUnit;
  private elevationSmallUnit: uomUnit;
  private obsInfraDepths: fuse.obsInfraWaterDepthDto[];

  constructor(UnitOfMeasureService: UnitOfMeasureService, LanguageService: LanguageService) {
    this._unitOfMeasureService = UnitOfMeasureService;
    this._languageService = LanguageService;
    this.volumeNormalUnit = this._unitOfMeasureService.getUnits(UnitTypes.Volume);
    this.elevationSmallUnit = this._unitOfMeasureService.getUnits(UnitTypes.Elevation, unitSizes.small);
  }

  $onChanges(changes) {
    if (changes.reloadCount?.currentValue > 0) {
      this.displayInfraDepthChart();
    }
  }

  $onDestroy() {
    if (this.infraDepthChart != null) {
      this.infraDepthChart.clear();
      this.infraDepthChart = null;
    }
  }

  private displayInfraDepthChart() {
    const hasVolume = this.obsInfraDepths.some((obs) => obs.volumeKL);
    const hasDepth = this.obsInfraDepths.some((obs) => obs.depthM);
    const dataProvider =
      ArrayUtils.sortByNumber(
        this.obsInfraDepths.map((obs) => ({
          date: obs.date,
          dayNumber: obs.dayNumber,
          volume: this.volumeNormalUnit.fromBaseRounded(obs.volumeKL),
          depth: this.elevationSmallUnit.fromBaseRounded(obs.depthM),
        }) as IObsInfraChartItem),
      (x) => x.dayNumber,
    );

    // #region chart
    const volumeAxis = {
      autoGridCount: false,
      position: 'left',
      title: `${this._languageService.instant('COMMON.VOLUME')} (${this.volumeNormalUnit.name})`,
      minimum: 0,
      gridCount: 5,
      gridThickness: 1,
    } as AmCharts.ValueAxis;
    const depthAxis = {
      autoGridCount: false,
      position: 'left',
      title: `${this._languageService.instant('COMMON.DEPTH')} (${this.elevationSmallUnit.name})`,
      minimum: 0,
      gridCount: 5,
      gridThickness: 0,
    } as AmCharts.ValueAxis;

    const volumeGraph = {
      valueField: 'volume',
      title: `${this._languageService.instant('COMMON.VOLUME')} (${this.volumeNormalUnit.name})`,
      valueAxis: volumeAxis,
      type: 'column',
      columnWidth: 0.5,
      alphaField: 'true',
      fillAlphas: 0.8,
      lineColor: '#27AAE0',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.VOLUME')}: ${val}${this.volumeNormalUnit.name}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;
    const depthGraph = {
      valueField: 'depth',
      title: `${this._languageService.instant('COMMON.DEPTH')} (${this.elevationSmallUnit.name})`,
      valueAxis: depthAxis,
      type: 'column',
      columnWidth: 0.5,
      alphaField: 'true',
      fillAlphas: 0.8,
      lineColor: '#97AAE0',
      markerType: 'square',
      balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
        const val = (graphDataItem.values as AmGraphDataValues).value;
        if (val) {
          return `${this._languageService.instant('COMMON.DEPTH')}: ${val.toFixed(2)}${this.elevationSmallUnit.name}`;
        } else {
          return '';
        }
      },
    } as AmCharts.AmGraph;

    const axes = [];
    const graphs = [];
    let legend = null;
    if (hasVolume && hasDepth) {
      depthAxis.position = 'right';
      legend = {
        useGraphSettings: true,
        position: 'top',
      } as AmCharts.AmLegend;
    }
    if (hasVolume) {
      axes.push(volumeAxis);
      graphs.push(volumeGraph);
    }
    if (hasDepth) {
      axes.push(depthAxis);
      graphs.push(depthGraph);
    }

    const infraDepthChartOption = {
      theme: 'light',
      type: 'serial',
      dataProvider: dataProvider,
      valueAxes: axes,
      startDuration: 0,
      graphs: graphs,
      legend: legend,
      chartScrollbar: {
        oppositeAxis: false,
        offset: 30,
        scrollbarHeight: 30,
        backgroundAlpha: 0,
        autoGridCount: true,
        color: '#aaa',
      },
      categoryField: 'date',
      categoryAxis: {
        gridPosition: 'center',
        gridAlpha: 0,
        parseDates: true,
        axisColor: '#DADADA',
        dateFormats: [
          { period: 'DD', format: 'MMM DD' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'MM', format: 'MMM DD' },
          { period: 'YYYY', format: 'MMM DD' },
        ],
        dashLength: 1,
      } as AmCharts.CategoryAxis,
      export: {
        enabled: false,
      },
    };
    this.infraDepthChart = AmCharts.makeChart('infra-depth-chart', infraDepthChartOption) as AmCharts.AmSerialChart;
    this.infraDepthChart.validateNow();
  }
}

angular.module('app.account').component('infraDepthChart', new InfraDepthChartComponent());
