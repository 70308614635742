// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#infra-depth-table {
  max-height: calc(100vh - 410px);
  overflow: auto;
}
#infra-depth-table table {
  width: 100%;
  border-collapse: collapse;
}
#infra-depth-table table thead tr {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  height: 28px;
  position: sticky;
  z-index: 10;
  top: 0;
}
#infra-depth-table table thead tr th {
  text-align: center;
  padding: 16px 0;
  background-color: #666;
  min-width: 85px;
}
#infra-depth-table table thead tr th:first-child {
  width: 120px;
}
#infra-depth-table table tbody tr td {
  text-align: center;
  padding: 2px 0;
}
#infra-depth-table table tbody tr td md-input-container {
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}
#infra-depth-table table tbody tr td md-input-container .md-errors-spacer {
  min-height: 0px;
}
#infra-depth-table table tbody tr td md-input-container .md-datepicker-button {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/views/equipment/components/infra-depth-table.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,cAAA;AACF;AACE;EACE,WAAA;EACA,yBAAA;AACJ;AAEM;EACE,YAAA;EACA,oCAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,MAAA;AAAR;AAEQ;EACE,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,eAAA;AAAV;AAEU;EACE,YAAA;AAAZ;AAQQ;EACE,kBAAA;EACA,cAAA;AANV;AAQU;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AANZ;AAQY;EACE,eAAA;AANd;AASY;EACE,aAAA;AAPd","sourcesContent":["#infra-depth-table {\r\n  max-height: calc(100vh - 410px);\r\n  overflow: auto;\r\n\r\n  table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n\r\n    thead {\r\n      tr {\r\n        color: white;\r\n        background-color: rgba(0, 0, 0, 0.6);\r\n        height: 28px;\r\n        position: sticky;\r\n        z-index: 10;\r\n        top: 0;\r\n\r\n        th {\r\n          text-align: center;\r\n          padding: 16px 0;\r\n          background-color: #666;\r\n          min-width: 85px;\r\n\r\n          &:first-child {\r\n            width: 120px;\r\n          }\r\n        }\r\n      }\r\n    }\r\n\r\n    tbody {\r\n      tr {\r\n        td {\r\n          text-align: center;\r\n          padding: 2px 0;\r\n\r\n          md-input-container {\r\n            margin: 0;\r\n            padding: 0;\r\n            vertical-align: bottom;\r\n\r\n            .md-errors-spacer {\r\n              min-height: 0px;\r\n            }\r\n\r\n            .md-datepicker-button {\r\n              display: none;\r\n            }\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
