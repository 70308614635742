import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { DecimalDayService } from '@services/decimal-day.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { UnitTypes, unitSizes, SQLErrorCodes } from '@common/enums';
import { ObsWaterFlow } from 'src/app/_DBContext/ObsWaterFlow';
import { BaseController } from 'src/app/base.controller';

export class AddWaterFlowReadingDialogController extends BaseController {
  public regexTimeInput: RegExp;
  public filterDates: (date: Date) => boolean;
  public minDate: Date;
  public maxDate: Date;
  public assetDate: Date;
  public volume = 0;
  public duration = '00:00';
  public lockVal = false;
  public volumeNormalUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _decimalDayService: DecimalDayService;
  private _languageService: LanguageService;

  private _assetId: number;
  private _readings: ObsWaterFlow[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    DecimalDayService: DecimalDayService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    assetId: number,
    readings: ObsWaterFlow[],
    fromDate: Date,
    toDate: Date,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._decimalDayService = DecimalDayService;
    this._languageService = LanguageService;

    this.entityManager = DataEntityService.manager;
    this.regexTimeInput = SWANConstants.RegexTimeInput;
    this._assetId = assetId;
    this._readings = readings;

    this.filterDates = (date: Date) => {
      return this._readings.every((a) => a.localDate.getTime() != date.getTime());
    };

    this.minDate = fromDate;
    this.volumeNormalUnit = UnitOfMeasureService.getUnits(UnitTypes.Volume, unitSizes.normal);

    const adjustedYesterday = this._dayNumberService.convertBrowserDateTimeToLocaleDate(new Date().addDays(-1));

    if (adjustedYesterday.getTime() < toDate.getTime()) {
      this.maxDate = adjustedYesterday.clone();
    } else {
      this.maxDate = toDate.clone();
    }

    this.assetDate = adjustedYesterday;
  }

  public closeDialog(updated: boolean) {
    this._mdDialog.hide(updated);
  }

  public submit() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.assetDate);
    const value = {
      AssetId: this._assetId,
      dayNumber: -999,
      dayDisplayYMD: this._dayNumberService.convertDayNumberToYMD(dayNumber),
      VolumeKL: this.volume,
      Duration: this._decimalDayService.HHmmToDecimalDays(this.duration),
      QualityFactor: 0,
      Locked: this.lockVal,
    } as ObsWaterFlow;
    const newEntityAsset: any = this.entityManager.createEntity('ObsWaterFlow', value);
    this.entityManager.addEntity(newEntityAsset);
    const saveOptions = {
      swanHandledErrors: [SQLErrorCodes.DuplicateKeyError],
    } as fuse.swanSaveOptions;
    this._dataEntityService.saveChanges(true, saveOptions).then(
      () => {
        this._languageService.success('COMMON.CHANGES_SAVED');
        this.closeDialog(true);
      },
      (saveFailed) => {
        this._dataEntityService.rejectChanges();
        if (saveFailed.httpResponse.data.Errors) {
          saveFailed.httpResponse.data.Errors.map((error) => {
            if (error.ErrorNumber == SQLErrorCodes.DuplicateKeyError) {
              console.log(error.Message);
              this._languageService.error('AC.EQUIP.ERROR_DATE_EXISTS', 'COMMON.CHANGES_NOT_SAVED');
            } else {
              this._languageService.error(error.Message);
            }
          });
        }
        this.closeDialog(false);
      },
    );
  }
}

angular.module('app.account').controller('AddWaterFlowReadingDialogController', AddWaterFlowReadingDialogController);
