import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { DayNumberService } from '@services/day-number.service';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class AddInfraDepthVolumeDialogController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public filterDates: (date: Date) => boolean;
  public fromDate: Date;
  public date: Date;
  public volume: number;
  public depth: number;
  public isLocked = false;
  public addForm: angular.IFormController;
  public volumeNormalUnit: uomUnit;
  public elevationSmallUnit: uomUnit;
  public isInVolume = false;
  public maximumDate: Date;

  public depthMax: number = null;
  public depthMin: number = 0;
  public volumeMax: number = null;
  public volumeMin: number = 0;
  public displayDepthMax: number = null;
  public displayDepthMin: number = 0;
  public displayVolumeMax: number = null;
  public displayVolumeMin: number = 0;

  private _assetId: number;
  private _toDate: Date;
  private _obsDepths: fuse.obsInfraWaterDepthDto[];
  private _depthMeterBoundaries: fuse.depthMeterBoundaryValuesDto[];

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    assetId: number,
    fromDate: Date,
    toDate: Date,
    obsDepths: fuse.obsInfraWaterDepthDto[],
    depthMeterBoundaries: fuse.depthMeterBoundaryValuesDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this._assetId = assetId;
    this._obsDepths = obsDepths;
    this._depthMeterBoundaries = depthMeterBoundaries;
    this.fromDate = fromDate;
    this._toDate = toDate;

    this.filterDates = (date: Date): boolean => {
      return this._obsDepths.every((a) => a.date.getTime() != date.getTime());
    };

    const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    if (adjustedToday.getTime() < this._toDate.getTime()) {
      this.maximumDate = adjustedToday.clone();
    } else {
      this.maximumDate = this._toDate.clone();
    }

    this.date = this.maximumDate;
    this.volumeNormalUnit = this._unitOfMeasureService.getUnits('Volume', unitSizes.normal);
    this.elevationSmallUnit = this._unitOfMeasureService.getUnits('Elevation', unitSizes.small);
  }

  $onInit() {
    this.dateChanged();
  }

  public changeOption(): void {
    if (this.isInVolume) {
      this.depth = null;
    } else {
      this.volume = null;
    }
  }

  public dateChanged() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.date);
    if (this._depthMeterBoundaries) {
      const bounds = this._depthMeterBoundaries.find(
        (a) =>
          (a.fromDayNumber === null || a.fromDayNumber <= dayNumber) && (a.toDayNumber === null || a.toDayNumber >= dayNumber),
      );
      if (bounds) {
        this.depthMax = bounds.depthMax;
        this.depthMin = bounds.depthMin ?? 0;
        this.volumeMax = bounds.volumeMax;
        this.volumeMin = bounds.volumeMin ?? 0;
        this.displayDepthMax = this.elevationSmallUnit.fromBaseRounded(bounds.depthMax);
        this.displayDepthMin = this.elevationSmallUnit.fromBaseRounded(bounds.depthMin ?? 0);
        this.displayVolumeMax = this.volumeNormalUnit.fromBaseRounded(bounds.volumeMax);
        this.displayVolumeMin = this.volumeNormalUnit.fromBaseRounded(bounds.volumeMin ?? 0);
      }
    }
  }

  public changeReading() {}

  public closeDialog(updated: boolean) {
    this._mdDialog.hide(updated);
  }

  public submit() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.date);
    const value = {
      volumeKL: this.isInVolume ? this.volume : null,
      depthM: this.isInVolume ? null : this.depth,
      isLocked: this.isLocked,
    } as fuse.obsInfraWaterDepthDto;
    this._http
      .put(CommonHelper.getApiUrl(`equipment/addObsInfraWaterDepth?assetid=${this._assetId}&dayNumber=${dayNumber}`), value)
      .then(
        (res) => {
          const result = res.data as boolean;
          if (result) {
            this._mdDialog.hide(true);
            this._languageService.success('COMMON.CHANGES_SAVED');
          } else {
            this._languageService.whoops();
          }
        },
        () => {
          this._languageService.whoops();
        },
      );
  }
}

angular.module('app.account').controller('AddInfraWaterFlowDialogController', AddInfraDepthVolumeDialogController);
