import * as angular from 'angular';
import { DataEntityService } from '@services/data-entity.service';
import { UnitTypes, SQLErrorCodes } from '@common/enums';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { ObsSoilMoisture } from 'src/app/_DBContext/ObsSoilMoisture';
import { BaseController } from 'src/app/base.controller';

export class AddSoilMoistureReadingDialogController extends BaseController {
  public filterDates: (date: Date) => boolean;
  public assetDate: Date;
  public minDate: Date;
  public maxDate: Date;
  public depth = 0;
  public moisture = 0;
  public soilDepthUnit: uomUnit;

  private _mdDialog: angular.material.IDialogService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;
  private _dayNumberService: DayNumberService;

  private _assetId: number;
  private _readings: ObsSoilMoisture[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    assetId: number,
    readings: ObsSoilMoisture[],
    fromDate: Date,
    toDate: Date,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._dataEntityService = DataEntityService;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;
    this._dayNumberService = DayNumberService;

    this.entityManager = DataEntityService.manager;
    this._readings = readings;
    this._assetId = assetId;

    this.filterDates = (date: Date) => {
      return this._readings.every((a) => a.localDate.getTime() != date.getTime());
    };

    this.minDate = fromDate;
    this.soilDepthUnit = UnitOfMeasureService.getUnits(UnitTypes.SoilDepth);

    const adjustedToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    if (adjustedToday.getTime() < toDate.getTime()) {
      this.maxDate = adjustedToday.clone();
    } else {
      this.maxDate = toDate.clone();
    }

    this.assetDate = this.maxDate.clone();
  }

  public closeDialog(updated: boolean) {
    this._mdDialog.hide(updated);
  }

  public submit() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.assetDate);
    //check validation
    if (this._readings.some((a) => a.dayNumber == dayNumber && a.Depth == this.depth)) {
      this._languageService.warning('AC.EQUIP.ERROR_DEPTH_EXISTS');
      return;
    }
    const values = {
      AssetId: this._assetId,
      dayNumber: -999,
      dayDisplayYMD: this._dayNumberService.convertDayNumberToYMD(dayNumber),
      Depth: this.depth,
      Moisture: this.moisture,
      QualityFactor: 0,
    } as ObsSoilMoisture;

    const newEntityAsset: any = this.entityManager.createEntity('ObsSoilMoisture', values);
    this.entityManager.addEntity(newEntityAsset);
    const saveOptions = {
      swanHandledErrors: [SQLErrorCodes.DuplicateKeyError],
    } as fuse.swanSaveOptions;
    this._dataEntityService.saveChanges(true, saveOptions).then(
      () => {
        this._languageService.success('COMMON.CHANGES_SAVED');
        this.closeDialog(true);
      },
      (saveFailed) => {
        this._dataEntityService.rejectChanges();
        if (saveFailed.httpResponse.data.Errors) {
          saveFailed.httpResponse.data.Errors.map((error) => {
            if (error.ErrorNumber == SQLErrorCodes.DuplicateKeyError) {
              console.log(error.Message);
              this._languageService.error('AC.EQUIP.ERROR_DATE_EXISTS', 'COMMON.CHANGES_NOT_SAVED');
            } else {
              this._languageService.error(error.Message);
            }
          });
        }
        this.closeDialog(false);
      },
    );
  }
}

angular.module('app.account').controller('AddSoilMoistureReadingDialogController', AddSoilMoistureReadingDialogController);
