import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class DataCaptureDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;

  public accountAssets: fuse.equipmentProfileDto[] = [];
  public step = 0;
  public selectedAssets = [] as fuse.equipmentProfileDto[];
  public dataCaptureParams = {} as fuse.dataCaptureDto;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public onOptionChanged(dataCaptureOption: fuse.dataCaptureDto): void {
    this.dataCaptureParams.isIntervalMode = dataCaptureOption.isIntervalMode;
    this.dataCaptureParams.endDateYMD = dataCaptureOption.endDateYMD;
    this.dataCaptureParams.fromDateYMD = dataCaptureOption.fromDateYMD;
    this.dataCaptureParams.runDays = dataCaptureOption.runDays;
  }

  public run() {
    this._mdDialog.hide(this.dataCaptureParams);
  }
}

angular.module('app.account').controller('DataCaptureDialogController', DataCaptureDialogController);
